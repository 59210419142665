import Divider from "antd/es/divider";
import React from "react";
import { EditSubmissionPageResponse } from "../../../../../../features/entities";
import getShortId from "../../../../../../features/helpers/get-short-id";
import useLocale from "../../../../../../features/providers/locale-provider";
import { useTheme } from "../../../../../../plugins/styled";
import Div from "../../../../../common/div";
import Typography from "../../../../../common/typography";
import getItemTranslatedLabel from "../../../../form-builder/utils/get-item-translated-label";
import FormSubmisionEditorGroup from "../form-submission-group";

interface FormSubmissionEditorPageProps {
  page: EditSubmissionPageResponse;
}

const FormSubmissionEditorPage = ({ page }: FormSubmissionEditorPageProps) => {
  const { selectedLocale } = useLocale();
  const theme = useTheme();

  return (
    <Div flex="column" gap={24}>
      <div>
        <Typography elementTheme="subtitle2">
          {getItemTranslatedLabel({
            label_en: page.entity.page_title_en,
            label_fr: page.entity.page_title_fr,
            fallback: getShortId(page.entity.form_page_id),
            parseOptions: {},
            locale: selectedLocale,
          })}
        </Typography>
        <Typography elementTheme="body2">
          {getItemTranslatedLabel({
            label_en: page.entity.page_heading_en,
            label_fr: page.entity.page_heading_fr,
            fallback: "",
            parseOptions: {},
            locale: selectedLocale,
          })}
        </Typography>
      </div>
      <div>
        {React.Children.toArray(
          page.groups?.map((group, j) => (
            <>
              <FormSubmisionEditorGroup group={group} />
              {j < page.groups.length - 1 && (
                <Divider
                  style={{
                    borderColor: theme.colors.grey_2,
                    margin: "10px 0",
                  }}
                  variant="dashed"
                />
              )}
            </>
          ))
        )}
      </div>
    </Div>
  );
};

export default FormSubmissionEditorPage;

