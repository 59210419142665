import { SelectProps } from "antd/es/select";

export const GinMediumStepOption: SelectProps["options"] = [
  { value: "meeting" },
  { value: "offline" },
];

export const GinTypeStepOption: SelectProps["options"] = [
  { value: "eligibility_validation" },
  { value: "information_requested" },
  { value: "form_submission" },
  { value: "application" },
  { value: "redaction" },
  { value: "form_filling" },
];

export const GinStepAssetTypeOption: SelectProps["options"] = [
  { value: "travel_plan" },
  { value: "financial_statement" },
  { value: "business_plan" },
  { value: "strategic_plan" },
  { value: "marketing_plan" },
  { value: "cv" },
  { value: "application_form" },
  { value: "lease" },
  { value: "invoice" },
  { value: "submission_offer" },
  { value: "attestation_form" },
  { value: "technical_sheet" },
  { value: "other" },
];

export const GinStepOwnerOption: SelectProps["options"] = [
  { value: "account_manager" },
  { value: "analyst" },
  { value: "researcher" },
];

