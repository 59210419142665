import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { GinProgram } from "./../../../../../core/lib/features/entities/programs-entities";

import {
  AdminGinResult,
  AdminGrantResult,
  ApiError,
  ApiErrorInitialState,
  Contact,
  FullGinApplication,
  GinApplication,
  GinApplicationCompany,
  GinApplicationProgram,
  GinEmail,
  GinInitialState,
  HubspotTemplate,
  InitialStateFullGinApplication,
} from "@hellodarwin/core/lib/features/entities";

import { RootState } from "../../../app/app-store";
import { showErrorNotification } from "../../utils";
import AdminApi from "../admin-api";

const gins = createEntityAdapter({
  selectId: (model: AdminGrantResult) => model.grant_id,
});

type StatusType =
  | "email"
  | "generic"
  | "singleGrant"
  | "applicationSingle"
  | "applicationContacts"
  | "applicationProgram"
  | "applicationCompany"
  | "programsList"
  | "relatedApplications";

type Status = {
  [key in StatusType]: "idle" | "pending";
};

export interface GinsState {
  status: Status;
  error: ApiError;
  ginGrants: EntityState<AdminGrantResult, string>;
  currentGin: AdminGinResult;
  hubspotTemplate: HubspotTemplate[] | null;
  selectedGinApplications: GinProgram[] | null;
  currentGinApplication: FullGinApplication;
  modalForm: {
    isVisible: boolean;
    applicationStatus: string;
  };
  modalMessage: {
    isVisible: boolean;
    isSuccessful: boolean;
    applicationStatus: string;
  };
  modalProgramContacts: {
    isVisible: boolean;
  };
}

const initialState: GinsState = {
  status: {
    generic: "idle",
    singleGrant: "pending",
    programsList: "pending",
    relatedApplications: "pending",
    applicationSingle: "pending",
    applicationCompany: "pending",
    applicationContacts: "pending",
    applicationProgram: "pending",
    email: "pending",
  },
  error: ApiErrorInitialState,
  ginGrants: gins.getInitialState(),
  currentGin: GinInitialState,
  hubspotTemplate: null,
  selectedGinApplications: null,
  currentGinApplication: InitialStateFullGinApplication,
  modalForm: {
    isVisible: false,
    applicationStatus: "",
  },
  modalMessage: {
    isVisible: false,
    isSuccessful: false,
    applicationStatus: "",
  },
  modalProgramContacts: {
    isVisible: false,
  },
};

export const queryGinGrants = createAsyncThunk<
  AdminGrantResult[],
  { api: AdminApi; locale: string; query?: string; filter?: string[] },
  { rejectValue: ApiError }
>(
  "admin/queryGins",
  async (
    {
      api,
      locale,
      query,
      filter,
    }: { api: AdminApi; locale: string; query?: string; filter?: string[] },
    { rejectWithValue }
  ) => {
    try {
      const url = `/gin?locale=${locale}${query ? `&query=${query}` : ""}${
        filter ? `&difficulty=${filter}` : ""
      }`;
      return (await api.get<AdminGrantResult[]>(url)).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinByGrantId = createAsyncThunk<
  AdminGinResult,
  { api: AdminApi; grantId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinByGrantId",
  async (
    { api, grantId }: { api: AdminApi; grantId: string },
    { rejectWithValue }
  ) => {
    try {
      return (await api.get<AdminGinResult>(`/gin/grant/${grantId}`)).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinApplications = createAsyncThunk<
  GinProgram[],
  { api: AdminApi; grantId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinApplications",
  async (
    { api, grantId }: { api: AdminApi; grantId: string },
    { rejectWithValue }
  ) => {
    try {
      return (await api.get<GinProgram[]>(`/gin/${grantId}/programs`)).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinApplication = createAsyncThunk<
  GinApplication,
  { api: AdminApi; applicationId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinApplication",
  async (
    { api, applicationId }: { api: AdminApi; applicationId: string },
    { rejectWithValue }
  ) => {
    try {
      return (
        await api.get<GinApplication>(`/gin/application/${applicationId}`)
      ).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinApplicationCompany = createAsyncThunk<
  GinApplicationCompany,
  { api: AdminApi; applicationId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinApplicationCompany",
  async (
    { api, applicationId }: { api: AdminApi; applicationId: string },
    { rejectWithValue }
  ) => {
    try {
      return (
        await api.get<GinApplicationCompany>(
          `/gin/application/${applicationId}/company`
        )
      ).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinApplicationContacts = createAsyncThunk<
  Contact[],
  { api: AdminApi; applicationId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinApplicationContacts",
  async (
    { api, applicationId }: { api: AdminApi; applicationId: string },
    { rejectWithValue }
  ) => {
    try {
      return (
        await api.get<Contact[]>(`/gin/application/${applicationId}/contacts`)
      ).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGinApplicationContacts = createAsyncThunk<
  Contact[],
  { api: AdminApi; applicationId: string; contactIds: string[] },
  { rejectValue: ApiError }
>(
  "gins/updateGinApplicationContacts",
  async ({ api, applicationId, contactIds }, { rejectWithValue }) => {
    try {
      const response = await api.post<Contact[]>(
        `/gin/application/${applicationId}/contacts`,
        { contactIds }
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGinApplicationProgram = createAsyncThunk<
  GinApplicationProgram,
  { api: AdminApi; applicationId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGinApplicationProgram",
  async (
    { api, applicationId }: { api: AdminApi; applicationId: string },
    { rejectWithValue }
  ) => {
    try {
      return (
        await api.get<GinApplicationProgram>(
          `/gin/application/${applicationId}/program`
        )
      ).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const toggleApplicationModalForm = createAction<{
  isVisible: boolean;
  applicationStatus: string;
}>("admin/toggleProgramsModalForm");

export const toggleApplicationModalMessage = createAction<{
  isVisible: boolean;
  isSuccessful: boolean;
  applicationStatus: string;
}>("admin/toggleProgramsModalMessage");

export const toggleProgramContactsModal = createAction<{
  isVisible: boolean;
}>("admin/toggleContactModal");

export const updateGinApplication = createAsyncThunk<
  GinApplication,
  { api: AdminApi; updatedApplication: GinApplication },
  { rejectValue: ApiError; state: RootState }
>(
  "admin/updateGinApplication",
  async (
    {
      api,
      updatedApplication,
    }: { api: AdminApi; updatedApplication: GinApplication },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.put<GinApplication>(
        `/gin/application`,
        updatedApplication
      );
      return response.data;
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { programs } = getState();
      if (programs.status === "pending") return false;
    },
  }
);

export const createGinEmail = createAsyncThunk<
  GinEmail,
  { api: AdminApi; email: GinEmail; grant_id: string },
  { rejectValue: ApiError }
>(
  "admin/CreateGinTemplate",
  async (
    {
      api,
      email,
      grant_id,
    }: { api: AdminApi; email: GinEmail; grant_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post<GinEmail>(
        `/gin/email/${grant_id}`,
        email
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGinEmail = createAsyncThunk<
  GinEmail,
  { api: AdminApi; email: GinEmail },
  { rejectValue: ApiError }
>(
  "admin/UpdateGinTemplate",
  async (
    { api, email }: { api: AdminApi; email: GinEmail },
    { rejectWithValue }
  ) => {
    try {
      return (await api.put<GinEmail>("/gin/email", email)).data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteGinEmail = createAsyncThunk<
  string,
  { api: AdminApi; gin_email_id: string },
  { rejectValue: ApiError }
>(
  "admin/DeleteGinEmail",
  async (
    { api, gin_email_id }: { api: AdminApi; gin_email_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.delete<string>(
        `/gin/email/delete/${gin_email_id}`
      );
      return response.data;
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const ginsSlice = createSlice({
  name: "gins",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryGinGrants.pending, (state) => {
      state.status.programsList = "pending";
    });
    builder.addCase(queryGinGrants.fulfilled, (state, { payload }) => {
      gins.setAll(state.ginGrants, payload ?? []);
      state.status.programsList = "idle";
    });
    builder.addCase(queryGinGrants.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.programsList = "idle";
    });
    builder.addCase(fetchGinByGrantId.pending, (state) => {
      state.status.singleGrant = "pending";
      state.status.email = "pending";
    });
    builder.addCase(fetchGinByGrantId.fulfilled, (state, { payload }) => {
      state.currentGin = payload;
      state.status.singleGrant = "idle";
      state.status.email = "idle";
    });
    builder.addCase(fetchGinByGrantId.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.singleGrant = "idle";
      state.status.email = "idle";
    });
    builder.addCase(fetchGinApplications.pending, (state) => {
      state.status.relatedApplications = "pending";
    });
    builder.addCase(fetchGinApplications.fulfilled, (state, { payload }) => {
      state.selectedGinApplications = payload;
      state.status.relatedApplications = "idle";
    });
    builder.addCase(fetchGinApplications.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.relatedApplications = "idle";
    });
    builder.addCase(fetchGinApplication.pending, (state) => {
      state.status.applicationSingle = "pending";
    });
    builder.addCase(fetchGinApplication.fulfilled, (state, { payload }) => {
      state.currentGinApplication.application = payload;
      state.status.applicationSingle = "idle";
    });
    builder.addCase(fetchGinApplication.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.applicationSingle = "idle";
    });
    builder.addCase(
      updateGinApplicationContacts.fulfilled,
      (state, { payload }) => {
        state.currentGinApplication.contacts = payload;
        state.status.applicationContacts = "idle";
      }
    );
    builder.addCase(updateGinApplicationContacts.pending, (state) => {
      state.status.applicationContacts = "pending";
    });
    builder.addCase(
      updateGinApplicationContacts.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status.applicationContacts = "idle";
      }
    );
    builder.addCase(fetchGinApplicationCompany.pending, (state) => {
      state.status.applicationCompany = "pending";
    });
    builder.addCase(
      fetchGinApplicationCompany.fulfilled,
      (state, { payload }) => {
        state.currentGinApplication.company = payload;
        state.status.applicationCompany = "idle";
      }
    );
    builder.addCase(
      fetchGinApplicationCompany.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status.applicationCompany = "idle";
      }
    );
    builder.addCase(fetchGinApplicationContacts.pending, (state) => {
      state.status.applicationContacts = "pending";
    });
    builder.addCase(
      fetchGinApplicationContacts.fulfilled,
      (state, { payload }) => {
        state.currentGinApplication.contacts = payload;
        state.status.applicationContacts = "idle";
      }
    );
    builder.addCase(
      fetchGinApplicationContacts.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status.applicationContacts = "idle";
      }
    );
    builder.addCase(fetchGinApplicationProgram.pending, (state) => {
      state.status.applicationProgram = "pending";
    });
    builder.addCase(
      fetchGinApplicationProgram.fulfilled,
      (state, { payload }) => {
        state.currentGinApplication.program = payload;
        state.status.applicationProgram = "idle";
      }
    );
    builder.addCase(
      fetchGinApplicationProgram.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status.applicationProgram = "idle";
      }
    );
    builder.addCase(toggleApplicationModalForm, (state, { payload }) => {
      state.modalForm = payload;
    });
    builder.addCase(toggleApplicationModalMessage, (state, { payload }) => {
      state.modalMessage = payload;
    });
    builder.addCase(toggleProgramContactsModal, (state, { payload }) => {
      state.modalProgramContacts = payload;
    });
    builder.addCase(updateGinApplication.pending, (state) => {
      state.status.applicationSingle = "pending";
    });
    builder.addCase(updateGinApplication.fulfilled, (state, { payload }) => {
      state.currentGinApplication.application = {
        ...state.currentGinApplication.application,
        ...payload,
      };
      state.status.applicationSingle = "idle";
    });
    builder.addCase(updateGinApplication.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.applicationSingle = "idle";
    });
    builder.addCase(createGinEmail.pending, (state) => {
      state.status.email = "pending";
    });
    builder.addCase(createGinEmail.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.email = "idle";
    });
    builder.addCase(createGinEmail.fulfilled, (state, { payload }) => {
      state.status.email = "idle";
      if (payload.type === "template") {
        state.currentGin.templates = state.currentGin.templates
          ? [...state.currentGin.templates, payload]
          : [payload];
      } else {
        state.currentGin.snippets = state.currentGin.snippets
          ? [...state.currentGin.snippets, payload]
          : [payload];
      }
    });
    builder.addCase(updateGinEmail.pending, (state) => {
      state.status.email = "pending";
    });
    builder.addCase(updateGinEmail.rejected, (state, { payload }) => {
      state.status.email = "idle";
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(updateGinEmail.fulfilled, (state, { payload }) => {
      state.status.email = "idle";
      if (payload.type === "template") {
        state.currentGin.templates = state.currentGin.templates.map((itm) =>
          itm.id === payload.id ? payload : itm
        );
      } else {
        state.currentGin.snippets = state.currentGin.snippets.map((itm) =>
          itm.id === payload.id ? payload : itm
        );
      }
    });
    builder.addCase(deleteGinEmail.pending, (state) => {
      state.status.email = "pending";
    });
    builder.addCase(deleteGinEmail.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.email = "idle";
    });
    builder.addCase(deleteGinEmail.fulfilled, (state, { payload }) => {
      state.status.email = "idle";
      state.currentGin.templates = state.currentGin.templates.filter(
        (itm) => itm.id !== payload
      );
      state.currentGin.snippets = state.currentGin.snippets.filter(
        (itm) => itm.id !== payload
      );
    });
  },
});

export const selectGinsIsLoading = createSelector(
  [
    (state: RootState, _?: StatusType) => state.gins.status,
    (_, type?: StatusType) => type,
  ],
  (status, type) => {
    if (!type) {
      return !!Object.keys(status).find((state) => state === "pending");
    } else {
      return status[type] === "pending";
    }
  }
);

export const { selectAll: selectGinGrants } = gins.getSelectors(
  (state: RootState) => state.gins.ginGrants
);

export const selectCurrentGin = (state: RootState) => state.gins.currentGin;

export const selectGinApplication = (state: RootState) =>
  state.gins.currentGinApplication.application;
export const selectGinApplicationCompany = (state: RootState) =>
  state.gins.currentGinApplication.company;
export const selectGinApplicationContacts = (state: RootState) =>
  state.gins.currentGinApplication.contacts;
export const selectGinApplicationProgram = (state: RootState) =>
  state.gins.currentGinApplication.program;

export const selectSelectedGinApplications = (state: RootState) =>
  state.gins.selectedGinApplications;

export const selectGinModalForm = (state: RootState) => state.gins.modalForm;
export const selectGinModalMessage = (state: RootState) =>
  state.gins.modalMessage;
export const selectGinProgramContactsModal = (state: RootState) =>
  state.gins.modalProgramContacts;

export const selectHubspotTemplate = createSelector(
  (state: RootState) => state.gins.hubspotTemplate,
  (hubspotTemplate) =>
    hubspotTemplate?.map((itm) => ({
      label: itm.label,
      value: itm.id.toString(),
    })) ?? []
);

export const ginsReducer = ginsSlice.reducer;

