import {
  ApiError,
  ApiErrorInitialState,
} from "@hellodarwin/core/lib/features/entities/api-entitites";
import {
  AssetDownloadResponse,
  AssetEntity,
  AssetFolderEntity,
  AssetHiddenType,
  AssetOwnerInformation,
  AssetRecordInformation,
  AssetWithOwnersEntity,
} from "@hellodarwin/core/lib/features/entities/assets-entities";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import {
  createAssetsAdapter,
  MappedAssetsFolders,
} from "../adapters/assets-adapter";
import AdminApi from "../admin-api";
import SliceRequest from "../slice-request";

const assetsAdapter = createEntityAdapter({
  selectId: (model: AssetEntity) => model.asset_id,
});

const foldersAdapter = createAssetsAdapter();

export interface AssetsState {
  status: "idle" | "pending";
  error: ApiError;
  assets: EntityState<AssetEntity, string>;
  folders: MappedAssetsFolders;
}

const initialState: AssetsState = {
  status: "idle",
  error: ApiErrorInitialState,
  assets: assetsAdapter.getInitialState(),
  folders: foldersAdapter.getInitialState(),
};

export const fetchAssetsFromOwner = SliceRequest<
  AssetWithOwnersEntity[],
  { api: AdminApi; owner: AssetOwnerInformation }
>("fetchAssetsFromOwner", async ({ api, owner }) => {
  const response = await api.get<AssetEntity[]>(
    `/assets?owner_id=${owner.owner_id}&owner_type=${owner.owner_type}`
  );
  return response.data;
});

export const fetchAssetsFromRecord = SliceRequest<
  AssetEntity[],
  { api: AdminApi; record: AssetRecordInformation }
>("fetchAssetsFromRecord", async ({ api, record }) => {
  const response = await api.get<AssetEntity[]>(
    `/assets?record_id=${record.record_id}&record_type=${record.record_type}`
  );
  return response.data;
});

export const fetchFoldersFromOwner = SliceRequest<
  AssetFolderEntity[],
  { api: AdminApi; owner: AssetOwnerInformation }
>("fetchFoldersFromOwner", async ({ api, owner }) => {
  const response = await api.get<AssetFolderEntity[]>(
    `/assets/folder?owner_id=${owner.owner_id}&owner_type=${owner.owner_type}`
  );
  return response.data;
});

export const fetchFoldersFromRecord = SliceRequest<
  AssetFolderEntity[],
  { api: AdminApi; owner: AssetOwnerInformation }
>("fetchFoldersFromOwner", async ({ api, owner }) => {
  const response = await api.get<AssetFolderEntity[]>(
    `/assets/folder?record_id=${owner.owner_id}&record_type=${owner.owner_type}`
  );
  return response.data;
});

export const uploadAsset = SliceRequest<
  AssetWithOwnersEntity,
  { api: AdminApi; formData: FormData }
>("uploadAsset", async ({ api, formData }) => {
  const response = await api.post<AssetEntity>(
    `/assets`,
    formData,
    undefined,
    "multipart/form-data"
  );
  return response.data;
});

export const deleteAsset = SliceRequest<
  string,
  { api: AdminApi; assetId: string }
>("deleteAsset", async ({ api, assetId }) => {
  return (await api.delete<string>(`/assets/${assetId}`)).data;
});

export const hideShowAsset = SliceRequest<
  AssetWithOwnersEntity,
  { api: AdminApi; asset_id: string; hide: AssetHiddenType }
>("hideShowAsset", async ({ api, asset_id, hide }) => {
  return (
    await api.put<AssetWithOwnersEntity>(`/assets/hide-show`, {
      hide,
      asset_id,
    })
  ).data;
});

export const downloadAsset = SliceRequest<
  AssetDownloadResponse,
  { api: AdminApi; asset_id: string }
>("downloadAsset", async ({ api, asset_id }) => {
  return (await api.get<AssetDownloadResponse>(`/assets/download/${asset_id}`))
    .data;
});

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAssetsFromOwner.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchAssetsFromOwner.fulfilled, (state, { payload }) => {
      state.folders = foldersAdapter.setAllFoldersFromRecords(
        state.folders,
        payload
      );
      state.status = "idle";
    });
    builder.addCase(fetchAssetsFromOwner.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;

      state.status = "idle";
    });
    builder.addCase(fetchAssetsFromRecord.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      fetchAssetsFromRecord.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { record },
          },
        }
      ) => {
        state.folders = foldersAdapter.addRecordAssets(
          state.folders,
          payload,
          record
        );

        state.status = "idle";
      }
    );
    builder.addCase(fetchAssetsFromRecord.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;

      state.status = "idle";
    });
    builder.addCase(fetchFoldersFromOwner.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchFoldersFromOwner.fulfilled, (state, { payload }) => {
      state.folders = foldersAdapter.setAllFolders(state.folders, payload);
      state.status = "idle";
    });
    builder.addCase(fetchFoldersFromOwner.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(uploadAsset.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(uploadAsset.fulfilled, (state, { payload }) => {
      state.folders = foldersAdapter.updateAsset(state.folders, payload);
      state.status = "idle";
    });
    builder.addCase(uploadAsset.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(deleteAsset.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(deleteAsset.fulfilled, (state, { payload }) => {
      state.folders = foldersAdapter.removeAsset(state.folders, payload);
      state.status = "idle";
    });
    builder.addCase(deleteAsset.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(hideShowAsset.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(hideShowAsset.fulfilled, (state, { payload }) => {
      state.folders = foldersAdapter.updateAsset(state.folders, payload);
      state.status = "idle";
    });
    builder.addCase(hideShowAsset.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(downloadAsset.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(downloadAsset.fulfilled, (state, { payload }) => {
      state.folders = foldersAdapter.updateAsset(state.folders, payload.asset);
      state.status = "idle";
    });
    builder.addCase(downloadAsset.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload ?? ApiErrorInitialState;
    });
  },
});

export const {
  selectAllAssets,
  selectAllFolders,
  selectAllMappedAssets,
  selectAllRecordAssets,
  selectAssetById,
  selectAssetByIdWithoutRecord,
  selectFolderById,
} = foldersAdapter.getSelectors();

export const isError = (state: RootState) =>
  state.assets.error !== ApiErrorInitialState;

export const assetsReducer = assetsSlice.reducer;

