import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import Div from "@hellodarwin/core/lib/components/common/div";
import Modal from "@hellodarwin/core/lib/components/common/hd-modal";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { SearchResults } from "@hellodarwin/core/lib/features/entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import Button from "antd/es/button";
import Input, { InputRef } from "antd/es/input";
import Select, { SelectProps } from "antd/es/select";
import Space from "antd/es/space";
import { useEffect, useRef, useState } from "react";
import SearchBarCompanies from "../components/search-bar/search-bar-companies";
import SearchBarContact from "../components/search-bar/search-bar-contact";
import SearchBarGrantProviders from "../components/search-bar/search-bar-grant-providers";
import SearchBarGrants from "../components/search-bar/search-bar-grants";
import SearchBarProjects from "../components/search-bar/search-bar-projects";
import SearchBarProvider from "../components/search-bar/search-bar-providers";
import { useAdminApi } from "../features/api/use-admin-api";
import useDebounce from "../hooks/use-debounce";

import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import "./search-bar.scss";

const SEARCH_DELAY_MS = 500;

const SearchBar = () => {
  const api = useAdminApi();
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<SearchResults | undefined>(undefined);
  const [values, setValues] = useState<string[]>([]);
  const inputElement = useRef<InputRef>(null);
  const { selectedLocale } = useLocale();
  const theme = useTheme();
  const { t } = useTranslations();
  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => {
        inputElement.current?.focus();
      }, 0.1);
    }
    setValues([
      "providers",
      "projects",
      "contacts",
      "companies",
      "grants",
      "grantProviders",
    ]);
  }, [isModalVisible]);

  const input = useRef<InputRef>(null);
  const options: SelectProps["options"] = [
    { label: t("searchBarAdmin|providers"), value: "providers" },
    { label: t("searchBarAdmin|projects"), value: "projects" },
    { label: "Contacts", value: "contacts" },
    { label: t("searchBarAdmin|companies"), value: "companies" },
    { label: t("programs_admin|programsTitle"), value: "grants" },
    { label: t("searchBarAdmin|grantProviders"), value: "grantProviders" },
  ];

  const onOpen = () => {
    input.current?.blur();
    setIsModalVisible(true);
  };
  const onClose = () => setIsModalVisible(false);

  const onSearch = (query: string) => {
    if (query === "") return;
    (async () => {
      setIsSearching(true);
      try {
        const response = await api.globalSearch(query, selectedLocale);
        if (
          response.providers.length < 1 &&
          response.projects.length < 1 &&
          response.contacts.length < 1 &&
          response.companies.length < 1 &&
          response.grants.length < 1 &&
          response.grantProviders.length < 1
        ) {
          setResults(undefined);
          return;
        }

        setResults(response);
      } catch (e: any) {
        console.error(e);
      } finally {
        setIsSearching(false);
      }
    })();
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <Div
        flex="row"
        align="stretch"
        borderColor={theme.colors.grey_5}
        style={{
          borderWidth: "1px 1px 0 0",
          overflow: "hidden",
          borderRadius: "0 16px 0  0 ",
          width: "100%",
          height: "calc(100% + 1px)",
          marginTop: -1,
        }}
      >
        <Input
          ref={input}
          onFocus={onOpen}
          size="large"
          placeholder={t("searchBarAdmin|search")}
          style={{
            width: "100%",
            border: 0,

            margin: 0,
          }}
        />
      </Div>

      <Modal open={isModalVisible} handleCancel={onClose} size="large">
        <Div
          onClick={(e) => e.stopPropagation()}
          className="hd-search-modal"
          style={{ padding: 16 }}
          backgroundColor={theme.colors.white_1}
          gap={10}
        >
          <Div flex="column">
            <Input
              ref={inputElement}
              value={searchTerm}
              placeholder={t("searchBarAdmin|searchBarPlaceholder")}
              onChange={(e) => setSearchTerm(e.target.value)}
              addonBefore={<SearchOutlined style={{ fontSize: 20 }} />}
              addonAfter={
                searchTerm ? (
                  <CloseCircleOutlined onClick={() => setSearchTerm("")} />
                ) : undefined
              }
            />
          </Div>

          <Div className="hd-scroll-section">
            {results ? (
              <>
                <Space
                  style={{ width: "100%", marginTop: "20px" }}
                  direction="vertical"
                >
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={values}
                    onChange={setValues}
                    options={options}
                  />
                </Space>
                {results?.providers?.length > 0 &&
                  values.includes("providers") && (
                    <SearchBarProvider
                      results={results}
                      isSearching={isSearching}
                      onClose={onClose}
                    ></SearchBarProvider>
                  )}
                {results?.projects?.length > 0 &&
                  values.includes("projects") && (
                    <SearchBarProjects
                      results={results}
                      isSearching={isSearching}
                      onClose={onClose}
                    ></SearchBarProjects>
                  )}
                {results?.contacts?.length > 0 &&
                  values.includes("contacts") && (
                    <SearchBarContact
                      results={results}
                      isSearching={isSearching}
                      onClose={onClose}
                    ></SearchBarContact>
                  )}
                {results?.companies?.length > 0 &&
                  values.includes("companies") && (
                    <SearchBarCompanies
                      results={results}
                      isSearching={isSearching}
                      onClose={onClose}
                    ></SearchBarCompanies>
                  )}
                {results?.grants?.length > 0 && values.includes("grants") && (
                  <SearchBarGrants
                    results={results}
                    isSearching={isSearching}
                    onClose={onClose}
                  ></SearchBarGrants>
                )}
                {results?.grantProviders?.length > 0 &&
                  values.includes("grantProviders") && (
                    <SearchBarGrantProviders
                      results={results}
                      isSearching={isSearching}
                      onClose={onClose}
                    ></SearchBarGrantProviders>
                  )}
              </>
            ) : (
              <Typography>{t("searchBarAdmin|noResults")}</Typography>
            )}
          </Div>
          <Div justify="flex-end" flex="row">
            <Button onClick={onClose}>{t("button|close")}</Button>
          </Div>
        </Div>
      </Modal>
    </>
  );
};

export default SearchBar;

