import { useTheme } from "styled-components";
import { useTranslations } from "../../../features/providers/translations-provider";
import Div from "../div";
import Typography from "../typography";
import LanguageTranslationsViewerFieldContainer from "./languages-translations-viewer-field-container";
import { LanguageTranslationsViewerProps } from "./types";

const LanguageTranslationsViewer: React.FC<LanguageTranslationsViewerProps> = ({
  fields,
  minHeight = 100,
  maxHeight,
  ghost,
}) => {
  const theme = useTheme();
  const { t } = useTranslations();

  return (
    <Div borderColor={!ghost ? theme.colors.grey_4 : undefined}>
      <LanguageTranslationsViewerFieldContainer
        content_en={t("language|en")}
        content_fr={t("language|fr")}
        ghost={ghost}
        renderField={(field) => (
          <Typography elementTheme="body2" medium>
            {field}
          </Typography>
        )}
      />
      {!ghost && (
        <div
          style={{
            borderBottom: `1px solid ${theme.colors.grey_4}`,
            boxShadow: theme.appearance.box_shadow_hover,
          }}
        />
      )}

      <Div
        flex="column"
        gap={4}
        overflow="auto"
        style={{ minHeight, maxHeight }}
      >
        {fields.map((field) => (
          <LanguageTranslationsViewerFieldContainer {...field} ghost={ghost} />
        ))}
      </Div>
    </Div>
  );
};

export default LanguageTranslationsViewer;

