import Checkbox from "antd/es/checkbox";
import DatePicker from "antd/es/date-picker";
import Form from "antd/es/form";
import Input from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import Radio from "antd/es/radio";
import Select from "antd/es/select";
import Space from "antd/es/space";
import React, { useMemo } from "react";
import { EditSubmissionItemResponse } from "../../../../../../features/entities/form-entities";
import useLocale from "../../../../../../features/providers/locale-provider";
import Div from "../../../../../common/div";
import { itemDescriptionOptions } from "../../../../form-builder/utils/form-parse-options";
import getItemTranslatedLabel from "../../../../form-builder/utils/get-item-translated-label";
import { getSelectOptions } from "../../../../form-builder/utils/get-select-options";
import FormLabel from "../../../../form-layouts/form-label";

interface FormInputProps {
  editSubmissionItem: EditSubmissionItemResponse;
}

const FormSubmissionInput = ({
  editSubmissionItem: { form_item },
}: FormInputProps) => {
  const { selectedLocale } = useLocale();

  const options = useMemo(
    () =>
      form_item.form_select_options
        ? getSelectOptions(form_item.form_select_options, selectedLocale)
        : [],
    [form_item.form_select_options, selectedLocale]
  );

  return (
    <Div flex="row" justify="space-between">
      <FormLabel
        label={getItemTranslatedLabel({
          label_en: form_item.content_en,
          label_fr: form_item.content_fr,
          fallback: "",
          locale: selectedLocale,
        })}
        extra={getItemTranslatedLabel({
          label_en: form_item.heading_en,
          label_fr: form_item.heading_fr,
          fallback: "",
          parseOptions: itemDescriptionOptions,
          locale: selectedLocale,
        })}
        style={{ maxWidth: 600 }}
      />
      <Form.Item
        noStyle
        name={form_item.form_item_id}
        rules={[
          {
            required: form_item.required === "required",
            message: "This field is required",
          },
        ]}
      >
        {form_item.type === "string" ? (
          form_item.field_type === "text" ? (
            <TextArea />
          ) : (
            <Input />
          )
        ) : form_item.type === "datetime" ? (
          form_item.field_type === "date" ? (
            <DatePicker picker="date" />
          ) : (
            <DatePicker picker="time" />
          )
        ) : form_item.type === "enum" ? (
          form_item.field_type === "select" ? (
            <Select
              style={{ width: 400, maxWidth: "100%" }}
              options={options}
            />
          ) : form_item.field_type === "radio" ? (
            <Radio.Group>
              <Space
                direction={
                  form_item.disposition === "vertical"
                    ? "vertical"
                    : "horizontal"
                }
              >
                {React.Children.toArray(
                  options.map((option) => (
                    <Radio value={option.value}>{option.label}</Radio>
                  ))
                )}
              </Space>
            </Radio.Group>
          ) : form_item.field_type === "checkbox" ? (
            <Checkbox.Group>
              <Space
                direction={
                  form_item.disposition === "vertical"
                    ? "vertical"
                    : "horizontal"
                }
              >
                {React.Children.toArray(
                  options.map((option) => (
                    <Checkbox value={option.value}>{option.label}</Checkbox>
                  ))
                )}
              </Space>
            </Checkbox.Group>
          ) : (
            <></>
          )
        ) : form_item.type === "file" ? (
          form_item.field_type === "attachment" ? (
            <Input type="file" />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Form.Item>
    </Div>
  );
};

export default FormSubmissionInput;

