import Edit from "@hellodarwin/icons/dist/icons/Edit";
import Save from "@hellodarwin/icons/dist/icons/Save";
import Drawer from "antd/es/drawer";
import Form, { useForm } from "antd/es/form/Form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import { useState } from "react";
import { FormPageResponse } from "../../../../../features/entities/form-entities";
import getShortId from "../../../../../features/helpers/get-short-id";
import { useTheme } from "../../../../../plugins/styled";
import Button from "../../../../common/button";
import Clipboard from "../../../../common/clipboard";
import Div from "../../../../common/div";
import RichTextEditor from "../../../../common/rich-editor";
import Typography from "../../../../common/typography";
import FormItem from "../../../form-layouts/form-item";
import FormLabel from "../../../form-layouts/form-label";
import { FormPageEditButtonProps } from "../../types";

const quillModules = {
  toolbar: [
    [{ header: [3, 4, false] }],
    ["bold", "italic", { list: "bullet" }, { list: "ordered" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const FormPageEditButton = ({ page, functions }: FormPageEditButtonProps) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModal = () => setEditModalOpen(!editModalOpen);
  const [form] = useForm<FormPageResponse>();
  const theme = useTheme();

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    const editedFormPage: FormPageResponse = {
      ...page,
      ...values,
    };

    functions.updateFormPage(editedFormPage);
  };
  return (
    <>
      <Button
        size="circle"
        trailingIcon={<Edit width={20} height={20} />}
        onClick={handleEditModal}
        defaultStyle={theme.colors.white_1}
      />
      <Drawer
        open={editModalOpen}
        onClose={handleEditModal}
        title={
          <Div
            align="center"
            flex="row"
            style={{ height: "100%" }}
            justify="space-between"
          >
            <Div fitContent flex="row" gap={8} align="center">
              <Typography elementTheme="subtitle1" lineClamp={3}>
                Page :
              </Typography>
              <Typography
                elementTheme="subtitle1"
                color={theme.colors.purple_1}
                colorHover={theme.colors.purple_2}
              >
                <Clipboard
                  title={getShortId(page.form_page_id)}
                  copy={page.form_page_id}
                />
              </Typography>
            </Div>

            <Button
              size="circle"
              defaultStyle={theme.colors.white_1}
              onClick={handleSubmit}
            >
              <Save size={16} />
            </Button>
          </Div>
        }
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={page}
          onFinish={handleSubmit}
        >
          <Div flex="column" gap={24}>
            <FormItem
              name={"page_number"}
              label={<FormLabel label="Page Number" />}
            >
              <Input size="large" disabled />
            </FormItem>
            <FormItem
              name={"page_type"}
              label={<FormLabel label="Page Type" />}
            >
              <Select
                options={[
                  {
                    label: "Default",
                    value: "",
                  },
                  {
                    label: "Opening",
                    value: "opening",
                  },
                  {
                    label: "Elligible Ending",
                    value: "elligible-end",
                  },
                  {
                    label: "ICP Elligible Ending",
                    value: "elligible-end-icp",
                  },
                  {
                    label: "Maybe Elligible Ending",
                    value: "maybe-elligible-end",
                  },
                  {
                    label: "Non Elligible Ending",
                    value: "not-elligible-end",
                  },
                ]}
              />
            </FormItem>
            <FormItem
              name={"page_title_en"}
              label={<FormLabel label="Title En" />}
            >
              <Input />
            </FormItem>
            <FormItem
              name={"page_title_fr"}
              label={<FormLabel label="Title Fr" />}
            >
              <Input />
            </FormItem>
            <FormItem
              name={"page_heading_en"}
              label={<FormLabel label="Heading En" />}
            >
              <RichTextEditor initialValue={page.page_heading_en ?? ""} />
            </FormItem>
            <FormItem
              name={"page_heading_fr"}
              label={<FormLabel label="Heading Fr" />}
            >
              <RichTextEditor initialValue={page.page_heading_fr ?? ""} />
            </FormItem>
          </Div>
        </Form>
      </Drawer>
    </>
  );
};

export default FormPageEditButton;

