import Form from "antd/es/form";
import React from "react";
import { EditListFormRender } from ".";
import Div from "../../../../../common/div";
import renderInput from "./render-input";

const defaultRenderForm: EditListFormRender = <T,>(
  inputs,
  form,
  activeItem,
  deleteItem
) => {
  return (
    <Form layout="vertical" initialValues={activeItem} form={form}>
      <Div flex="column" gap={16}>
        {React.Children.toArray(
          inputs.map((input) =>
            renderInput({
              input,
              activeItem,
              deleteItem,
              form,
            })
          )
        )}
      </Div>
    </Form>
  );
};

export default defaultRenderForm;

