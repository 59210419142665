import Div from "@hellodarwin/core/lib/components/common/div";
import { AssetOwnerInformation } from "@hellodarwin/core/lib/features/entities";
import parse from "@hellodarwin/core/lib/features/helpers/parse";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { ginEditorParseOptions } from "../gin-editor";
import { GinSectionContainer } from "../styles";
import GinBlockHeader from "./gin-block-header";

export type GinBlockComponentType = "program" | "application";
export interface GinBlockComponentProps {
  prepareHandleSave: HandleSaveFuncStateDispatch;
  handleEditing?: () => void;
  isToggle?: boolean;
  handleToggle?: () => void;
  entityId: string;
  section: string;
  isEditing: boolean;
  type: GinBlockComponentType;
  additionalOwners?: AssetOwnerInformation[];
}

export interface GinBlockConfig {
  Content: (props: GinBlockComponentProps) => ReactNode;
  id: string;
  hide?: boolean;
  isHiddenActions?: boolean;
  isSimpleActions?: boolean;
  toggleName?: string;
}

export type HandleSaveFunc = () => void;
export type HandleSaveFuncState = HandleSaveFunc | undefined;
export type HandleSaveFuncStateDispatch = Dispatch<
  SetStateAction<HandleSaveFuncState>
>;
export interface GinBlockProps extends GinBlockConfig {
  entityId: string;
  type: "program" | "application";
  additionalOwners?: AssetOwnerInformation[];
}

const GinBlock = (props: GinBlockProps) => {
  const { Content, entityId, id, additionalOwners, isSimpleActions } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isToggle, setIsToggle] = useState(false);

  const [handleSave, prepareHandleSave] =
    useState<HandleSaveFuncState>(undefined);

  const [showHistory, setShowHistory] = useState(false);
  const [content, setContent] = useState<string>("");
  const handleEditing = () => setIsEditing(!isEditing);
  const handleHistory = () => setShowHistory(!showHistory);
  const handleToggle = () => setIsToggle(!isToggle);
  const onSave = () => {
    if (!!handleSave) {
      handleSave();
    }
    handleEditing();
  };

  return (
    <GinSectionContainer id={id}>
      <GinBlockHeader
        {...props}
        handleSave={
          isSimpleActions ? handleEditing : !!handleSave ? onSave : undefined
        }
        handleEditing={handleEditing}
        isEditing={isEditing}
        handleHistory={handleHistory}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        setContent={setContent}
        isToggle={isToggle}
        handleToggle={setIsToggle}
      />
      <div style={{ width: "100%" }}>
        {!showHistory || content === "" ? (
          <Content
            prepareHandleSave={prepareHandleSave}
            handleEditing={handleEditing}
            entityId={entityId}
            section={id}
            type={props.type}
            isEditing={isEditing}
            additionalOwners={additionalOwners}
            isToggle={isToggle}
            handleToggle={handleToggle}
          />
        ) : (
          <Div flex="column" gap={8}>
            {parse(content, ginEditorParseOptions)}
          </Div>
        )}
      </div>
    </GinSectionContainer>
  );
};

export default GinBlock;

