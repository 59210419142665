import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  AdminProfile,
  AdminSelectedTags,
  Project,
  ProjectAsset,
} from "@hellodarwin/core/lib/features/entities";
import Language from "@hellodarwin/core/lib/features/enums/language";
import ProjectDescriptionTypeInbox from "@hellodarwin/core/lib/features/enums/project-description-type-inbox";
import ProjectStartWhen from "@hellodarwin/core/lib/features/enums/project-start-when";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import ProjectType from "@hellodarwin/core/lib/features/enums/project-type";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Collapse from "antd/es/collapse";
import Form, { RuleObject } from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import InputNumber, { InputNumberProps } from "antd/es/input-number";
import List from "antd/es/list";
import message from "antd/es/message";
import Select from "antd/es/select";
import Space from "antd/es/space";
import Switch from "antd/es/switch";
import Tooltip from "antd/es/tooltip";
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactQuill, { Quill } from "react-quill";
import { useAppDispatch } from "../../app";
import {
  setSelectedProject,
  toggleProjectsModal,
} from "../../features/api/slices/projects-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { AdminPagesForms } from "../../pages/single-project-page";
import TagsForm, { EntityType } from "../tags/tags-form";
import { quillModules } from "../text-editor";
import ProjectAssetUpload from "./project-asset-upload";

const { Title } = Typography;
const { TextArea } = Input;
export enum LeadSourceType {
  Facebook = "Facebook Ads",
  Google = "Google Ads",
  Organic = "Organic (website)",
  Email = "Email (newsletter)",
  ClientApp = "Client App",
  MCEL = "MCEL",
  RBC = "RBC",
  PCAN2 = "PCAN2",
  ReEngagement = "Re-engagement",
  ClientReferral = "Client referral",
  Tradeshows = "Tradeshows",
  Direct = "Direct",
  AffiliateSP = "Affiliate SP",
  Other = "Other",
  AWQ = "AWQ",
  ReferenceshD = "References hD",
  OolongMedia = "Oolong Media",
  Unsold = "Unsold",
  GrantsPackage = "Grants Package",
  NonIcp = "Non-ICP Grant Info",
}

interface ProjectFormProps {
  project?: Project;
  admins: AdminProfile[];
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
  save?: () => void;
  setHasAttachments?: (value: Boolean) => void;
  tags: AdminSelectedTags;
}

export type ProjectFormValues = {
  title: string;
  original_description: string;
  description: string;
  special_conditions: string;
  additional_comments: string;
  start_when: ProjectStartWhen;
  budget_type?: string;
  budget_min: number;
  budget_max: number;
  estimated_value: number;
  lead_price: number;
  provider_max: number;
  provider_type?: string;
  lead_source: string;
  lead_source_specification?: string;
  creation_source: string;
  campaign_id: string;
  ad_group_id: string;
  kw: string;
  fbcl_id: string;
  is_request_diy: boolean;
  is_diy_activated: boolean;
  gcl_id: string;
  source_url: string;
  lp_url: string;
  fbc: string;
  internal_comments: string;
  account_manager?: string;
  languages: Language;
  is_premium: boolean;
  is_aqt: boolean;
  raise_hand_price: number;
  raise_hand_max: number;
  rh_status_details: string;
  referral_provider_id: string;
  description_context: string;
  description_goal: string;
  description_template: string;
};

export const InputNumberDollars = (props: InputNumberProps) => {
  return (
    <InputNumber
      {...props}
      formatter={(value) => `${value} $`}
      style={{ width: "100%" }}
    />
  );
};

export const checkTypeTitle = (value: string) => {
  let errorTitle = "";
  const regex = /<h4>(.*?)<\/h4>/g;
  const matches = value.match(regex);

  if (matches) {
    matches.map((match) => {
      const title = match
        .replace(/<\/?h4>/g, "")
        .trim()
        .toLocaleLowerCase(); // Remove <h4> and </h4> tags

      if (
        !Object.values(ProjectDescriptionTypeInbox).includes(
          title as ProjectDescriptionTypeInbox
        )
      ) {
        errorTitle = title;
      }
      return errorTitle;
    });
  }
  return errorTitle;
};

const ProjectForm = ({
  project,
  admins,
  setForms,
  save,
  setHasAttachments,
  tags,
}: ProjectFormProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<ProjectFormValues>();
  const [assets, setAssets] = useState<ProjectAsset[]>([]);
  const [isOtherLeadSource, setIsOtherLeadSource] = useState<boolean>(false);
  const initialValues: ProjectFormValues = useMemo(() => {
    return {
      title: project?.title ?? "",
      original_description: project?.original_description ?? "",
      description: project?.description ?? "",
      special_conditions: project?.special_conditions ?? "",
      additional_comments: project?.additional_comments ?? "",
      budget_type: project?.budget_type,
      lead_price: project?.lead_price ?? 0,
      budget_min: project?.budget_min ?? 0,
      lead_source: project?.lead_source ?? "",
      lead_source_specification: project?.lead_source_specification ?? "",
      creation_source: project?.creation_source ?? "",
      campaign_id: project?.campaign_id ?? "",
      ad_group_id: project?.ad_group_id ?? "",
      kw: project?.kw ?? "",
      fbcl_id: project?.fbcl_id ?? "",
      gcl_id: project?.gcl_id ?? "",
      source_url: project?.source_url ?? "",
      lp_url: project?.lp_url ?? "",
      fbc: project?.fbc ?? "",
      is_request_diy: !!project?.diy_requested_at,
      budget_max: project?.budget_max ?? 0,
      estimated_value: project?.estimated_value ?? 0,
      start_when: project?.start_when
        ? project?.start_when
        : ProjectStartWhen.Flexible,
      provider_max: project?.provider_max ?? 0,
      provider_type:
        project?.provider_type === undefined ? "Any" : project?.provider_type,
      internal_comments: project?.internal_comments ?? "",
      account_manager: project?.account_manager ?? "",
      languages: project?.languages ?? Language.Any,
      is_premium: !!project?.premium_at,
      is_aqt: !!project?.aqt_at,
      is_diy_activated: !!project?.activated_diy_at,
      raise_hand_price: project?.raise_hand_price ?? 0,
      raise_hand_max: project?.raise_hand_max ?? 0,
      rh_status_details: project?.rh_status_details ?? "",
      referral_provider_id: project?.referral_provider_id ?? "",
      description_context: project?.description_context || "",
      description_goal: project?.description_goal || "",
      description_template: project?.description_template || "",
    };
  }, [project]);

  useEffect(() => {
    if (project?.lead_source) setOtherLeadSource(project?.lead_source);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const setOtherLeadSource = (value: string) => {
    if (value?.toString() === "Other") {
      setIsOtherLeadSource(true);
    } else {
      setIsOtherLeadSource(false);
      form.setFieldsValue({ lead_source_specification: "" });
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        if (!project || !project.rfp_id) {
          return;
        }
        const response = await api.getProjectAssets(project.rfp_id);
        if (isMounted) {
          setAssets(response);

          setForms((prevState: AdminPagesForms) => ({
            ...prevState,
            projectForm: form,
          }));

          if (setHasAttachments && response.length > 0) setHasAttachments(true);
        }
      } catch (e: any) {
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [api, form, project, project?.rfp_id, setForms, setHasAttachments]);

  const onDeleteAsset = (assetId?: string) => {
    (async () => {
      try {
        if (project === undefined || assetId === undefined) {
          return;
        }
        const newAssets = [...assets];
        const index = newAssets.findIndex((a) => a.rfp_asset_id === assetId);
        if (index < 0) {
          return;
        }
        await api.deleteProjectAsset(assetId);
        newAssets.splice(index, 1);
        setAssets(newAssets);
        message.success("Saved!");
      } catch (e) {
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    })();
  };

  const getProjectDescriptionTemplate = (e: SyntheticEvent) => {
    e.stopPropagation();
    (async () => {
      try {
        if (
          project?.languages &&
          (project?.languages === Language.French ||
            project?.languages === Language.English)
        ) {
          var description_template =
            await api.fetchProjectDescriptionTemplateByLanguage(
              project?.languages
            );
          if (description_template.content) {
            form.setFieldValue(
              "description_template",
              description_template.content
            );
          }
        }
      } catch (e) {
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    })();
  };

  const getPotentialMatches = () => {
    (async () => {
      try {
        if (save) {
          save();
        }

        if (project) {
          dispatch(setSelectedProject(project));
          dispatch(
            toggleProjectsModal({
              type: "potentialProvider",
              isVisible: true,
            })
          );
        }
      } catch (e) {
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    })();
  };

  const Parchment = Quill.import("parchment");

  class Label extends Parchment.Embed {
    static create(value: any) {
      const node = super.create(value);
      node.innerText = value;
      node.contentEditable = "false";
      this._addRemovalButton(node);
      return node;
    }

    static value(node: any) {
      return node.childNodes[0].textContent;
    }

    static _addRemovalButton(node: any) {
      // Extra span forces the cursor to the end of the label, otherwise it appears inside the removal button
      const span = document.createElement("h4");
      node.appendChild(span);
    }
  }
  Label.blotName = "label";
  Label.tagName = "H4";
  Label.className = "ql-label";
  Quill.register(Label);

  const onAssetUpload = (asset: ProjectAsset) => {
    setAssets([...assets, asset]);
  };

  const matchPrice = project?.lead_price
    ? project.lead_price - (project.discounted_amount || 0)
    : 0;

  const adminOptions = admins.map((admin) => ({
    key: admin.admin_id,
    value: admin.admin_id,
    label: `${admin.first_name} ${admin.last_name}`,
  }));

  const { Panel } = Collapse;

  return (
    <Card style={{ padding: "1rem" }}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        name="project"
      >
        <Div flex="column">
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Please add a Title",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Original description" name="original_description">
            <TextArea disabled rows={4} />
          </Form.Item>
          {project && project.description && (
            <Form.Item>
              <Collapse
                style={{ backgroundColor: "#fff" }}
                defaultActiveKey={project.description ? "1" : "0"}
              >
                <Panel
                  header={
                    <Typography>
                      {project.description_template
                        ? "Old Description"
                        : "Description"}
                    </Typography>
                  }
                  key={"1"}
                >
                  <Form.Item
                    name="description"
                    tooltip="This information will show in all applications. The description type are as followed : 
                    Client Description, Project Description, Context, Goals, Details, Service Provider Requirement, Budget, Contact, Other"
                    rules={[
                      () =>
                        ({
                          required: true,
                          validator(_, value) {
                            if (value === "" || value === "<p><br></p>") {
                              return Promise.reject(
                                new Error("Please add a description")
                              );
                            }
                            return Promise.resolve();
                          },
                        }) as RuleObject,
                    ]}
                  >
                    <ReactQuill
                      className="text-editor"
                      theme="snow"
                      modules={quillModules}
                      preserveWhitespace={true}
                    />
                  </Form.Item>
                </Panel>
              </Collapse>
            </Form.Item>
          )}
          <Form.Item>
            <Collapse
              style={{ backgroundColor: "#fff" }}
              defaultActiveKey={project?.description_template ? "1" : "0"}
            >
              <Panel
                header={
                  <Typography>
                    {project?.description ? "New Description" : "Description"}
                  </Typography>
                }
                key={"1"}
                extra={
                  <Button
                    size={"small"}
                    onClick={(e: SyntheticEvent) =>
                      getProjectDescriptionTemplate(e)
                    }
                  >
                    Use template
                  </Button>
                }
              >
                <Form.Item
                  name="description_template"
                  tooltip="This information will show in all applications. The description type are as followed : 
            Client Description, Project Description, Context, Goals, Details, Service Provider Requirement, Budget, Contact, Other"
                  rules={[
                    () =>
                      ({
                        required: true,
                        validator(_, value) {
                          if (project?.description) return true;

                          if (value === "" || value === "<p><br></p>") {
                            return Promise.reject(
                              new Error("Please add a description")
                            );
                          }

                          const check = checkTypeTitle(value);
                          if (check) {
                            return Promise.reject(
                              new Error(
                                `Description header section ${check} is not valid`
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }) as RuleObject,
                  ]}
                >
                  <ReactQuill
                    className="text-editor"
                    theme="snow"
                    modules={quillModules}
                    preserveWhitespace={true}
                    onChange={(description) => {
                      if (description.includes("<strong>")) {
                        description = description.replace(/<\/?strong>/g, "");
                      }
                      form.setFieldValue("description_template", description);
                    }}
                  />
                </Form.Item>
              </Panel>
            </Collapse>
          </Form.Item>
          <Form.Item>
            <Collapse style={{ backgroundColor: "#fff" }}>
              <Panel
                header={
                  <Space>
                    <Typography>Special Conditions</Typography>
                    <Tooltip title="This information will only show in the partner app">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Space>
                }
                key={"1"}
              >
                <Form.Item name="special_conditions">
                  <ReactQuill
                    className="text-editor"
                    theme="snow"
                    modules={quillModules}
                  />
                </Form.Item>
              </Panel>
            </Collapse>
          </Form.Item>
          <Form.Item>
            <Collapse
              style={{ backgroundColor: "#fff" }}
              defaultActiveKey={project?.additional_comments ? "1" : "0"}
            >
              <Panel
                header={
                  <Space>
                    <Typography>Additional Comments</Typography>
                    <Tooltip title="This information will show in the partner app once the provider has purchased the project">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Space>
                }
                key={"1"}
              >
                <Form.Item name="additional_comments">
                  <TextArea rows={3} autoSize />
                </Form.Item>
              </Panel>
            </Collapse>
          </Form.Item>
          <Row>
            <Col span={8}>
              <Form.Item
                style={{ marginRight: "2rem" }}
                label="Project starts when"
                name="start_when"
              >
                <Select placeholder="Select option">
                  <Select.Option value={ProjectStartWhen.RightAway}>
                    Right away
                  </Select.Option>
                  <Select.Option value={ProjectStartWhen.ComingMonth}>
                    In the coming month
                  </Select.Option>
                  <Select.Option value={ProjectStartWhen.NextQuarter}>
                    In the next quarter
                  </Select.Option>
                  <Select.Option value={ProjectStartWhen.Flexible}>
                    Flexible
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Premium"
                name="is_premium"
                valuePropName={"checked"}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="AQT" name="is_aqt" valuePropName={"checked"}>
                <Switch />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="DIY"
                name="is_diy_activated"
                valuePropName={"checked"}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Div>
        <Title level={4} elementTheme="h6">
          Pricing
        </Title>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Min budget" name="budget_min">
              <InputNumberDollars />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Max budget" name="budget_max">
              <InputNumberDollars />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Budget type" name="budget_type">
              <Select>
                <Select.Option value="One Time">One Time</Select.Option>
                <Select.Option value="Weekly">Weekly</Select.Option>
                <Select.Option value="Monthly">Monthly</Select.Option>
                <Select.Option value="Yearly">Yearly</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Estimated value"
              name="estimated_value"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please add an estimated value")
                    );
                  },
                }),
                {
                  required: true,
                  message: "Please add an estimated value",
                },
              ]}
            >
              <InputNumberDollars />
            </Form.Item>
          </Col>
        </Row>

        <Title level={4} elementTheme="h6">
          Lead
        </Title>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Lead price"
              name="lead_price"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Please add a lead price"));
                  },
                }),
                {
                  required: true,
                  message: "Please add a lead price",
                },
              ]}
            >
              <InputNumberDollars
                disabled={project?.status !== ProjectStatus.PendingApproval}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Match price">
              <InputNumberDollars value={matchPrice} disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Project language" name="languages">
              <Select placeholder="Select options">
                <Select.Option value={Language.French}>French</Select.Option>
                <Select.Option value={Language.English}>English</Select.Option>
                <Select.Option value={Language.Any}>Any</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Providers Type" name="provider_type">
              <Select>
                <Select.Option value="Freelance">Freelance</Select.Option>
                <Select.Option value="Agency">Agency</Select.Option>
                <Select.Option value="Any">Any</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Max Providers" name="provider_max">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        {project?.type === ProjectType.RaiseHand && (
          <>
            <Title level={4} elementTheme="h6">
              Raise Hand
            </Title>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Raise Hand Price"
                  name="raise_hand_price"
                  rules={[
                    {
                      required: true,
                      message: "Please add a raise hand price",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Max Raised Hands" name="raise_hand_max">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Collapse
                    style={{ backgroundColor: "#fff" }}
                    defaultActiveKey={project?.rh_status_details ? "1" : "0"}
                  >
                    <Panel
                      header={
                        <Space>
                          <Typography>Raisehand status details</Typography>
                          <Tooltip title="This information will show in the partner app once the provider raises his hand on a project">
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </Space>
                      }
                      key={"1"}
                    >
                      <Form.Item name="rh_status_details">
                        <TextArea rows={3} autoSize />
                      </Form.Item>
                    </Panel>
                  </Collapse>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item>
              <Collapse style={{ backgroundColor: "#fff" }}>
                <Panel
                  header={
                    <>
                      <Row gutter={12}>
                        <Typography>Lead source</Typography>

                        {isOtherLeadSource && (
                          <Col
                            span={24}
                            onClick={(e: SyntheticEvent) => {
                              e.stopPropagation();
                            }}
                            style={{ cursor: "default" }}
                          >
                            <Form.Item
                              label="Specification"
                              name="lead_source_specification"
                              rules={[
                                {
                                  required: true,
                                  message: "Please add specify the lead source",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </>
                  }
                  key="1"
                  extra={
                    <Form.Item
                      name="lead_source"
                      rules={[
                        {
                          required: true,
                          message: "Please select a lead source type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onChange={(value) => {
                          setOtherLeadSource(value?.toString());
                        }}
                        onClick={(e: SyntheticEvent) => {
                          e.stopPropagation();
                        }}
                        style={{ width: "200px" }}
                      >
                        <Select.Option value={LeadSourceType.GrantsPackage}>
                          {LeadSourceType.GrantsPackage}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.AffiliateSP}>
                          {LeadSourceType.AffiliateSP}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.ReEngagement}>
                          {LeadSourceType.ReEngagement}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.RBC}>
                          {LeadSourceType.RBC}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.NonIcp}>
                          {LeadSourceType.NonIcp}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.Unsold}>
                          {LeadSourceType.Unsold}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.ClientApp}>
                          {LeadSourceType.ClientApp}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.ClientReferral}>
                          {LeadSourceType.ClientReferral}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.Direct}>
                          {LeadSourceType.Direct}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.Email}>
                          {LeadSourceType.Email}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.Facebook}>
                          {LeadSourceType.Facebook}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.Google}>
                          {LeadSourceType.Google}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.Organic}>
                          {LeadSourceType.Organic}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.Other}>
                          {LeadSourceType.Other}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.ReferenceshD}>
                          {LeadSourceType.ReferenceshD}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.Tradeshows}>
                          {LeadSourceType.Tradeshows}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.MCEL}>
                          {LeadSourceType.MCEL}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.AWQ}>
                          {LeadSourceType.AWQ}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.OolongMedia}>
                          {LeadSourceType.OolongMedia}
                        </Select.Option>
                        <Select.Option value={LeadSourceType.PCAN2}>
                          {LeadSourceType.PCAN2}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  }
                >
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item label="Creation source" name="creation_source">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Campaign ID" name="campaign_id">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item label="Ad groupd ID" name="ad_group_id">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Keyword" name="kw">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item label="Facebook Click ID" name="fbcl_id">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="FBC" name="fbc">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item label="Google Click ID" name="gcl_id">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Source URL" name="source_url">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item label="Landing Page URl" name="lp_url">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Referral Provider Id"
                        name="referral_provider_id"
                        tooltip="The lead price will be credited to the referring provider when the project is activated"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Form.Item>
          </Col>
        </Row>
        <Col>
          <Row gutter={12}>
            <Col>
              <Title level={4} elementTheme="h6">
                Assets
              </Title>
            </Col>
            {project && (
              <Col>
                <ProjectAssetUpload
                  projectId={project.rfp_id}
                  onAssetUpload={onAssetUpload}
                />
              </Col>
            )}
          </Row>
          <Row gutter={12}>
            <>
              {assets.length > 0 && (
                <Div style={{ padding: "0 1rem" }}>
                  <List
                    itemLayout="horizontal"
                    dataSource={assets}
                    renderItem={(item, index) => (
                      <List.Item key={index} style={{ width: "100%" }}>
                        <List.Item.Meta
                          title={
                            <a href={item.url}>
                              <Typography elementTheme="body2">
                                {item.filename}
                              </Typography>
                            </a>
                          }
                        />
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => onDeleteAsset(item.rfp_asset_id)}
                        ></Button>
                      </List.Item>
                    )}
                  />
                </Div>
              )}
            </>
          </Row>
        </Col>
        <Col>
          <Title level={4} elementTheme="h6">
            Managers
          </Title>
          <Form.Item label="Project manager" name="account_manager">
            <Select
              showSearch
              options={adminOptions}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
            />
          </Form.Item>
        </Col>
      </Form>
      <Div flex="row" justify="space-between">
        <Title level={4} elementTheme="h6">
          Tags
        </Title>

        <Button size={"small"} onClick={getPotentialMatches}>
          View Potential Matches
        </Button>
      </Div>

      <Div>
        {project && <TagsForm entityType={EntityType.Project} tags={tags} />}

        <Form layout="vertical">
          <Form.Item label="Internal Comments" name="internal_comments">
            <TextArea rows={3} autoSize />
          </Form.Item>
        </Form>
      </Div>
    </Card>
  );
};

export default ProjectForm;

