import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import LanguageTranslationsViewer from "@hellodarwin/core/lib/components/common/languages-translations-viewer";
import RichTextEditor from "@hellodarwin/core/lib/components/common/rich-editor";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import FormItemEditList from "@hellodarwin/core/lib/components/forms/form-builder/controllers/group/form-edit-list";
import renderInput from "@hellodarwin/core/lib/components/forms/form-builder/controllers/group/form-edit-list/render-input";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import FormLabel from "@hellodarwin/core/lib/components/forms/form-layouts/form-label";
import {
  InitialStepAssetValue,
  InitialStepValue,
  Step,
  StepAsset,
  StepFormFunction,
} from "@hellodarwin/core/lib/features/entities";
import getTranslationsFromString from "@hellodarwin/core/lib/features/helpers/get-translation-from-strings";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import theme from "@hellodarwin/core/lib/theme";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Help from "@hellodarwin/icons/dist/icons/Help";
import Form from "antd/es/form/Form";
import { Store } from "antd/es/form/interface";
import Input from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import Select from "antd/es/select";
import Tooltip from "antd/es/tooltip";
import React from "react";
import {
  GinMediumStepOption,
  GinStepOwnerOption,
  GinTypeStepOption,
} from "../../../utils/gin-select-option";
import getStepNumberLabel from "../../gin-step-card/get-step-number-label";
import GinStepAssetsForm from "./gin-steps-asset-form";

export interface GinStepsFormProps {
  functions: StepFormFunction;
  grantId: string;
  steps: Step[];
  setActiveStep: (step: Step | undefined) => void;
  activeStep: Step | undefined;
  setActiveAsset: (asset: StepAsset | undefined) => void;
  activeAsset: StepAsset | undefined;
}
const GinStepForm: React.FC<GinStepsFormProps> = ({
  grantId,
  steps,
  functions,
  setActiveStep,
  activeStep,
  setActiveAsset,
  activeAsset,
}) => {
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();

  const ginTypeStepOptionLabel = GinTypeStepOption!.map((item) => ({
    ...item,
    label: t(`gin_steps|step_type_${item.value}`),
  }));

  const ginMediumStepOption = GinMediumStepOption!.map((item) => ({
    ...item,
    label: t(`gin_steps|step_medium_${item.value}`),
  }));

  const ginStepOwnerOption = GinStepOwnerOption?.map((item) => ({
    ...item,
    label: t(`gin_steps|owner_${item.value}`),
  }));

  const addNewStep = () => {
    setActiveStep({ ...InitialStepValue });
  };
  const addNewAsset = () => {
    setActiveAsset({ ...InitialStepAssetValue });
  };
  const handleSubmitFormStep = async (step: Step) => {
    if (!!step.step_id) {
      const result = await functions.updateSelectedStep(step);
      if (result !== step) {
        setActiveStep(undefined);
      }
    } else {
      step.step_order = steps.length;
      const result = await functions.createNewStep(grantId, step);
      if (result !== InitialStepValue) {
        setActiveStep(undefined);
      }
    }
  };

  const reorder = (reorderdItems: Step[]) => {
    const newSteps = reorderdItems.map((step, index) => {
      return {
        ...step,
        step_order: index,
      };
    });
    functions.reorderSteps(grantId, newSteps);
  };

  return (
    <FormItem
      name={"gin_section_step"}
      layout="vertical"
      label={
        <FormLabel
          label={t("gin_section|step")}
          actions={
            <Button
              transparent
              defaultStyle={theme.colors.primary}
              onClick={() => addNewStep()}
            >
              <AddNew size={16} />
            </Button>
          }
        />
      }
    >
      <FormItemEditList<Step>
        options={steps ?? []}
        optionLabel={selectedLocale === "en" ? "title_en" : "title_fr"}
        typeLabel={t(`gin_section|step`)}
        renderLabel={(_, index) => {
          const stepTitle = getTranslationsFromString({
            translation_en: steps[index as number].title_en,
            translation_fr: steps[index as number].title_fr,
            fallback: steps[index as number].title_fr,
            selectedLocale,
          });
          return getStepNumberLabel({
            stepText: t("gin_section|step"),
            index: index ?? -1,
            fallback: t("gin_section|new_step"),
            stepTitle: `${stepTitle ?? ""}`,
          });
        }}
        optionTitleLabel={selectedLocale === "en" ? "title_en" : "title_fr"}
        renderTitleLabel={(stepTitle) => {
          return getStepNumberLabel({
            stepText: t("gin_section|step"),
            index: -1,
            fallback: t("gin_section|new_step"),
            stepTitle: `${
              activeStep?.title_en && selectedLocale === "en"
                ? activeStep?.title_en
                : activeStep?.title_fr ?? ""
            }`,
          });
        }}
        add={addNewStep}
        activeElement={activeStep}
        setActiveElement={setActiveStep}
        update={handleSubmitFormStep}
        reorder={reorder}
        renderForm={(inputs, form, activeItem, deleteItem) => (
          <Form
            form={form}
            initialValues={{ ...(activeItem as Store) }}
            layout="vertical"
          >
            <Div flex="column" gap={16}>
              <Div flex="column" gap={16}>
                {inputs.map((input) =>
                  renderInput({ form, input, activeItem, deleteItem })
                )}
              </Div>
              <Div flex="column" gap={16}>
                <Typography elementTheme="subtitle2">
                  {t(`gin_steps|step_external_content`)}
                </Typography>
                <LanguageTranslationsViewer
                  fields={[
                    {
                      content_en: "title_en",
                      content_fr: "title_fr",
                      renderField(field, locale) {
                        return (
                          <FormItem
                            label={t(`gin_steps|step_title_${locale}`)}
                            name={field}
                            rules={[
                              {
                                message: t(`gin_steps|step_title_required`),
                              },
                            ]}
                          >
                            <Input />
                          </FormItem>
                        );
                      },
                    },
                    {
                      content_en: "external_content_en",
                      content_fr: "external_content_fr",
                      renderField(field, locale) {
                        return (
                          <FormItem
                            label={t(
                              `gin_steps|step_external_content_${locale}`
                            )}
                            name={field}
                            rules={[
                              {
                                message: t(
                                  `gin_steps|step_external_content_required`
                                ),
                              },
                            ]}
                          >
                            <TextArea />
                          </FormItem>
                        );
                      },
                    },
                  ]}
                />
              </Div>
            </Div>
          </Form>
        )}
        drawerWidth={800}
        inputs={[
          {
            label: t("gin_steps|step_title_fr"),
            name: "title_fr",
            children: <Input />,
            rules: [
              { required: true, message: t("gin_steps|step_title_required") },
            ],
          },
          {
            label: t("gin_steps|step_medium"),
            name: "medium",
            rules: [
              {
                required: true,
                message: t("gin_steps|step_medium_required"),
              },
            ],
            children: <Select options={ginMediumStepOption} />,
          },
          {
            label: t("gin_steps|step_type"),
            name: "type",
            rules: [
              {
                required: true,
                message: t("gin_steps|step_type_required"),
              },
            ],
            children: <Select options={ginTypeStepOptionLabel} />,
          },
          {
            label: t("gin_steps|owner"),
            name: "owner",
            children: <Select options={ginStepOwnerOption} allowClear />,
          },
          {
            label: t("gin_steps|step_description"),
            name: "description",
            children: (
              <RichTextEditor initialValue={activeStep?.description || ""} />
            ),
          },
          {
            label: t("assets|files"),
            name: "form_select_asset",
            actions: activeStep?.step_id ? (
              <Button
                transparent
                defaultStyle={theme.colors.primary}
                onClick={() => addNewAsset()}
              >
                <AddNew size={16} />
              </Button>
            ) : (
              <Tooltip title={t("gin_steps|step_asset_tooltip")}>
                <>
                  <Help size={16} />
                </>
              </Tooltip>
            ),
            children: (
              <GinStepAssetsForm
                grantId={grantId}
                functions={functions}
                setActiveStep={setActiveStep}
                setActiveAsset={setActiveAsset}
                activeAsset={activeAsset}
                activeStep={activeStep}
              />
            ),
          },
        ]}
      />
    </FormItem>
  );
};

export default GinStepForm;

