import FilesList from "@hellodarwin/core/lib/components/files/files-list";
import {
  AssetHiddenType,
  AssetItemType,
  AssetWithOwnersEntity,
} from "@hellodarwin/core/lib/features/entities";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app";
import {
  deleteAsset,
  downloadAsset,
  fetchAssetsFromRecord,
  hideShowAsset,
  selectAllRecordAssets,
  uploadAsset,
} from "../../../../features/api/slices/assets-slice";
import { useNewAdminApi } from "../../../../features/api/use-admin-api";
import { GinBlockComponentProps } from "../gin-block";

export type setSelectedAssetFunc = React.Dispatch<
  React.SetStateAction<AssetWithOwnersEntity | undefined>
>;
const FileSectionGin = ({
  entityId,
  type,
  isEditing,
  handleEditing,
  additionalOwners,
}: GinBlockComponentProps) => {
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();
  const assets = useAppSelector((state) =>
    selectAllRecordAssets(state, entityId)
  );

  useEffect(() => {
    if (!!entityId) {
      dispatch(
        fetchAssetsFromRecord({
          api,
          record: {
            record_id: entityId,
            record_type: type === "application" ? "application" : "gin",
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, type]);

  const handleDownloadClick = async (asset: AssetItemType) => {
    try {
      const file = asset as AssetWithOwnersEntity;
      const url = (
        await dispatch(downloadAsset({ api, asset_id: file.asset_id })).unwrap()
      ).link;
      window.open(url, "download");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAsset = async (
    formData: FormData
  ): Promise<"rejected" | "fulfilled"> =>
    (
      await dispatch(
        uploadAsset({
          api,
          formData,
        })
      )
    ).meta.requestStatus;

  const handleHideShowAssets = (asset_id: string, hide: AssetHiddenType) => {
    dispatch(hideShowAsset({ api, asset_id, hide }));
  };
  const handleDelete = (assetId: string) => {
    dispatch(deleteAsset({ api, assetId }));
  };
  return (
    <>
      <FilesList
        vaultPath=""
        items={assets}
        activeRecord={undefined}
        handleDownload={handleDownloadClick}
        handleSaveAsset={handleSaveAsset}
        noHeader
        defaultIsRow={false}
        additionalOwners={additionalOwners}
        rootRecord={{
          record_id: entityId,
          record_type: type === "program" ? "gin" : "application",
        }}
        isEdit
        isMultiFiles
        multiDrawerOpen={isEditing}
        handleMultiDrawer={handleEditing}
        handleDeleteAsset={handleDelete}
        handleHideShowAsset={handleHideShowAssets}
      />
    </>
  );
};

export default FileSectionGin;

