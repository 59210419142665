import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import AssetEditRow from "@hellodarwin/core/lib/components/files/asset-drawer/asset-editor-drawer/edit-row";
import HideAssetModal from "@hellodarwin/core/lib/components/files/asset-drawer/multi-assets-drawer/hide-asset-modal";
import useGetAssetActions from "@hellodarwin/core/lib/components/files/utils/get-asset-actions";
import useGetAssetMeta from "@hellodarwin/core/lib/components/files/utils/get-asset-meta";
import {
  AssetEntity,
  AssetHiddenType,
  AssetItemType,
  AssetRowMeta,
  Step,
  StepAsset,
} from "@hellodarwin/core/lib/features/entities";
import parse, {
  HTMLReactParserOptions,
  domToReact,
} from "@hellodarwin/core/lib/features/helpers/parse";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import StepType from "@hellodarwin/icons/dist/icons/StepType";
import User from "@hellodarwin/icons/dist/icons/User";
import Video from "@hellodarwin/icons/dist/icons/Video";
import { useAppDispatch } from "admin/src/app";
import {
  downloadAsset,
  hideShowAsset,
} from "admin/src/features/api/slices/assets-slice";
import { deleteStepAsset } from "admin/src/features/api/slices/gin-steps-slice";
import { useNewAdminApi } from "admin/src/features/api/use-admin-api";
import Divider from "antd/es/divider";
import Empty from "antd/es/empty";
import { Element } from "html-react-parser";
import React, { useState } from "react";
import { GinStepCardSummary } from "../../styles";
import {
  GinStepCardContentSection,
  GinStepCardContentSectionWithIcon,
} from "./gin-step-card-content-section";

export const stepSectionOptions: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode.type === "tag") {
      const nodeTag = domNode as Element;

      if (nodeTag.name === "h2") {
        return (
          <Typography.Title
            level={2}
            elementTheme="body2"
            bold
            style={{ marginBottom: 8, marginTop: 16 }}
          >
            {domToReact(nodeTag.children)}
          </Typography.Title>
        );
      } else if (nodeTag.name === "ul") {
        return (
          <Typography elementTheme="body2" style={{ marginBottom: 4 }}>
            <ul
              style={{
                margin: 0,
                display: "flex",
                flexDirection: "column",
                gap: 6,
              }}
            >
              {domToReact(nodeTag.children, stepSectionOptions)}
            </ul>
          </Typography>
        );
      } else if (nodeTag.name === "li") {
        return <li>{domToReact(nodeTag.children)}</li>;
      } else if (nodeTag.name === "p") {
        return (
          <Typography elementTheme="body2" style={{ marginBottom: 8 }}>
            {domToReact(nodeTag.children)}
          </Typography>
        );
      }
    }
  },
};

const GinStepCardContentAdmin = ({
  medium,
  type,
  owner,
  description,
  external_content_en,
  external_content_fr,
  assets,
  handleOpenAsset,
}: Step & {
  handleOpenAsset: (asset: StepAsset) => void;
}) => {
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();
  const [openHideModal, setOpenHideModal] = useState(false);
  const [selectedMeta, setSelectedMeta] = useState<AssetRowMeta>();

  const closeHideModal = () => {
    setSelectedMeta(undefined);
    setOpenHideModal(false);
  };
  const showHideModal = (meta?: AssetRowMeta) => {
    setSelectedMeta(meta);
    setOpenHideModal(true);
  };

  const handleDownload = async (asset: AssetEntity) => {
    try {
      const url = (
        await dispatch(
          downloadAsset({ api, asset_id: asset.asset_id })
        ).unwrap()
      ).link;
      window.open(url, "download");
    } catch (error) {
      console.error(error);
    }
  };

  const openSingleDrawer = (meta?: AssetRowMeta) => {
    const asset = assets.find(
      (asset) =>
        asset.example?.asset_id === (meta ? meta.id : activeAssetItemId)
    );
    if (asset) handleOpenAsset(asset);
  };
  const [activeAssetItemId, setActiveItemId] = useState<string>();
  const handleDeleteAsset = async (example_asset_id: string) => {
    const asset = assets.find(
      (asset) => asset.example!.asset_id === example_asset_id
    );
    await dispatch(
      deleteStepAsset({
        api,
        assetID: asset?.step_asset_id || "",
        stepID: asset?.step_id || "",
      })
    );
  };

  const handleHideShowAsset = (asset_id: string, hide: AssetHiddenType) => {
    dispatch(hideShowAsset({ api, asset_id, hide }));
  };

  const onItemSelected = (example_asset_id?: string) => {
    setActiveItemId(example_asset_id);
    openSingleDrawer();
  };

  const onItemAction = (asset: AssetItemType) => {
    handleDownload(asset as AssetEntity);
  };

  const getAssetActions = useGetAssetActions({
    onItemAction,
    onItemSelected,
    setActiveItemId,
    handleDeleteAsset,
    handleHideShowAsset,
  });
  const getAssetMeta = useGetAssetMeta({ getAssetActions });

  return (
    <>
      <GinStepCardSummary>
        <Div
          gap={24}
          flex="row"
          style={{
            padding: "0px 24px",
          }}
        >
          <GinStepCardContentSectionWithIcon
            Icon={Video}
            title={t("gin_steps|step_medium")}
            value={t(`gin_steps|step_medium_${medium}`)}
          />
          <div
            style={{
              alignSelf: "stretch",
              borderLeft: `1px solid ${theme.colors.grey_4}`,
            }}
          />
          <GinStepCardContentSectionWithIcon
            Icon={StepType}
            title={t("gin_steps|step_type")}
            value={t(`gin_steps|step_type_${type}`)}
          />
          {owner && (
            <>
              <div
                style={{
                  alignSelf: "stretch",
                  borderLeft: `1px solid ${theme.colors.grey_4}`,
                }}
              />
              <GinStepCardContentSectionWithIcon
                Icon={User}
                title={t("gin_steps|owner")}
                value={t(`gin_steps|owner_${owner}`)}
              />
            </>
          )}
        </Div>
      </GinStepCardSummary>
      <Divider
        style={{
          borderColor: theme.colors.grey_4,
          margin: "0px",
        }}
      />
      <Div
        gap={20}
        flex="column"
        style={{
          padding: "16px 24px",
        }}
      >
        <GinStepCardContentSection title={t("gin_steps|step_description")}>
          {description ? (
            <Typography elementTheme="body2">
              {parse(description || "", stepSectionOptions)}
            </Typography>
          ) : (
            <Empty
              description={
                <Typography
                  elementTheme="subtitle2"
                  color={theme.colors.grey_2}
                >
                  {t("gin_steps|description_empty")}
                </Typography>
              }
            />
          )}
        </GinStepCardContentSection>

        <GinStepCardContentSection title={t(`assets|files`)}>
          <Div flex="column" gap={8}>
            {assets ? (
              React.Children.toArray(
                assets.map((elem) => {
                  if (elem.example) {
                    const meta = getAssetMeta(elem.example);
                    meta.name =
                      selectedLocale === "en" ? elem.name_en : elem.name_fr;
                    return (
                      <AssetEditRow
                        editAsset={openSingleDrawer}
                        meta={meta}
                        openHideModal={() => showHideModal(meta)}
                      />
                    );
                  }
                  return null;
                })
              )
            ) : (
              <Empty
                description={
                  <Typography
                    elementTheme="subtitle2"
                    color={theme.colors.grey_2}
                  >
                    {t("gin_steps|asset_empty")}
                  </Typography>
                }
              />
            )}
          </Div>
        </GinStepCardContentSection>
      </Div>
      <HideAssetModal
        open={openHideModal}
        onClose={closeHideModal}
        meta={selectedMeta}
      />
    </>
  );
};

export default GinStepCardContentAdmin;

