import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import { GrantPreview } from "@hellodarwin/core/lib/features/entities";
import { AdminFormsSubmission } from "@hellodarwin/core/lib/features/entities/form-entities";
import { getShortId } from "@hellodarwin/core/lib/features/helpers";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  fetchAllFormSubmission,
  fetchAllFormSubmissionByCompanyId,
  fetchAllFormSubmissionByFormId,
  fetchAllFormSubmissionByGrantId,
  selectAllFormsSubmissions,
} from "../../../features/api/slices/forms-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import Clipboard from "../../clipboard";
interface FormsSubmissionListProps {
  entityId: string;
  type: "form" | "grant" | "company" | "all";
}
const FormsSubmissionList = ({ entityId, type }: FormsSubmissionListProps) => {
  const api = useAdminApi();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const submissions = useAppSelector(selectAllFormsSubmissions);
  const navigate = useNavigate();
  const { t } = useTranslations();

  useEffect(() => {
    switch (type) {
      case "form":
        dispatch(fetchAllFormSubmissionByFormId({ api, formId: entityId }));
        break;
      case "grant":
        dispatch(fetchAllFormSubmissionByGrantId({ api, grantId: entityId }));

        break;
      case "company":
        dispatch(
          fetchAllFormSubmissionByCompanyId({ api, companyId: entityId })
        );
        break;

      default:
        dispatch(fetchAllFormSubmission({ api }));
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, entityId]);

  const goToSubmission = (submission: AdminFormsSubmission) => {
    navigate(`/forms/submissions/${submission.form_submission_id}`);
  };

  const openGrant = (grant: GrantPreview) => {
    window.open(`/grants/${grant.grant_id}`, "_blank");
  };
  const openContact = (company_id: string, contact_id: string) => {
    window.open(`/companies/${company_id}/users`, "_blank");
  };
  const openForm = (form_id: string) => {
    window.open(`/forms/${form_id}`, "_blank");
  };
  const openCompany = (company_id: string) => {
    window.open(`/companies/${company_id}`, "_blank");
  };

  const columns: ColumnsType<AdminFormsSubmission> = [
    {
      title: "Action",

      key: "action",
      width: 80,
      render: (data) => (
        <Div flex="row" gap={8} fitContent>
          <Button
            transparent
            defaultStyle={theme.colors.purple_1}
            onClick={() => {
              goToSubmission(data);
            }}
          >
            <NewWindow width={20} height={20} />
          </Button>
        </Div>
      ),
    },
    {
      title: t("formSubmission|submissionId"),
      dataIndex: "form_submission_id",
      key: "form_submission_id",
      render: (form_submission_id: string) => {
        return (
          <Clipboard
            title={getShortId(form_submission_id)}
            copy={form_submission_id}
          />
        );
      },
      width: 130,
    },
    {
      title: t("formSubmission|titleProgram"),
      dataIndex: "title",
      key: "title",

      render: (title, record) => {
        return !!record.form_id?.length ? (
          <Button
            isLink
            size="auto"
            onClick={() => openForm(record.form_id!)}
            defaultStyle={theme.colors.purple_1}
            trailingIcon={<NewWindow size={16} />}
          >
            {title}
          </Button>
        ) : (
          title
        );
      },
    },
    {
      title: t("formSubmission|contact_name"),
      dataIndex: "contact_name",
      key: "contact_name",
      render: (contact_name, record) => {
        const value = !!contact_name?.trim().length
          ? contact_name
          : record.contact_email;

        return !!record.contact_id?.length && !!record.company_id?.length ? (
          <Button
            isLink
            size="auto"
            onClick={() => openContact(record.company_id!, record.contact_id!)}
            defaultStyle={theme.colors.purple_1}
            trailingIcon={<NewWindow size={16} />}
          >
            {value}
          </Button>
        ) : (
          value
        );
      },
    },
    {
      title: t("formSubmission|company"),
      dataIndex: "company_name",
      key: "company_name",
      render: (company_name, record) => {
        const value = !!company_name?.trim().length
          ? company_name
          : record.contact_email;
        return !!record.company_id?.length ? (
          <Button
            isLink
            size="auto"
            onClick={() => openCompany(record.company_id!)}
            defaultStyle={theme.colors.purple_1}
            trailingIcon={<NewWindow size={16} />}
          >
            {value}
          </Button>
        ) : (
          value
        );
      },
    },
    {
      title: t("formSubmission|programsTitle"),
      dataIndex: "grants",
      key: "grants",
      width: 320,
      render: (grantPreviews: GrantPreview[]) => {
        return (
          <Div flex="row" gap={8} wrap="wrap">
            {grantPreviews?.map((grant) => (
              <HdTag
                color={theme.colors.purple_2}
                text={grant.display_title}
                hoverable
                icon={<NewWindow width={12} height={12} />}
                onClick={() => openGrant(grant)}
                iconPosition="end"
              />
            ))}
          </Div>
        );
      },
    },
    {
      title: t("formSubmission|created_on"),
      dataIndex: "created_at",
      key: "created_at",
      width: 120,
      render: (date) => {
        return new Date(date).toLocaleDateString();
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: 100,
    },
    {
      title: t("formSubmission|eligibilityState"),
      dataIndex: "eligibility_state",
      key: "eligibility_state",
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 80,
      render: (data) => (
        <Div flex="row" gap={8} fitContent>
          <Button
            transparent
            defaultStyle={theme.colors.purple_1}
            onClick={() => {
              goToSubmission(data);
            }}
          >
            <NewWindow size={16} />
          </Button>
        </Div>
      ),
    },
  ];

  return (
    <Div flex="column" gap={24}>
      <Table
        columns={columns}
        dataSource={submissions}
        sticky
        scroll={{ x: 1400 }}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              goToSubmission(record);
            },
          };
        }}
      />
    </Div>
  );
};

export default FormsSubmissionList;

