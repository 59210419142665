import { ExclamationCircleOutlined } from "@ant-design/icons";
import Container from "@hellodarwin/core/lib/components/common/container";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import ProfileUsersList from "@hellodarwin/core/lib/components/profile/profile-users-list";
import {
  Contact,
  ProjectSearchResult,
} from "@hellodarwin/core/lib/features/entities";
import {
  getFormattedDate,
  getPaginationTotal,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import theme from "@hellodarwin/core/lib/theme";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import Row from "antd/es/row";
import Table, { ColumnsType } from "antd/es/table";
import { TabsProps } from "antd/es/tabs";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import Auth0UserButtons from "../../components/auth0/contacts-auth0-buttons";
import Clipboard from "../../components/clipboard";
import CompanyCard from "../../components/companies/company-card";
import CompanyForm from "../../components/companies/company-form";
import AdminFilesList from "../../components/files/files-list";
import FormsSubmissionList from "../../components/forms/submissions/forms-submission-list";
import { EMAIL_ALREADY_EXISTS_ERROR } from "../../components/programs/programs-action-modal/create-program-form";
import {
  fetchCompany,
  selectCompanyById,
} from "../../features/api/slices/companies-slice";
import {
  assignContact,
  createContact,
  deleteContact,
  fetchCompanyContacts,
  getContactByEmail,
  selectCompanyContacts,
  selectIsLoading,
  updateContact,
} from "../../features/api/slices/users-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { getProjectStatusColor } from "../../features/utils";
import CompanyGrantsList from "./grants-list";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;
type PageState = {
  projects: ProjectSearchResult[];
  pagination: {
    page: number;
    size: number;
  };
};

export enum CompanyContent {
  Company = "company",
  Projects = "projects",
  Users = "users",
  Grants = "grants",
  FormsSubmissons = "submissions",
  Files = "files",
}

const SingleCompanyPage = ({ defaultTab }: { defaultTab: CompanyContent }) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const params = useParams<{
    id: string;
  }>();
  const company = useAppSelector((state) =>
    selectCompanyById(state, params.id || "")
  );
  const companyContacts = useAppSelector((state) =>
    selectCompanyContacts(state, company?.company_id || "")
  );
  const loading = useAppSelector(selectIsLoading);
  const pageStateInitialValues: PageState = {
    projects: [],
    pagination: {
      page: DEFAULT_PAGE,
      size: DEFAULT_LIMIT,
    },
  };
  const [activeTab, setActiveTab] = useState<CompanyContent | undefined>(
    defaultTab
  );
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);
  const [apiState, setApiState] = useState<{
    isLoading: boolean;
    isSearching: boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isSearching: false,
    isErrored: null,
  });

  const fetch = (
    page: number,
    size: number,
    query: string,
    loading: boolean,
    isSearching: boolean
  ) => {
    (async () => {
      setApiState({
        isLoading: loading,
        isSearching: isSearching,
        isErrored: null,
      });
      try {
        const response = await api.queryProjects(page, size, query);
        const newPageState = {
          projects: response,
          pagination: { page: page, size: size },
        };

        setPageState(newPageState);
        setApiState({ isLoading: false, isSearching: false, isErrored: null });
      } catch (e: any) {
        setApiState({ isLoading: false, isSearching: false, isErrored: e });
        console.error(e);
      }
    })();
  };

  const handleContactEdit = async (contact: Contact) => {
    return await dispatch(updateContact({ api, contact }));
  };

  useEffect(() => {
    dispatch(fetchCompany({ api, companyId: params.id || "" }));
    dispatch(fetchCompanyContacts({ api, companyId: params.id || "" }));
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, `${params.id}`, true, false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContactDelete = async (contactId: string) => {
    return await dispatch(deleteContact({ api, contactId }));
  };

  const handleContactAdd = async (contact: Contact) => {
    const createContactResult = await dispatch(
      createContact({
        api,
        contact: contact,
        destination: "Client",
      })
    );
    if (createContact.rejected.match(createContactResult)) {
      const error = createContactResult.payload;
      if (error && error.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        Modal.confirm({
          title: t("singleCompanyPage|contact_exists_modal_title"),
          icon: <ExclamationCircleOutlined />,
          okButtonProps: {
            style: {
              backgroundColor: theme.colors.purple_1,
              color: theme.colors.white_1,
              borderRadius: "24px",
            },
          },
          cancelButtonProps: {
            style: {
              backgroundColor: theme.colors.white_1,
              borderColor: theme.colors.grey_1,
              color: theme.colors.grey_1,
              borderRadius: "24px",
            },
          },
          content: t("singleCompanyPage|contact_exists_modal_content"),
          async onOk() {
            contact.company_id = company?.company_id || "";
            return await dispatch(assignContact({ api, contact: contact }));
          },
          onCancel() {},
        });
      } else {
        message.error(t("singleCompanyPage|messageError"));
      }
    }
    return createContactResult;
  };

  const handleEmailExists = async (email: string) => {
    if (companyContacts.find((c) => c.email === email)) {
      return Promise.reject(t("singleCompanyPage|emailExists"));
    }
    return await dispatch(getContactByEmail({ api, email }));
  };

  const handleContactAssign = async (contact: Contact) => {
    contact.company_id = company?.company_id || "";
    return await dispatch(assignContact({ api, contact: contact }));
  };

  const getContent = (tab: CompanyContent) => {
    if (company?.company_id) {
      switch (tab) {
        case CompanyContent.Projects:
          return (
            <>
              {pageState.projects && pageState.projects.length > 0 ? (
                <Row gutter={24}>
                  <Typography.Title level={3}>
                    {" "}
                    {t("singleCompanyPage|projects")}
                  </Typography.Title>
                  <Table
                    dataSource={pageState.projects}
                    columns={columns}
                    pagination={{
                      pageSize: pageState.pagination.size,
                      current: pageState.pagination.page,
                      total: getPaginationTotal(
                        pageState.pagination.page,
                        pageState.pagination.size,
                        pageState.projects?.length
                      ),
                      showSizeChanger: true,
                    }}
                    loading={apiState.isLoading}
                  />
                </Row>
              ) : (
                t("companyForm|noRelatedProject")
              )}
            </>
          );
        case CompanyContent.Company:
          return (
            <CompanyForm company={company} setForms={() => null}></CompanyForm>
          );
        case CompanyContent.Grants:
          return <CompanyGrantsList companyId={params.id || ""} />;
        case CompanyContent.Users:
          return (
            <>
              {company && company.company_id !== "" && (
                <ProfileUsersList
                  users={companyContacts}
                  companyId={company.company_id}
                  handleContactDelete={handleContactDelete}
                  handleContactAdd={handleContactAdd}
                  handleEmailExists={handleEmailExists}
                  handleContactEdit={handleContactEdit}
                  handleContactAssign={handleContactAssign}
                  Auth0Buttons={Auth0UserButtons}
                  isAdmin
                  destination={"Client"}
                  ClipboardComponent={({ copy, title }) => (
                    <Clipboard copy={copy} title={title} />
                  )}
                />
              )}
            </>
          );
        case CompanyContent.FormsSubmissons:
          return (
            <FormsSubmissionList type="company" entityId={company.company_id} />
          );
        case CompanyContent.Files:
          return (
            <AdminFilesList
              owner={{
                owner_type: "company",
                owner_id: company.company_id,
                owner_name: company.name,
              }}
              vaultPath={`/companies/${company.company_id}/files`}
            />
          );
        default:
          return <></>;
      }
    }
  };

  const tabs = useMemo(() => {
    let tabs: TabsProps["items"] = [];

    (Object.keys(CompanyContent) as (keyof typeof CompanyContent)[]).forEach(
      (key) => {
        tabs?.push({
          label: key,
          key: CompanyContent[key],
          children: getContent(CompanyContent[key]),
        });
      }
    );

    return tabs;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, companyContacts]);

  if (!params.id) return <Loading />;

  if (loading || !company) {
    return <Loading />;
  }

  const columns: ColumnsType<ProjectSearchResult> = [
    {
      title: t("singleCompanyPage|project_id"),
      dataIndex: "rfp_id",
      key: "rfp_id",
      render: (v) => <Clipboard copy={v} title={getShortId(v)} />,
    },
    {
      title: t("singleCompanyPage|status"),
      dataIndex: "project_status",
      key: "project_status",
      render: (v) => <Tag color={getProjectStatusColor(v)}>{v}</Tag>,
      sorter: {
        compare: (a, b) => a.project_status.localeCompare(b.project_status),
      },
    },
    {
      title: t("singleCompanyPage|titleProgram"),
      dataIndex: "title",
      key: "title",
      ellipsis: {
        showTitle: false,
      },
      render: (v) => (
        <Tooltip placement="topLeft" title={v}>
          {v}
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => a.title.localeCompare(b.title),
      },
    },
    {
      title: t("singleCompanyPage|companyName"),
      dataIndex: "company_name",
      key: "company_name",
      sorter: {
        compare: (a, b) => a.company_name.localeCompare(b.company_name),
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t("singleCompanyPage|created_on"),
      dataIndex: "project_created_at",
      key: "project_created_at",
      sorter: {
        compare: (a, b) =>
          new Date(a.project_created_at).getTime() -
          new Date(b.project_created_at).getTime(),
      },
      render: (v) => <Tooltip title={v}>{getFormattedDate(v)}</Tooltip>,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t("singleCompanyPage|category"),
      dataIndex: "project_category",
      key: "project_category",
      sorter: {
        compare: (a, b) => a.project_category.localeCompare(b.project_category),
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "is_premium",
      render: (v) =>
        v ? t("singleCompanyPage|yes") : t("singleCompanyPage|no"),
    },
  ];

  const handleTabClick = (key: CompanyContent) => {
    setActiveTab(key);
    if (company.company_id)
      navigate(
        key === CompanyContent.Company
          ? `/companies/${company.company_id}`
          : `/companies/${company.company_id}/${key}`,
        {
          replace: true,
        }
      );
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={company?.name}
      title={company?.name}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: t("companyAdminPage|companies"),
          path: "/companies",
        },
        {
          breadcrumbName: company?.name,
        },
      ]}
    >
      <Row gutter={24}>
        <CompanyCard company={company} />
      </Row>
      <Container
        tabs={tabs}
        activeTab={activeTab}
        handleTabClick={(activeTab) =>
          handleTabClick(activeTab as CompanyContent)
        }
        fullWidth
      />
    </PageLayout>
  );
};

export default SingleCompanyPage;

