import LanguageTranslationsViewer from "@hellodarwin/core/lib/components/common/languages-translations-viewer";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Step } from "@hellodarwin/core/lib/features/entities/gin-entities";

const GinStepCardContentClient = ({
  external_content_en,
  external_content_fr,
}: Step) => {
  return (
    <LanguageTranslationsViewer
      fields={[
        {
          content_en: external_content_en,
          content_fr: external_content_fr,
          renderField: (field) => (
            <Typography elementTheme="body2">{field}</Typography>
          ),
        },
      ]}
      ghost
    />
  );
};
export default GinStepCardContentClient;

