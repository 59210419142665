import { useTheme } from "styled-components";
import Div from "../div";
import { LanguageTranslationsViewerField } from "./types";

const LanguageTranslationsViewerFieldContainer = ({
  renderField,
  content_en,
  content_fr,
  ghost,
}: LanguageTranslationsViewerField) => {
  const theme = useTheme();
  return (
    <Div flex="row" align="stretch">
      <div
        style={{
          padding: "8px 24px",
          flex: 1,
          borderRight: !ghost ? `1px solid ${theme.colors.grey_4}` : undefined,
        }}
      >
        {renderField(content_en, "en")}
      </div>
      <div style={{ flex: 1, padding: "8px 24px" }}>
        {renderField(content_fr, "fr")}
      </div>
    </Div>
  );
};

export default LanguageTranslationsViewerFieldContainer;

