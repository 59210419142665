import Container from "@hellodarwin/core/lib/components/common/container";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import GrantCard from "@hellodarwin/core/lib/components/grants/card";
import Loading from "@hellodarwin/core/lib/components/loading";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  queryGinGrants,
  selectGinGrants,
  selectGinsIsLoading,
} from "../../features/api/slices/gins-slice";
import { useNewAdminApi } from "../../features/api/use-admin-api";
import GinFilter from "./gin-filter";

const GinList = () => {
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const ginGrants = useAppSelector(selectGinGrants);
  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, "programsList")
  );
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  const init = async () => {
    await dispatch(queryGinGrants({ api, locale: selectedLocale }));
  };

  const applyFilter = async (query: string, filter: string[]) => {
    try {
      await dispatch(
        queryGinGrants({ api, locale: selectedLocale, query, filter })
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <PageLayout
      app="admin"
      title={t("gin_program|gin")}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: t("gin_program|gin"),
        },
      ]}
    >
      <Container>
        <GinFilter applyFilter={applyFilter} />
        {isLoading ? (
          <Loading />
        ) : ginGrants && ginGrants.length > 0 ? (
          ginGrants.map((grant, index) => (
            <GrantCard {...grant} key={index} ginList={true} />
          ))
        ) : (
          <Typography
            textAlign="center"
            elementTheme="h5"
            style={{ fontWeight: 400 }}
            nowrap
          >
            {t(`gin_program|noGrants`)}
          </Typography>
        )}
      </Container>
    </PageLayout>
  );
};

export default GinList;

