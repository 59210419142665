import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Design from "@hellodarwin/icons/dist/icons/Design";
import Settings from "@hellodarwin/icons/dist/icons/Settings";
import Divider from "antd/es/divider";
import Popconfirm from "antd/es/popconfirm";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import ChatSettingsDrawer from "../../components/hd-chat/chat-drawer-settings";
import ChatTemplateDrawer from "../../components/hd-chat/chat-template-drawer";
import {
  fetchCompany,
  selectCompanyById,
} from "../../features/api/slices/companies-slice";
import {
  fetchChatQuestions,
  selectChatQuestions,
  selectQuestionsLoading,
} from "../../features/api/slices/hd-chat-questions-slice";
import {
  deleteChat,
  exportChat as exportChatSlice,
  fetchChatById,
  selectChatById,
  selectChatsLoading,
  updateChat,
} from "../../features/api/slices/hd-chats-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";
import QuestionContext from "./question-context";

const SingleChatPage = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const chat = useAppSelector((state) => selectChatById(state, id || ""));
  const questions = useAppSelector((state) =>
    selectChatQuestions(state, id || "")
  );
  const chatLoading = useAppSelector(selectChatsLoading);
  const questionsLoading = useAppSelector(selectQuestionsLoading);
  const [chatTemplateDrawer, setChatTemplateDrawer] = useState(false);
  const [settingsDrawer, setSettingsDrawer] = useState(false);
  const [currentSection, setCurrentSection] = useState("");
  const company = useAppSelector((state) =>
    selectCompanyById(state, chat?.company_id || "")
  );

  const [selectedAnswers, setSelectedAnswers] = useState(
    new Map<string, string>()
  );

  useEffect(() => {
    const scrollContainer = document.getElementById("scrollContainer");
    const handleScroll = () => {
      if (!scrollContainer) return;
      const containerTop = scrollContainer?.getBoundingClientRect().top;
      const scrollPosition =
        scrollContainer.scrollTop + containerTop + window.innerHeight / 2;
      const current = questions.find((question) => {
        const element = document.getElementById(question.id);
        if (element) {
          const { offsetTop, offsetHeight } = element;
          return (
            scrollPosition >= offsetTop &&
            scrollPosition < offsetTop + offsetHeight
          );
        }
        return false;
      });

      if (current) {
        setCurrentSection(current.id);
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [questionsLoading, questions]);

  const scrollToSection = (sectionId: string) => {
    const scrollContainer = document.getElementById("scrollContainer");
    if (scrollContainer) {
      const targetSection = document.getElementById(sectionId);
      if (targetSection) {
        const offsetTop = targetSection.offsetTop;
        scrollContainer.scrollTo({
          top: offsetTop - theme.appearance.pageHeaderHeight - 24,
          behavior: "smooth",
        });
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(fetchChatById({ api, chatId: id }));
    }
  }, [id, dispatch, api]);

  useEffect(() => {
    if (chat?.company_id) {
      dispatch(fetchCompany({ api, companyId: chat?.company_id || "" }));
    }
    if (chat?.locale) {
      dispatch(
        fetchChatQuestions({ api, chatId: chat?.id, locale: chat?.locale })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  const exportChat = (chatId: string) => {
    if (chatId) {
      const sortedAnswerIds = Array.from(
        selectedAnswers.entries(),
        ([index, id]) => ({
          id,
          index,
        })
      )
        .sort((a, b) => {
          const [mainA, subA] = a.index.split(".").map(Number);
          const [mainB, subB] = b.index.split(".").map(Number);
          if (mainA !== mainB) {
            return mainA - mainB;
          }
          return subA - subB;
        })
        .map((item) => item.id);
      dispatch(
        exportChatSlice({
          api: api,
          chatId: chatId,
          exportType: "grantApplication",
          answerIds: Array.from(sortedAnswerIds),
        })
      ).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          navigate(`/grant-application/${action.payload}`);
        }
      });
    }
  };

  if (!id || !chat || chatLoading) {
    return <Loading />;
  }

  return (
    <PageLayout
      app="admin"
      tabTitle="Conversation"
      styles={{
        content: {
          padding: 0,
          margin: `${theme.appearance.pageHeaderHeight}px 0px 0px 0px`,
        },
      }}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Chats",
          path: "/chats",
        },
        {
          breadcrumbName: chat?.title,
        },
      ]}
    >
      <Div
        flex="row"
        style={{
          height: `calc(100vh - ${theme.appearance.pageHeaderHeight + 16}px)`,
          overflow: "none",
          paddingTop: 1,
          maxWidth: "100%",
        }}
      >
        <Div
          flex="column"
          borderColor={theme.colors.black}
          style={{
            borderRight: "none",
            paddingTop: 16,
            paddingBottom: 16,
            maxWidth: `calc(100vw - 400px)`,
          }}
          gap={8}
        >
          <Title
            level={5}
            style={{ paddingLeft: 16 }}
            editable={{
              onChange: (newText) => {
                var newChat = { ...chat };
                newChat.title = newText;
                dispatch(updateChat({ api, chat: newChat }));
              },
              maxLength: 200,
            }}
          >
            {chat?.title}
          </Title>
          <Div
            flex="column"
            fullHeight
            id={"scrollContainer"}
            overflow="auto"
            style={{ paddingLeft: 16, paddingRight: 16 }}
          >
            {
              <>
                {!questions && questionsLoading ? (
                  <Loading />
                ) : (
                  <>
                    {questions.map((question, index) => (
                      <Div
                        flex="column"
                        key={`${question.id}-${index}`}
                        id={question.id}
                      >
                        <QuestionContext
                          key={index}
                          question={question}
                          chat={chat}
                          questionIndex={index}
                          setSelectedAnswers={setSelectedAnswers}
                        />
                        {index !== questions.length - 1 && (
                          <Divider
                            style={{
                              marginTop: 32,
                              marginBottom: 32,
                              borderColor: theme.colors.grey_4,
                            }}
                          />
                        )}
                      </Div>
                    ))}
                    {!chat.template_id && (
                      <Div
                        flex="row"
                        justify="center"
                        align="center"
                        gap={16}
                        fullHeight
                      >
                        <Button
                          onClick={() => setChatTemplateDrawer(true)}
                          defaultStyle={theme.colors.purple_1}
                          size="large"
                          headingIcon={<Design />}
                        >
                          <Typography.Title
                            elementTheme="h6"
                            level={4}
                            noMargin
                          >
                            Select Context Template
                          </Typography.Title>
                        </Button>
                      </Div>
                    )}
                  </>
                )}
              </>
            }
          </Div>
        </Div>
        <Div
          flex="column"
          backgroundColor={theme.colors.beige_2}
          borderColor={theme.colors.black}
          fullHeight
          style={{
            width: "30%",
            maxWidth: "360px",
            minWidth: "150px",
          }}
        >
          {chat.template_id && questions && (
            <Div
              justify="center"
              flex="row"
              backgroundColor={theme.colors.beige_2}
            >
              <Typography.Title style={{ margin: 8 }} level={3}>
                Table of content
              </Typography.Title>
            </Div>
          )}
          {chat.template_id && questions && (
            <Div
              flex="column"
              overflow="auto"
              style={{ padding: "0px 32px 16px 32px" }}
              fullHeight
              gap={20}
              backgroundColor={theme.colors.beige_2}
            >
              {questions.map((question, index) => (
                <Div
                  style={{
                    cursor: "pointer",
                    width: "100%",
                  }}
                  key={`${question.id}-${index}`}
                  bordersSide="bottom"
                  borderColor={
                    currentSection === question.id ? "theme.colors.black" : ""
                  }
                >
                  <Typography.Paragraph
                    elementTheme="caption"
                    onClick={() => scrollToSection(question.id)}
                    ellipsis
                    style={{
                      fontWeight:
                        currentSection === question.id ? "bold" : "normal",
                    }}
                  >
                    {question.prompt_name}
                  </Typography.Paragraph>
                </Div>
              ))}
            </Div>
          )}
          {chat.template_id && questions && (
            <Div
              flex="row"
              gap={16}
              justify="space-between"
              backgroundColor={theme.colors.beige_1}
              style={{ padding: 16, paddingTop: 24, width: "100%" }}
            >
              <Button
                size="auto"
                fitContent
                defaultStyle={theme.colors.purple_1}
                onClick={() => exportChat(chat?.id || "")}
              >
                <Typography>Export Chat</Typography>
              </Button>
              <Div flex="row" gap={16} fitContent>
                <Button
                  size="auto"
                  fitContent
                  defaultStyle={theme.colors.purple_1}
                  style={{ padding: 8 }}
                  onClick={() => setSettingsDrawer(true)}
                >
                  <Settings size={24} />
                </Button>
                <Popconfirm
                  title="Delete chat"
                  description="Are you sure to delete this chat?"
                  onConfirm={async () => {
                    const deletedChat = await dispatch(
                      deleteChat({ api, chatId: chat.id })
                    ).unwrap();
                    if (deletedChat) {
                      navigate("/chats");
                    }
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    size="auto"
                    fitContent
                    style={{ padding: 8, color: theme.colors.white_1 }}
                    defaultStyle={theme.colors.red_2}
                  >
                    <Delete size={24} />
                  </Button>
                </Popconfirm>
              </Div>
            </Div>
          )}
        </Div>
      </Div>
      <ChatSettingsDrawer
        chat={chat}
        loading={chatLoading}
        company={company}
        visible={settingsDrawer}
        onClose={() => setSettingsDrawer(false)}
      />
      <ChatTemplateDrawer
        chat={chat}
        visible={chatTemplateDrawer}
        onClose={() => setChatTemplateDrawer(false)}
      ></ChatTemplateDrawer>
    </PageLayout>
  );
};

export default SingleChatPage;

