import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import {
  AdminGrantResult,
  GrantTimeline,
} from "@hellodarwin/core/lib/features/entities";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Delete from "@hellodarwin/icons/dist/icons/Delete";
import {
  fetchAllPromptsForGrants,
  selectAllPrompts,
} from "admin/src/features/api/slices/hd-chat-prompts-slice";
import Button from "antd/es/button";
import { default as Form } from "antd/es/form";
import { Row } from "antd/es/grid";
import Input from "antd/es/input/Input";
import message from "antd/es/message";
import Skeleton from "antd/es/skeleton";
import dayjs from "dayjs";
import { Suspense, lazy, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  generateGrantTimelines,
  selectIsLoadingGen,
  selectIsLoadingSoloGen,
} from "../../../features/api/slices/grants-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import theme from "../../../theme";
import CreateUpdatePromptDrawer from "../../hd-chat/create-update-prompt-drawer";

const DatePicker = lazy(() => import("antd/es/date-picker"));

export const grantTimelineSectionTitle = "grant_timeline";
type GrantFormTimelineProps = {
  grant?: AdminGrantResult;
  form: any;
  isGeneratingAll?: boolean;
  isGeneratingRemaining?: boolean;
};

const GrantFormTimeline = ({
  grant,
  form,
  isGeneratingAll,
  isGeneratingRemaining,
}: GrantFormTimelineProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [grantsTimeline, setGrantsTimeline] = useState<GrantTimeline[]>(
    grant?.grant_timeline ?? []
  );
  const prompts = useAppSelector(selectAllPrompts);
  const [promptDrawerVisible, setPromptDrawerVisible] = useState(false);
  const isLoadingAny = useAppSelector((state) => selectIsLoadingGen(state));
  const isLoadingTimeline = useAppSelector((state) =>
    selectIsLoadingSoloGen(state, grantTimelineSectionTitle)
  );

  useEffect(() => {
    if (
      isGeneratingAll ||
      !grantsTimeline ||
      (isGeneratingRemaining && grantsTimeline.length <= 0)
    ) {
      handleGenerateTimeline();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeneratingAll, isGeneratingRemaining]);

  useEffect(() => {
    dispatch(fetchAllPromptsForGrants({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddDate = () => {
    var newDates: GrantTimeline = {
      grant_timeline_id: "",
      grant_id: grant?.grant_id!,
      opened_at: new Date(),
      closed_at: new Date(),
      type_fr: "",
      type_en: "",
    };
    if (!!grantsTimeline) {
      setGrantsTimeline([...grantsTimeline, newDates]);
    } else {
      setGrantsTimeline([newDates]);
    }
  };

  const accessScrapeContext = () => {
    window.open(`/grants/scrape-context/${grant?.grant_id}`, "_blank");
  };

  const handleGenerateTimeline = async () => {
    try {
      const generatedGrantTimeline = await dispatch(
        generateGrantTimelines({ api: api, grantId: grant?.grant_id! })
      ).unwrap();
      if (generatedGrantTimeline != null) {
        setGrantsTimeline(generatedGrantTimeline);
        form.setFieldValue("grant_timeline", generatedGrantTimeline);
        message.success("Grant Timeline Generated");
      } else {
        setGrantsTimeline([]);
        form.setFieldValue("grant_timeline", []);
        message.success("No timeline found");
      }
    } catch (e: any) {
      message.error("Something went wrong: ");
      message.error(e.cause);
      console.error(e);
    }
  };

  const handleRemoveDate = async (
    index: number,
    grantTimelineId: string | undefined
  ) => {
    const newGrantTimeline = grantsTimeline.filter((_, i) => i !== index);
    setGrantsTimeline(newGrantTimeline);
    form.setFieldValue("grant_timeline", newGrantTimeline);
    if (!!grantTimelineId)
      try {
        await api.deleteGrantTimeline(grantTimelineId);
      } catch (e: any) {
        console.error(e);
      }
  };

  const handleDateChange = (index: number, date: any, field: string) => {
    const newGrantTimeline = grantsTimeline.map((timeline, i) => {
      if (i === index) {
        return {
          ...timeline,
          [field]: date ? date.toDate() : null,
        };
      }
      return timeline;
    });
    setGrantsTimeline(newGrantTimeline);
    form.setFieldValue("grant_timeline", newGrantTimeline);
  };

  const handleTypeChange = (index: number, type: string, locale: string) => {
    const newGrantTimeline = grantsTimeline.map((timeline, i) => {
      if (i === index) {
        return {
          ...timeline,
          [locale]: type,
        };
      }
      return timeline;
    });
    setGrantsTimeline(newGrantTimeline);
    form.setFieldValue("grant_timeline", newGrantTimeline);
  };

  const displayGrantTimelines = () => {
    return isLoadingTimeline ? (
      <Div flex={"row"} gap={16} align="end" style={{ marginBottom: 16 }}>
        <Skeleton.Button active size="small" shape="circle" />
        <Div flex="column" fitMaxContent>
          <Typography elementTheme="caption">Opened At</Typography>
          <Skeleton.Input style={{ width: 150 }} active size="small" />
        </Div>
        <Div flex="column" fitMaxContent>
          <Typography elementTheme="caption">Closed At</Typography>
          <Skeleton.Input style={{ width: 150 }} active size="small" />
        </Div>
        <Div flex="column" fitMaxContent>
          <Typography elementTheme="caption">Type En</Typography>
          <Skeleton.Input style={{ width: 250 }} active size="small" />
        </Div>
        <Div flex="column" fitMaxContent>
          <Typography elementTheme="caption">Type Fr</Typography>
          <Skeleton.Input style={{ width: 250 }} active size="small" />
        </Div>
      </Div>
    ) : (
      !!grantsTimeline &&
        grantsTimeline.map((timeline, index) => (
          <>
            <Div
              flex={"row"}
              gap={16}
              key={index}
              align="end"
              style={{ marginBottom: 16 }}
            >
              <Button
                onClick={() =>
                  handleRemoveDate(index, timeline.grant_timeline_id)
                }
                type="text"
                size="small"
                danger={true}
                disabled={isLoadingTimeline}
                icon={<Delete size={24} />}
                style={{ marginBottom: 8 }}
              />
              <Div flex="column" fitMaxContent>
                <Typography elementTheme="caption">Opened At</Typography>
                <Suspense fallback={<Loading />} key={index}>
                  <DatePicker
                    size="large"
                    picker="date"
                    allowClear
                    value={
                      timeline.opened_at ? dayjs(timeline.opened_at) : null
                    }
                    onChange={(date) =>
                      handleDateChange(index, date, "opened_at")
                    }
                  />
                </Suspense>
              </Div>
              <Div flex="column" fitMaxContent>
                <Typography elementTheme="caption">Closed At</Typography>
                <Suspense fallback={<Loading />} key={index}>
                  <DatePicker
                    size="large"
                    picker="date"
                    allowClear
                    value={
                      timeline.closed_at ? dayjs(timeline.closed_at) : null
                    }
                    onChange={(date) =>
                      handleDateChange(index, date, "closed_at")
                    }
                  />
                </Suspense>
              </Div>
              <Div flex="column" fitMaxContent>
                <Typography elementTheme="caption">Type En</Typography>
                <Suspense fallback={<Loading />} key={index}>
                  <Input
                    type="text"
                    size="large"
                    width={250}
                    value={timeline.type_en || ""}
                    onChange={(e) =>
                      handleTypeChange(index, e.target.value, "type_en")
                    }
                  />
                </Suspense>
              </Div>
              <Div flex="column" fitMaxContent>
                <Typography elementTheme="caption">Type Fr</Typography>
                <Suspense fallback={<Loading />} key={index}>
                  <Input
                    type="text"
                    width={250}
                    size="large"
                    value={timeline.type_fr || ""}
                    onChange={(e) =>
                      handleTypeChange(index, e.target.value, "type_fr")
                    }
                  />
                </Suspense>
              </Div>
            </Div>
          </>
        ))
    );
  };

  return (
    <>
      <Row gutter={12} justify="space-between" style={{ marginBottom: "32px" }}>
        <Button
          color={theme.colors.purple_1}
          onClick={() => {
            handleGenerateTimeline();
          }}
          size="middle"
          type="primary"
          style={{ width: "200px" }}
          loading={isLoadingAny}
          disabled={isLoadingAny}
        >
          Generate Timeline
        </Button>
        <Div flex="row" gap={16} fitMinContent>
          <Button
            color={theme.colors.purple_1}
            type="default"
            onClick={accessScrapeContext}
          >
            See scrape context
          </Button>
          <Button onClick={() => setPromptDrawerVisible(true)}>
            Edit Prompt
          </Button>
        </Div>
      </Row>
      <Form.Item name={"grant_timeline"}>
        {displayGrantTimelines()}
        <Button
          onClick={() => handleAddDate()}
          type="text"
          size="middle"
          icon={<AddNew size={24} />}
        />
      </Form.Item>
      {prompts && (
        <CreateUpdatePromptDrawer
          visible={promptDrawerVisible}
          onClose={() => setPromptDrawerVisible(false)}
          prompt={
            prompts.find(
              (prompt) => prompt.name === "grant_timeline_for_script"
            )!
          }
        />
      )}
    </>
  );
};

export default GrantFormTimeline;

