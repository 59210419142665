import LoadingScreen from "@hellodarwin/core/lib/components/loading/screen";
import { useNewAdminApi } from "admin/src/features/api/use-admin-api";
import Empty from "antd/es/empty";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  selectCurrentGin,
  selectGinsIsLoading,
} from "../../../features/api/slices/gins-slice";
import {
  fetchGrant,
  selectGrantById,
} from "../../../features/api/slices/grants-slice";
import GinBlock from "../gin-single/gin-block";
import { GinSummaryBlock } from "../gin-single/gin-grant-summary";
import SingleGinHeader from "../gin-single/gin-single-header";
import { GinSingleContainer } from "../gin-single/styles";
import useGinGrantSummary from "./get-gin-grant-summary";
import GinGrantHeaderContent from "./grant-single-header";
import useGinBlocks, {
  GinGrantSinglePageSection,
} from "./use-gin-grant-blocks";

interface GinSingleGrantPageProps {
  grantId: string;
  sections: GinGrantSinglePageSection[];
}

const GinSingleGrantPage = ({ grantId, sections }: GinSingleGrantPageProps) => {
  const blocks = useGinBlocks();
  const adminGin = useAppSelector(selectCurrentGin);

  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, "singleGrant")
  );
  const activeSections = sections.filter(
    (s) => !!blocks[s] && !blocks[s]?.hide
  );
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (adminGin?.grant?.grant_id) {
      dispatch(fetchGrant({ api: api, grantId: adminGin.grant.grant_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminGin?.grant?.grant_id]);

  const grant = useAppSelector((state) =>
    adminGin?.grant?.grant_id
      ? selectGrantById(state, adminGin.grant.grant_id)
      : null
  );

  const summaryBlocks: GinSummaryBlock[] = useGinGrantSummary(
    grant || undefined
  );

  if (isLoading) return <LoadingScreen />;
  if (!grantId.length) return <Empty />;

  return (
    <GinSingleContainer>
      <SingleGinHeader
        HeaderContent={GinGrantHeaderContent}
        blocks={summaryBlocks}
      />
      {React.Children.toArray(
        activeSections.map((s) => (
          <GinBlock {...blocks[s]} entityId={grantId} type="program" />
        ))
      )}
    </GinSingleContainer>
  );
};

export default GinSingleGrantPage;

