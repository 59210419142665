interface getStepNumberLabelProps {
  stepText: string;
  fallback: string;
  stepTitle: string;
  index: number;
}
const getStepNumberLabel = ({
  index,
  stepText,
  stepTitle,
  fallback,
}: getStepNumberLabelProps) => {
  if (index < 0)
    if (!stepTitle) return fallback;
    else return stepTitle;

  return `${stepText} ${index + 1} : ${stepTitle}`;
};
export default getStepNumberLabel;

