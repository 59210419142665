import Div from "@hellodarwin/core/lib/components/common/div";
import {
  AdminGrantResult,
  HdChatPromptRequest,
} from "@hellodarwin/core/lib/features/entities";
import Check from "@hellodarwin/icons/dist/icons/Check";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { Row } from "antd/es/grid";
import Input from "antd/es/input";
import Skeleton from "antd/es/skeleton";
import { useState } from "react";
import ReactQuill from "react-quill";
import { useAppSelector } from "../../app";
import { selectIsLoadingSoloGen } from "../../features/api/slices/grants-slice";
import theme from "../../theme";
import { quillModules } from "../text-editor";
import { transformSectionIntoLabel } from "./grant-form/grant-form";
import GrantPromptButtons from "./grant-prompt-buttons";
const { TextArea } = Input;

type GrantPromptContentSectionProps = {
  section: string;
  setSelectedPrompt?: (prompt: HdChatPromptRequest) => void;
  setAddNewPromptDrawer?: (promptDrawer: boolean) => void;
  onSave: (() => Promise<void>) | undefined;
  grant?: AdminGrantResult | undefined;
  isTextArea: boolean;
  prompts?: HdChatPromptRequest[];
  isProject?: boolean;
  generateSection: (section: string) => void;
  rowsCount?: number;
};

const GrantPromptContentSection = ({
  section,
  setSelectedPrompt,
  setAddNewPromptDrawer,
  onSave,
  grant,
  isTextArea,
  prompts,
  isProject,
  generateSection,
  rowsCount = 10,
}: GrantPromptContentSectionProps) => {
  const isLoading = useAppSelector((state) =>
    selectIsLoadingSoloGen(state, section)
  );
  const [canSave, setCanSave] = useState<boolean>(false);

  const SkeletonTextArea = (rows: number) => (
    <Skeleton
      paragraph={{ rows: rows, width: "100%" }}
      style={{
        backgroundColor: theme.colors.white_1,
        padding: 10,
      }}
      active
    />
  );

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ width: "100%", marginBottom: "8px" }}
      >
        <Div style={{ width: "200px" }}>
          {transformSectionIntoLabel(section)}
        </Div>
        {isProject ? (
          <Button
            color={theme.colors.purple_1}
            onClick={() => {
              onSave!();
              setCanSave(false);
            }}
            disabled={!canSave}
          >
            <Check size={24} />
          </Button>
        ) : (
          <GrantPromptButtons
            section={section}
            setSelectedPrompt={setSelectedPrompt}
            setAddNewPromptDrawer={setAddNewPromptDrawer}
            onSave={onSave}
            grant={grant}
            generateContent={() => generateSection(section)}
            prompts={prompts}
            canSave={canSave}
            setCanSave={setCanSave}
          />
        )}
      </Row>
      {isLoading ? (
        SkeletonTextArea(5)
      ) : (
        <Form.Item name={section}>
          {isTextArea ? (
            <TextArea
              rows={rowsCount}
              onChange={() => {
                if (!canSave) setCanSave(true);
              }}
            />
          ) : (
            <ReactQuill
              className="text-editor"
              theme="snow"
              modules={quillModules}
              onChange={() => {
                if (!canSave) setCanSave(true);
              }}
            />
          )}
        </Form.Item>
      )}
    </>
  );
};

export default GrantPromptContentSection;

