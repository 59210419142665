import ProjectIgnoreModal from "@hellodarwin/core/lib/components/project/project-ignore-modal";
import ProviderRefuseModal from "@hellodarwin/core/lib/components/project/provider-refuse-modal";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  selectMatchesModal,
  selectSelectedMatch,
  toggleMatchesModal,
} from "../../../features/api/slices/matches-slice";
import {
  adminRefuseMatch,
  fetchProjectMatches,
  ignoreProject,
  selectSelectedProject,
} from "../../../features/api/slices/projects-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";

const MatchesActionModal = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectMatchesModal);
  const match = useAppSelector(selectSelectedMatch) || "";
  const project = useAppSelector(selectSelectedProject) || "";

  const handleClose = () => {
    dispatch(toggleMatchesModal({ isVisible: false, type: "" }));
  };

  const ignoreMatch = async (
    match_id: string,
    refusedReason: string,
    refusedReasonSpecified: string
  ) => {
    await dispatch(
      ignoreProject({
        api,
        matchId: match_id,
        refusedReason: refusedReason,
        refuseReasonSpecified: refusedReasonSpecified,
      })
    ).then(() => handleClose());
    if (!!project) {
      dispatch(fetchProjectMatches({ api, projectId: project.rfp_id }));
    }
  };

  const refuseMatch = async (
    match_id: string,
    refusedReason: string,
    refusedReasonSpecified: string
  ) => {
    await dispatch(
      adminRefuseMatch({
        api,
        matchId: match_id,
        refusedReason: refusedReason,
        refusedReasonSpecified: refusedReasonSpecified,
      })
    ).then(() => handleClose());
    if (!!project) {
      dispatch(fetchProjectMatches({ api, projectId: project.rfp_id }));
    }
  };

  switch (modal.type) {
    case "ignore":
      return (
        <ProjectIgnoreModal
          match={match}
          modalVisible={modal.isVisible}
          handleIgnore={ignoreMatch}
          closeModal={handleClose}
        />
      );
    case "refuse":
      return (
        <ProviderRefuseModal
          match={match}
          modalVisible={modal.isVisible}
          handleRefuse={refuseMatch}
          closeModal={handleClose}
        />
      );

    default:
      return <></>;
  }
};

export default MatchesActionModal;

