import { IconProps } from "../features/entities/general";
import CalculateFraction from "../utils/calculate-fraction";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const RightArrow = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox={`0 0 ${iconProps.width} ${iconProps.width}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`M1.5 ${iconProps.width / 2}H${iconProps.width / 2}L${
          iconProps.width - 1.5
        } ${iconProps.width / 2}`}
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d={`M${CalculateFraction({
          oldValue: 21,
          newValue: iconProps.width,
        })} ${CalculateFraction({
          oldValue: 6,
          newValue: iconProps.width,
        })}L${CalculateFraction({ oldValue: 31, newValue: iconProps.width })} ${
          iconProps.width / 2
        }L${CalculateFraction({
          oldValue: 21,
          newValue: iconProps.width,
        })} ${CalculateFraction({ oldValue: 26, newValue: iconProps.width })}`}
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default RightArrow;

