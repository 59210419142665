import Typography from "@hellodarwin/core/lib/components/common/typography";
import AddressAutocompleteInput from "@hellodarwin/core/lib/components/forms/form-groups/adress-autocomplete-input";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/lib/components/forms/utils/company-infos";
import validateWebsite from "@hellodarwin/core/lib/components/forms/utils/validate-website";
import { Provider } from "@hellodarwin/core/lib/features/entities";
import Language from "@hellodarwin/core/lib/features/enums/language";
import SingleLanguage from "@hellodarwin/core/lib/features/enums/single-language";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Collapse from "antd/es/collapse";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import InputNumber from "antd/es/input-number";
import Select from "antd/es/select";
import Space from "antd/es/space";
import Switch from "antd/es/switch";
import { Dispatch, SetStateAction, useEffect } from "react";
import ReactQuill from "react-quill";
import { AdminPagesForms } from "../../pages/single-project-page";
import theme from "../../theme";
import { quillModules } from "../text-editor";

const { Title } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

type ProviderFormProps = {
  provider?: Provider;
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
};

export type ProviderFormValues = {
  name: string;
  type: string;
  company_size: string;
  annual_revenue: string;
  project_language: string;
  province: string;
  postal_code: string;
  country: string;
  isCanada: boolean;
  description: string;
  comments: string;
  website: string;
  business_number: string;
  email: string;
  phone: string;
  address: string;
  facebook: string;
  twitter: string;
  linked_in: string;
  instagram: string;
  tiktok: string;
  pinterest: string;
  youtube: string;
  city: string;
  is_aqt: boolean;
  available_raise_hands: number;
  lat: number;
  lng: number;
  sb_promocode: string;
  profile_language: string;
};

const ProviderForm = ({ provider, setForms }: ProviderFormProps) => {
  const [form] = Form.useForm<ProviderFormValues>();
  const { t } = useTranslations();

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      providerForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    form.resetFields();
    if (provider) form.setFieldsValue(provider);
  }, [form, provider]);

  const initialValues: ProviderFormValues = {
    name: provider?.name ?? "",
    type: provider?.type === undefined ? "Agency" : provider?.type,
    company_size: provider?.size ?? "",
    annual_revenue: provider?.annual_revenue ?? "",
    project_language: provider?.project_language ?? "",
    province: provider?.province ?? "",
    description: provider?.description ?? "",
    comments: provider?.comments ?? "",
    website: provider?.website ?? "",
    business_number: provider?.business_number ?? "",
    email: provider?.email ?? "",
    phone: provider?.phone ?? "",
    address: provider?.address ?? "",
    postal_code: provider?.postal_code ?? "",
    country: provider?.country ?? "",
    isCanada: provider?.country === "Canada" || provider?.country === "",
    facebook: provider?.facebook ?? "",
    twitter: provider?.twitter ?? "",
    linked_in: provider?.linked_in ?? "",
    instagram: provider?.instagram ?? "",
    tiktok: provider?.tiktok ?? "",
    pinterest: provider?.pinterest ?? "",
    youtube: provider?.youtube ?? "",
    city: provider?.city ?? "",
    is_aqt: !!provider?.aqt_at,
    available_raise_hands: provider?.available_raise_hands ?? 0,
    lat: provider?.lat || 0,
    lng: provider?.lng || 0,
    sb_promocode: provider?.sb_promocode || "",
    profile_language: provider?.profile_language || SingleLanguage.English,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <Card style={{ padding: "1rem" }}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        name="company"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={t("provider_form|file_description")}
              name="description"
            >
              <ReactQuill
                className="text-editor"
                theme="snow"
                modules={quillModules}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="AQT" name="is_aqt" valuePropName={"checked"}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("provider_form|availableHands")}
              name="available_raise_hands"
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t("provider_form|promoCode")} name="sb_promocode">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={4} elementTheme="h6">
              {t("provider_form|companyDetails")}
            </Title>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("provider_form|form_name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("provider_form|companyNameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Space>
              <Form.Item
                label={t("provider_form|website")}
                name="website"
                rules={[
                  {
                    required: true,
                    validator: validateWebsite({
                      message: t("provider_form|websiteInvalid"),
                      isAdmin: true,
                      isRequired: true,
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {provider?.website && (
                <Button
                  onClick={() =>
                    window.open(
                      provider?.website?.includes("https://")
                        ? provider?.website
                        : "https://" + provider?.website,
                      "_blank"
                    )
                  }
                  icon={
                    <NewWindow
                      style={{ color: theme.colors.purple_1 }}
                      size={16}
                    />
                  }
                />
              )}
            </Space>
          </Col>
          <Col span={12}>
            <Form.Item label="Type" name="type">
              <Select placeholder="Select option">
                <Select.Option value="Agency">
                  {t("provider_form|agency")}
                </Select.Option>
                <Select.Option value="Freelance">Freelance</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Company size" name="company_size">
              <Select placeholder="Select option">
                {companySize.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t("provider_form|annualRevenue")}
              name="annual_revenue"
            >
              <Select placeholder="Select option">
                {annualRevenue.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("provider_form|businessNumber")}
              name="business_number"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t("provider_form|phone")} name="phone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t("provider_form|projectLanguage")}
              name="project_language"
            >
              <Select placeholder="Select options">
                <Select.Option value={Language.French}>Français</Select.Option>
                <Select.Option value={Language.English}>English</Select.Option>
                <Select.Option value={Language.Any}>Any</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t("provider_form|profileLanguage")}
              name="profile_language"
            >
              <Select placeholder="Select options">
                <Select.Option value={Language.French}>Français</Select.Option>
                <Select.Option value={Language.English}>English</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Col span={24}>
              <AddressAutocompleteInput form={form} showManualAddress />
            </Col>
          </Col>
          <Col span={24}>
            <Form.Item label={t("provider_form|only_email")} name="email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Collapse style={{ backgroundColor: "#fff" }}>
              <Panel
                header={<Typography>{t("provider_form|socials")}</Typography>}
                key={"1"}
              >
                <Form.Item label="Facebook" name="facebook">
                  <Input />
                </Form.Item>
                <Form.Item label="Twitter" name="twitter">
                  <Input />
                </Form.Item>
                <Form.Item label="Linkedin" name="linked_in">
                  <Input />
                </Form.Item>
                <Form.Item label="Instagram" name="instagram">
                  <Input />
                </Form.Item>
                <Form.Item label="Tiktok" name="tiktok">
                  <Input />
                </Form.Item>
                <Form.Item label="Pinterest" name="pinterest">
                  <Input />
                </Form.Item>
                <Form.Item label="Youtube" name="youtube">
                  <Input />
                </Form.Item>
              </Panel>
            </Collapse>
          </Col>
          <Col span={24}>
            <Form.Item label={t("provider_form|comments")} name="comments">
              <TextArea rows={4} autoSize />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ProviderForm;

