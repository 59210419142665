import FilesList from "@hellodarwin/core/lib/components/files/files-list";
import {
  AssetEntity,
  AssetOwnerInformation,
  AssetRecordInformation,
} from "@hellodarwin/core/lib/features/entities";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../app";
import {
  downloadAsset,
  fetchFoldersFromOwner,
  selectAllFolders,
  selectFolderById,
  uploadAsset,
} from "../../../features/api/slices/assets-slice";
import { useNewAdminApi } from "../../../features/api/use-admin-api";

interface AdminFilesListProps {
  owner: AssetOwnerInformation;
  vaultPath: string;
}

const AdminFilesList = ({ owner, vaultPath }: AdminFilesListProps) => {
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();

  const folders = useAppSelector(selectAllFolders);

  const { folderId } = useParams<{ folderId: string }>();
  const folder = useAppSelector((state: RootState) =>
    selectFolderById(state, folderId ?? "")
  );

  useEffect(() => {
    dispatch(fetchFoldersFromOwner({ api, owner }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner]);

  const handleDownload = async (asset: AssetEntity) => {
    try {
      const url = (
        await dispatch(
          downloadAsset({ api, asset_id: asset.asset_id })
        ).unwrap()
      ).link;
      window.open(url, "download");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAsset = async (
    formData: FormData
  ): Promise<"rejected" | "fulfilled"> =>
    (
      await dispatch(
        uploadAsset({
          api,
          formData,
        })
      )
    ).meta.requestStatus;

  const rootRecord: AssetRecordInformation = useMemo(
    () => ({
      record_id: owner.owner_id,
      record_name: owner.owner_name,
      record_type: owner.owner_type,
    }),
    [owner]
  );

  const activeRecord: AssetRecordInformation | undefined = useMemo(
    () => (!!folder.record_id ? folder : undefined),
    [folder]
  );

  return (
    <div>
      <FilesList
        handleDownload={handleDownload}
        items={
          !!folder && folder.asset_details.length > 0
            ? folder.asset_details
            : folders
        }
        vaultPath={vaultPath}
        handleSaveAsset={handleSaveAsset}
        activeRecord={activeRecord}
        rootRecord={rootRecord}
        additionalOwners={[owner]}
        allowUpload
      />
    </div>
  );
};

export default AdminFilesList;

