import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Save from "@hellodarwin/icons/dist/icons/Save";
import Drawer from "antd/es/drawer";
import { FormInstance, useForm } from "antd/es/form/Form";
import React, { ReactNode, useEffect, useState } from "react";
import { isObject } from "../../../../../../features/helpers/arrays-equal";
import { useTheme } from "../../../../../../plugins/styled";
import Button from "../../../../../common/button";
import Div from "../../../../../common/div";
import DragDropSingleColumnContainer from "../../../../../common/drag-drop/single";
import Typography from "../../../../../common/typography";
import { FormItemProps } from "../../../../form-layouts/form-item";
import { FormLabelProps } from "../../../../form-layouts/form-label";
import defaultRenderForm from "./default-render-form";
import { FormEditListItem } from "./styles";

export type EditListFormRender = <T>(
  inputs: FormItemEditInput<T>[],
  form: FormInstance<T>,
  activeItem: T,
  deleteItem?: (deletedItem: T) => void
) => ReactNode;
export interface FormItemEditInputRender<T> {
  render: (itm: T) => ReactNode;
  addItm?: () => void;
}
export interface FormItemEditInput<T> {
  name: string;
  label: string;
  children: ReactNode | FormItemEditInputRender<T>;
  rules?: FormItemProps["rules"];
  actions?: FormLabelProps["actions"];
}
export interface FormItemEditListProps<T> {
  options: T[];
  optionLabel: keyof T;
  optionTitleLabel?: keyof T;
  inputs: FormItemEditInput<T>[];
  typeLabel: string;
  reorder?: (reorderdItems: T[]) => void;
  add?: () => void;
  update?: (updatedItem: T) => void;
  deleteItem?: (deletedItem: T) => void;
  renderLabel?: (key: T[keyof T], index?: number) => ReactNode;
  renderForm?: EditListFormRender;
  renderTitleLabel?: (key: T[keyof T]) => ReactNode;
  activeElement?: T;
  drawerWidth?: number;
  setActiveElement?: (element: T | undefined) => void;
}

const FormItemEditList = <T,>({
  options,
  optionLabel,
  optionTitleLabel,
  inputs,
  typeLabel,
  reorder,
  update,
  renderLabel,
  renderTitleLabel,
  activeElement,
  deleteItem,
  setActiveElement,
  renderForm,
  drawerWidth,
}: FormItemEditListProps<T>) => {
  const theme = useTheme();

  const [form] = useForm<T>();
  const [activeItem, setActiveItem] = useState<T | undefined>(activeElement);

  const closeDrawer = () => {
    !!setActiveElement ? setActiveElement(undefined) : setActiveItem(undefined);
  };

  const openDrawer = (item: T) =>
    !!setActiveElement ? setActiveElement(item) : setActiveItem(item);

  useEffect(() => {
    setActiveItem(activeElement);
  }, [activeElement]);

  if (!options) return <></>;

  const getLabel = (option: T, isTitle?: boolean, index?: number) => {
    return !!renderTitleLabel && isTitle
      ? renderTitleLabel(option[optionTitleLabel ?? optionLabel])
      : !!renderLabel
        ? renderLabel(option[optionLabel], index)
        : (option[optionLabel] as string);
  };

  const handleUpdateOption = () => {
    form
      .validateFields()
      .then((values) => {
        const editedValues: T = { ...activeItem, ...values };
        !!update && update(editedValues);
      })
      .catch((e) => console.error(e));
  };

  const renderItem = (option: T, index?: number) => (
    <FormEditListItem onClick={() => openDrawer(option)}>
      <>{getLabel(option, false, index)}</>
      {!!deleteItem && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            deleteItem(option);
          }}
          trailingIcon={<Delete width={12} height={12} />}
          size="circle"
          defaultStyle={theme.colors.primary}
        />
      )}
    </FormEditListItem>
  );

  useEffect(() => {
    if (!!activeItem && isObject(activeItem)) {
      form.resetFields();
      Object.entries(activeItem).forEach(([key, value]) => {
        form.setFieldValue(key as any, value);
      });
    }
  }, [activeItem]);

  const renderFormFunc = !!renderForm ? renderForm : defaultRenderForm;

  return (
    <Div flex="column" gap={8}>
      {!!reorder ? (
        <DragDropSingleColumnContainer
          setItems={reorder}
          items={options}
          direction="vertical"
          itemStyle={{ padding: 0, marginBottom: 8, width: "100%" }}
          renderItem={renderItem}
        />
      ) : (
        React.Children.toArray(options.map(renderItem))
      )}

      <Drawer
        open={!!activeItem}
        onClose={closeDrawer}
        title={
          !!activeItem && (
            <Div
              align="center"
              flex="row"
              style={{ height: "100%" }}
              justify="space-between"
            >
              <Div fitContent flex="row" gap={8} align="center">
                <Typography elementTheme="subtitle1">{typeLabel} :</Typography>
                <Typography elementTheme="subtitle1">
                  {getLabel(activeItem, true)}
                </Typography>
              </Div>{" "}
              {!!update && (
                <Button
                  size="circle"
                  defaultStyle={theme.colors.white_1}
                  onClick={handleUpdateOption}
                >
                  <Save size={16} />
                </Button>
              )}
            </Div>
          )
        }
        width={drawerWidth}
      >
        {!!activeItem &&
          renderFormFunc<T>(inputs, form, activeItem, deleteItem)}
      </Drawer>
    </Div>
  );
};

export default FormItemEditList;

