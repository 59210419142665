import Div from "@hellodarwin/core/lib/components/common/div";
import Dropdown from "@hellodarwin/core/lib/components/common/dropdown";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import GenderSelect from "@hellodarwin/core/lib/components/forms/form-groups/gender-select";
import {
  Company,
  Contact,
} from "@hellodarwin/core/lib/features/entities/core-entities";
import {
  AdminProjectRequest,
  Project,
} from "@hellodarwin/core/lib/features/entities/project-entities";
import { selectInputEmail } from "@hellodarwin/core/lib/features/helpers";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Card from "antd/es/card";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import { MenuProps } from "antd/es/menu";
import Modal from "antd/es/modal";
import Select from "antd/es/select";
import Tooltip from "antd/es/tooltip";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../app";
import { updateProject } from "../../features/api/slices/projects-slice";
import { updateContact } from "../../features/api/slices/users-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { AdminPagesForms } from "../../pages/single-project-page";
import ExternalLink from "../widgets/external-link";
import AssignCompanyContactModal from "./assign-company-contact-modal";

import "./contacts-form.scss";

const { Title } = Typography;
const { TextArea } = Input;

type ContactsFormProps = {
  contact: Contact | undefined;
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
  company?: Company;
  destination: string;
  project?: Project;
};

const ContactsForm = ({
  contact,
  setForms,
  company,
  destination,
  project,
}: ContactsFormProps) => {
  const [form] = Form.useForm<Contact>();
  const { t } = useTranslation();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [assignModalOpen, setAssignModalOpen] = useState(false);

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      contactForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(contact ? contact : {});
  }, [form, contact]);

  const handleAssign = (contact_id: string, contact_name: string) => {
    if (project?.rfp_id) {
      Modal.confirm({
        title: t("project|contacts.assign"),
        content: t("project|contacts.assignConfirm", {
          contact_name: contact_name,
        }),
        okText: t("button.confirm"),
        cancelText: t("button.cancel"),
        okButtonProps: {
          style: {
            backgroundColor: theme.colors.purple_1,
            color: theme.colors.white_1,
            borderRadius: "24px",
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: theme.colors.white_1,
            borderColor: theme.colors.grey_1,
            color: theme.colors.grey_1,
            borderRadius: "24px",
          },
        },
        onOk() {
          const request: AdminProjectRequest = {
            rfp_id: project.rfp_id,
            company_id: company?.company_id || "",
            description: project.description,
            tags: project.tags,
            contact_id: contact_id,
          };
          dispatch(updateProject({ api, project: request }));
          setAssignModalOpen(false);
        },
      });
    } else {
      Modal.error({ title: "No project associated to this contact." });
      setAssignModalOpen(false);
    }
  };

  const actionMenuItems: MenuProps["items"] = [
    {
      label: t("project|contacts.assign"),
      key: 2,
      onClick: () => setAssignModalOpen(true),
    },
  ];
  return (
    <>
      <Card style={{ padding: "1rem" }}>
        <Form
          form={form}
          initialValues={
            contact
              ? contact
              : {
                  name: "",
                }
          }
          layout="vertical"
          name="contact"
        >
          <Div flex="column">
            <Div>
              <Div flex="row" justify="space-between">
                <Div>
                  <Title
                    level={4}
                    elementTheme="h6"
                    copyable={{
                      successMessage: [
                        contact?.contact_id,
                        "contact id copied",
                      ],
                      text: contact?.contact_id,
                    }}
                  >
                    Contact Details
                  </Title>
                </Div>
                {contact && (
                  <ExternalLink
                    id={contact.crm_id}
                    title={"Hubspot ID"}
                    url={contact.hubspot_url}
                    onEnd={(newValue) => {
                      dispatch(
                        updateContact({
                          api,
                          contact: {
                            ...contact,
                            ...{ crm_id: newValue },
                          },
                        })
                      );
                    }}
                  />
                )}
                <Dropdown items={actionMenuItems} cta={{ size: 24 }} />
              </Div>
              {contact && (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please add a name for the contact",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Title"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please add a title",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <GenderSelect />
                  </Col>
                  <Col span={8}>
                    <Tooltip
                      title={"Contact communications preferred language"}
                    >
                      <Form.Item
                        label="Language"
                        name="preferred_language"
                        rules={[
                          {
                            required: true,
                            message: "Please select a preferred language",
                          },
                        ]}
                      >
                        <Select placeholder="Select option">
                          <Select.Option value="fr">French</Select.Option>
                          <Select.Option value="en">English</Select.Option>
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>
              )}
            </Div>
            {contact && (
              <Row gutter={16}>
                <Col span={18}>
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please add a phone for the contact",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Ext" name="ext">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Login Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please add a email for the contact",
                      },
                    ]}
                  >
                    <Input onMouseDown={selectInputEmail} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Comments" name="comments">
                    <TextArea rows={contact.comments ? 4 : 1} autoSize />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Div>
        </Form>
      </Card>
      <AssignCompanyContactModal
        open={assignModalOpen}
        handleClose={() => setAssignModalOpen(false)}
        handleSubmit={handleAssign}
        companyId={company?.company_id || ""}
      />
    </>
  );
};

export default ContactsForm;

