import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import { PropsWithChildren, ReactNode } from "react";

export const GinStepCardContentSection = ({
  title,
  children,
}: { title: string } & PropsWithChildren) => {
  const theme = useTheme();
  return (
    <Div flex="column" gap={12} style={{ color: theme.colors.grey_2 }}>
      <Typography elementTheme="overline">{title}</Typography>
      <div>{children}</div>
    </Div>
  );
};

export const GinStepCardContentSectionWithIcon = ({
  title,
  Icon,
  value,
}: {
  title: string;
  Icon: IconNode;
  value: ReactNode;
}) => {
  const theme = useTheme();
  return (
    <Div flex="column" gap={4} style={{ padding: "12px 0" }}>
      <Div
        flex="row"
        gap={8}
        style={{ color: theme.colors.grey_2 }}
        align="center"
      >
        <Icon size={16} />
        <Typography elementTheme="overline">{title}</Typography>
      </Div>

      <Typography elementTheme="body2" style={{ paddingLeft: 16 + 8 }}>
        {value}
      </Typography>
    </Div>
  );
};

