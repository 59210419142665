import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import GrantCard from "@hellodarwin/core/lib/components/grants/card";
import { FundingExplorerFilterValues } from "@hellodarwin/core/lib/components/grants/funding-explorer/filter/types";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Settings from "@hellodarwin/icons/dist/icons/Settings";
import Badge from "antd/es/badge";
import Form from "antd/es/form";
import { useWatch } from "antd/es/form/Form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import Spin from "antd/es/spin";
import Tooltip from "antd/es/tooltip";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  searchGrantsWithAI,
  selectGrantSearchResults,
  selectIsLoading,
  selectSearchExplanation,
  selectSearchExtractedFilters,
  selectSearchInput,
  setSearchInput,
} from "../../features/api/slices/chatgpt-slice";
import {
  fetchGrantFinancingType,
  fetchGrantService,
  fetchProvinces,
  selectGrantFinancingType,
  selectGrantService,
  selectProvinces,
} from "../../features/api/slices/grants-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
} from "../../features/api/slices/tags-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import SearchGrantsSettingsDrawer from "./search-settings-drawer";

const { TextArea } = Input;

export enum SearchType {
  GENERATIVE_GROUPED = "generative_grouped",
  GENERATIVE_SINGLE = "generative_single",
  HYBRID = "hybrid",
}

export type GptFilterValues = {
  search_type: SearchType;
  max_results: number;
  hybrid_search_alpha: number;
  hybrid_search_autocut: number;
  rephrase: boolean;
  rephrase_model: string;
  rephrase_temperature: number;
  rephrase_max_tokens: number;
  extract_filters: number;
  rerank: boolean;
  rerank_model: string;
  rerank_temperature: number;
  rerank_max_tokens: number;
};

const SearchGrantsPage = () => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();
  const grantSeachResults = useAppSelector(selectGrantSearchResults);
  const provinces = useAppSelector(selectProvinces);

  const [grantsFiltersForm] = Form.useForm<FundingExplorerFilterValues>();
  const [gptFiltersForm] = Form.useForm<GptFilterValues>();

  const filterValues = useWatch([], grantsFiltersForm);

  const explanation = useAppSelector(selectSearchExplanation);
  const extractedFilters = useAppSelector(selectSearchExtractedFilters);
  const promptInput = useAppSelector(selectSearchInput);
  const handlePromptChange = useCallback(
    (e: any) => {
      dispatch(setSearchInput(e.target.value));
    },
    [dispatch]
  );
  const financingType = useAppSelector(selectGrantFinancingType);
  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector((state) =>
    selectIndustriesSubsectors(state, filterValues?.industry || [])
  );
  const [explanationModal, setExplanationModal] = useState(false);
  const services = useAppSelector(selectGrantService);
  const isLoading = useAppSelector(selectIsLoading);
  const [settingsDrawerVisible, setSettingsDrawerVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchGrantFinancingType({ api, locale: selectedLocale }));
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    dispatch(fetchProvinces({ api, locale: selectedLocale }));
    dispatch(fetchGrantService({ api, locale: selectedLocale }));
  }, [selectedLocale, api, dispatch]);

  const handleReload = () => {
    handleFetchResponse(filterValues);
  };
  const handleFetchResponse = useCallback(
    async (values: FundingExplorerFilterValues) => {
      const grantFilters = grantsFiltersForm.getFieldsValue();
      const gptFilters = gptFiltersForm.getFieldsValue();

      await dispatch(
        searchGrantsWithAI({
          api,
          prompt: promptInput,
          locale: selectedLocale,
          grantFilters: grantFilters,
          gptFilters: gptFilters,
        })
      );
    },
    [
      dispatch,
      grantsFiltersForm,
      gptFiltersForm,
      api,
      promptInput,
      selectedLocale,
    ]
  );

  const handleKeyDown = (e: any) => {
    if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
      handleReload();
    }
  };

  const totalFiltersCount = Object.values(
    grantsFiltersForm.getFieldsValue()
  ).filter(
    (value) =>
      value !== undefined && (Array.isArray(value) ? value.length > 0 : value)
  ).length;

  return (
    <PageLayout
      app="admin"
      title={
        <Div flex="row" gap={16} align="center">
          <Typography elementTheme="h6">Search Grants</Typography>
          <Tooltip title="Enter a playbook or simply a prompt and get a list of grants that match your query. The search is performed in 3 steps. 1) The prompt is rephrased to improve the search results. 2) The prompt is compared to the grant database to find the best matches. 3) If the 'Reranking' parameter is enabled, the results are reordered to improve the accuracy of the search.">
            <QuestionCircleOutlined />
          </Tooltip>
        </Div>
      }
      actions={
        <Badge count={totalFiltersCount}>
          <Button
            size="auto"
            fitContent
            defaultStyle={theme.colors.purple_1}
            style={{ padding: 8 }}
            onClick={() => setSettingsDrawerVisible(true)}
          >
            <Settings size={24} />
          </Button>
        </Badge>
      }
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Search Grants",
          path: "/search-grants",
        },
      ]}
    >
      <Div flex="column" gap={16} align="center">
        <TextArea
          rows={10}
          value={promptInput}
          onChange={handlePromptChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter your query here..."
        />
        <Button
          onClick={handleReload}
          disabled={isLoading}
          defaultStyle={theme.colors.purple_1}
        >
          {isLoading ? <Spin /> : "Submit Prompt"}
        </Button>
      </Div>
      <Div flex="row" justify="space-between" align="center">
        <Div flex="column">
          <Div flex="row">
            <Typography style={{ paddingRight: 8 }}>
              {t(`grants|results`)}
            </Typography>
            <Typography bold>
              {t(`grants|nbGrants`, {
                nb:
                  grantSeachResults && grantSeachResults.length > 0
                    ? grantSeachResults.length
                    : 0,
              })}
            </Typography>
          </Div>
          {extractedFilters.length > 0 && (
            <Typography style={{ textWrap: "nowrap" }} elementTheme="body3">
              Showing results for:
              {extractedFilters.map((filter, index) => (
                <HdTag
                  key={`tag-${index}`}
                  size="small"
                  color={theme.colors.grey_2}
                  text={filter}
                />
              ))}
            </Typography>
          )}
        </Div>
        {explanation && (
          <Link
            style={{ textWrap: "nowrap" }}
            onClick={() => setExplanationModal(true)}
            to={""}
          >
            View Explanation
          </Link>
        )}
      </Div>
      {grantSeachResults.map((grant, index) => (
        <GrantCard
          key={index}
          {...grant}
          isAdmin={true}
          search_score={grant.search_score}
        />
      ))}
      <SearchGrantsSettingsDrawer
        visible={settingsDrawerVisible}
        onClose={() => setSettingsDrawerVisible(false)}
        grantsFiltersForm={grantsFiltersForm}
        gptFiltersForm={gptFiltersForm}
        financingType={financingType}
        industries={industries}
        subIndustries={subindustries}
        provinces={provinces}
        services={services}
        fetch={handleFetchResponse}
        filterValues={filterValues}
      />
      <Modal
        title="Research Output Explanation"
        open={explanationModal}
        onCancel={() => setExplanationModal(false)}
        width={800}
        footer={null}
      >
        <TextArea size="small" rows={25} value={explanation} readOnly />
      </Modal>
    </PageLayout>
  );
};

export default SearchGrantsPage;

