import Card from "@hellodarwin/core/lib/components/common/Card";
import Div from "@hellodarwin/core/lib/components/common/div";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Forms } from "@hellodarwin/core/lib/features/entities/form-entities";
import { getStringDate } from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { useNavigate } from "react-router-dom";

const InfoRow = ({ label, value }: { label: string; value?: string }) => {
  if (!value) return <></>;
  return (
    <Div flex="row" gap={8} fitContent align="center">
      <Typography elementTheme="body2" bold>
        {label}
      </Typography>
      <Typography elementTheme="body2">{value}</Typography>
    </Div>
  );
};

const FormCard = ({ form }: { form: Forms }) => {
  const { selectedLocale } = useLocale();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslations();
  if (!form.form_id) return <></>;
  return (
    <Card
      hoverable
      onClick={() => navigate(`/forms/${form.form_id}`)}
      style={{
        padding: 24,
        width: "100%",

        flex: 1,
      }}
    >
      <Div flex="column" gap={24}>
        <Div flex="column" gap={4}>
          <Typography.Title level={1} elementTheme="h6">
            {selectedLocale === "en" ? form.title_en : form.title_fr}
          </Typography.Title>
          <Div flex="row" gap={24} align="center" justify="space-between">
            <InfoRow label="Type : " value={form.form_type} />
            <InfoRow
              label="Created at : "
              value={getStringDate(
                new Date(`${form.created_at}`),
                selectedLocale
              )}
            />
          </Div>
          <Typography elementTheme="body2">
            {selectedLocale === "en" ? form.subtitle_en : form.subtitle_fr}
          </Typography>
        </Div>
        <Div flex="column" gap={8}>
          <Typography elementTheme="body2" bold>
            {t("programs_admin|programsTitle")}
          </Typography>
          <Div flex="row" gap={8}>
            {form.grants?.map((grant) => (
              <HdTag color={theme.colors.purple_2} text={grant.display_title} />
            ))}
          </Div>
        </Div>
      </Div>
    </Card>
  );
};
export default FormCard;

