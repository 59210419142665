import Dropdown from "@hellodarwin/core/lib/components/common/dropdown";
import { Project } from "@hellodarwin/core/lib/features/entities";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import { getWebsiteURL, isDev } from "@hellodarwin/core/lib/features/helpers";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { DefaultTheme } from "@hellodarwin/core/lib/theme/index";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import { MenuProps } from "antd/es/menu";
import Modal from "antd/es/modal";
import notification from "antd/es/notification";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app";
import { fetchProjectMatches } from "../../features/api/slices/projects-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

type ProjectActionsProps = {
  project: Project;
};

const ProjectActions = ({ project }: ProjectActionsProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as DefaultTheme;

  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleDeleteProject = () => {
    if (!!project.activated_at) {
      Modal.error({
        title: t("project|actions.deleteError"),
      });
    } else {
      Modal.confirm({
        title: t("project|actions.delete"),
        content: t("project|actions.deleteConfirm"),
        okText: t("button.confirm"),
        cancelText: t("button.cancel"),
        okButtonProps: {
          style: {
            backgroundColor: theme.colors.purple_1,
            color: theme.colors.white_1,
            borderRadius: "24px",
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: theme.colors.white_1,
            borderColor: theme.colors.grey_1,
            color: theme.colors.grey_1,
            borderRadius: "24px",
          },
        },
        onOk() {
          return new Promise((resolve, reject) => {
            api
              .deleteProject(project.rfp_id || "")
              .then(() => {
                notification.success({
                  message: "successDelete",
                  placement: "topLeft",
                  duration: 0,
                });
                navigate("/projects");
                resolve("Suppression");
              })
              .catch((e: Error) => reject(e));
          }).catch(() => console.error("Oops errors!"));
        },
      });
    }
  };

  const handleResetMatches = async () => {
    await api.resetProjectMatches(project.rfp_id);
    await api.fetchProjectPurchasedMatches(project.rfp_id);

    dispatch(fetchProjectMatches({ api, projectId: project.rfp_id }));
  };

  const handleResetPurchases = async () => {
    await api.resetProjectPurchases(project.rfp_id);
    await api.fetchProjectPurchasedMatches(project.rfp_id);

    dispatch(fetchProjectMatches({ api, projectId: project.rfp_id }));
  };

  const handleResetRaiseHands = async () => {
    await api.resetProjectRaiseHands(project.rfp_id);
    await api.fetchProjectPurchasedMatches(project.rfp_id);

    dispatch(fetchProjectMatches({ api, projectId: project.rfp_id }));
  };

  const devActionMenuItems: MenuProps["items"] = useMemo(
    () => [
      {
        label: "Public Project Update",
        onClick: () =>
          window.open(
            getWebsiteURL() +
              "/project-status-update?token=" +
              project.status_update_token,
            "_blank"
          ),
        key: 1,
        icon: <NewWindow width={16} style={{ color: theme.colors.purple_1 }} />,
      },
      {
        label: t("project|actions.delete"),
        onClick: toggleDeleteProject,
        key: 2,
      },
      {
        label: "Reset Matches",
        onClick: handleResetMatches,
        key: 3,
      },
      {
        label: "Reset Purchases",
        onClick: handleResetPurchases,
        key: 4,
      },
      {
        label: "Reset Raise Hands",
        onClick: handleResetRaiseHands,
        key: 5,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project]
  );

  const actionMenuItems: MenuProps["items"] = useMemo(() => {
    const items: MenuProps["items"] = [];
    items?.push({
      label: "Public",
      onClick: () =>
        window.open(
          getWebsiteURL() +
            "/project-status-update?token=" +
            project.status_update_token,
          "_blank"
        ),
      key: 1,
      icon: <NewWindow width={16} style={{ color: theme.colors.purple_1 }} />,
    });
    if (
      project.status === ProjectStatus.PendingApproval ||
      project.status === ProjectStatus.Canceled
    ) {
      items?.push({
        label: t("project|actions.delete"),
        onClick: toggleDeleteProject,
        key: 1,
      });
    }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const items = useMemo(
    () => (isDev ? devActionMenuItems : actionMenuItems),
    [devActionMenuItems, actionMenuItems]
  );

  return <Dropdown items={items} cta={{ size: 32 }} />;
};

export default ProjectActions;

