import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import { ReactNode } from "react";
import { useTheme } from "styled-components";
import Loading from "../../../loading";
import Collapse from "../../Collapse";
import Typography from "../../typography";
import { SidebarBlockContainer, SidebarBlockHeader } from "./styles";

export interface SidebarBlockConfig {
  title: ReactNode;
  content: ReactNode | SidebarBlockConfig[];
  extra?: ReactNode;
}

export interface SidebarBlockProps extends SidebarBlockConfig {
  isLoading?: boolean;
}

const SidebarBlock = ({
  isLoading,
  content,
  title,
  extra,
}: SidebarBlockProps) => {
  const theme = useTheme();
  return (
    <SidebarBlockContainer>
      <Collapse
        ghost
        style={{ overflow: "hidden" }}
        defaultActiveKey={"sidebar-block"}
        noPadding
        styles={{
          panelContainer: {
            padding: "20px 32px",
          },
          panelContent: {
            paddingTop: 16,
          },
        }}
        items={[
          {
            id: "sidebar-block",
            title: (
              <SidebarBlockHeader>
                <Typography elementTheme="subtitle1">{title}</Typography>
              </SidebarBlockHeader>
            ),
            children: (
              <SidebarBlockContainer>
                {isLoading ? <Loading /> : (content as ReactNode)}
              </SidebarBlockContainer>
            ),
            extra,
          },
        ]}
        ExpandIcon={({ isActive }) => (
          <TabArrow down={isActive} size={12} color={theme.colors.grey_2} />
        )}
      />
    </SidebarBlockContainer>
  );
};

export default SidebarBlock;

