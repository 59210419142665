import Div from "@hellodarwin/core/lib/components/common/div";
import {
  Contact,
  GrantResult,
  Program,
  ProgramGrantStatus,
  ProgramType,
} from "@hellodarwin/core/lib/features/entities";
import { Company } from "@hellodarwin/core/lib/features/entities/core-entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import Close from "@hellodarwin/icons/dist/icons/Close";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import notification from "antd/es/notification";
import Radio from "antd/es/radio";

import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import Select from "antd/es/select";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  fetchAdmins,
  selectAdminIsLoading,
  selectAllAdmins,
} from "../../../features/api/slices/admins-slice";
import {
  fetchGlobalSearchResults,
  selectGlobalSearchLoading,
  selectGlobalSearchResults,
} from "../../../features/api/slices/search-global-slice";
import { createContact } from "../../../features/api/slices/users-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import useDebounce from "../../../hooks/use-debounce";
import GrantsSearch from "../../grants/grants-search";
import SelectCompany from "./select-company";

const SEARCH_DELAY_MS = 300;
export const EMAIL_ALREADY_EXISTS_ERROR = 5023;

type CreateProgramFormProps = {
  visible: boolean;
  handleClose: () => void;
  companyId?: string;
  companies?: Company[];
};

export const ProgramGrantStatusProd = [
  "ongoing_information_required",
  "ongoing_work_hellodarwin",
  "validation_required",
  "completed_to_submit",
  "submitted_waiting_for_results",
  "accepted",
  "refused",
  "not_submited",
];

const CreateProgramForm = ({
  visible,
  handleClose,
  companyId,
  companies,
}: CreateProgramFormProps) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isNewContact, setIsNewContact] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<
    Contact[] | undefined
  >();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedGrant, setSelectedGrant] = useState<GrantResult | undefined>();
  const admins = useAppSelector(selectAllAdmins);
  const isAdminsLoading = useAppSelector(selectAdminIsLoading);
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCompanyTerm, setSearchCompanyTerm] = useState("");
  const { selectedLocale } = useLocale();
  const searchCompanies = useAppSelector(selectGlobalSearchResults);
  const searchCompaniesLoading = useAppSelector(selectGlobalSearchLoading);
  const { t } = useTranslations();

  const [companyOptions, setCompanyOptions] = useState(
    companies?.map((company) => ({
      key: company.company_id,
      value: company.company_id,
      label: company.name,
    })) || undefined
  );

  const handleSubmitGrant = (grant: GrantResult) => {
    setSelectedGrant(grant);
    form.setFieldValue("program_grant_id", grant.grant_id);
  };

  useEffect(
    () => {
      setSelectedGrant(undefined);
      form.setFieldValue("program_grant_status", "identified_opportunitites");
      dispatch(
        fetchAdmins({
          api,
          needsAuth0: false,
          page: 1,
          limit: 100,
          searchedTerm: "",
        })
      );
      if (companyId && companyId !== "default") {
        initContacts(companyId);
      }
      if (companies) {
        setCompanyOptions(
          companies.map((company) => ({
            key: company.company_id,
            value: company.company_id,
            label: company.name,
          }))
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (selectedCompany) {
      initContacts(selectedCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    if (searchCompanies) {
      if (
        !searchCompanies ||
        !searchCompanies.companies ||
        searchCompanies.companies.length < 1
      ) {
        setCompanyOptions(undefined);
        return;
      }

      setCompanyOptions(
        searchCompanies.companies.map((company) => ({
          key: company.company_id,
          value: company.company_id,
          label: company.company_name,
        }))
      );
    }
  }, [searchCompanies]);

  const initContacts = async (companyId: string) => {
    try {
      const response = await api.queryCompanyContacts(companyId, 1, 20, "");
      setContacts(response);
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const onFinish = async (values: any) => {
    setIsSaving(true);
    try {
      let newProgram: Program = {
        ...values,
      };
      if (isNewContact) {
        const contactFetch = await api.getContactByEmail(values.contact_email);
        if (contactFetch) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: { data: { error_code: EMAIL_ALREADY_EXISTS_ERROR } },
          };
        }
        await dispatch(
          createContact({
            api,
            contact: {
              contact_id: "",
              name: values.contact_name,
              company_id: selectedCompany || "",
              preferred_language: values.project_language,
              email: values.contact_email,
            },
            destination: "Client",
          })
        );

        const contact = await api.fetchCompanyContacts(selectedCompany || "");
        newProgram.program_contact_id = contact[contact.length - 1].contact_id;
        newProgram.notification_contacts = [contact[contact.length - 1]];
      }

      if (companyId) {
        newProgram.program_company_id = companyId;
      }

      if (selectedCompany) {
        newProgram.program_company_id = selectedCompany;
      }

      newProgram.program_name = selectedGrant?.grant_title;
      if (selectedContacts && selectedContacts.length > 0) {
        newProgram.program_contact_id = selectedContacts[0].contact_id;
        newProgram.program_company_id = selectedContacts[0].company_id;
        newProgram.contacts = selectedContacts;
        newProgram.notification_contacts = selectedContacts;
      }

      newProgram.program_type = ProgramType.GrantsRoadmap;
      const program = await api.createProgram(newProgram);

      setSelectedGrant(undefined);
      setIsSaving(false);
      handleClose();
      if (!companyId || companies) {
        navigate("/programs/" + program.program_id);
      }
      message.success("Saved!");
    } catch (e: any) {
      setIsSaving(false);

      if (e.response?.data?.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        notification.error({
          message: "Email already exists",
          description: "This email is already associated with a contact",
        });
        return;
      }

      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const onSearch = async (query: string) => {
    if (query === "" && (!companyId || companyId === "default")) return;

    setIsSearching(true);
    try {
      const response = companyId
        ? await api.queryCompanyContacts(companyId, 1, 8, query)
        : await api.queryContacts(1, 8, query, true);
      setContacts(response);
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    } finally {
      setIsSearching(false);
    }
  };

  const onSearchCompanies = async (query: string) => {
    if (query === "" && !selectedCompany) return;
    try {
      await dispatch(
        fetchGlobalSearchResults({
          api: api,
          query: query,
          locale: selectedLocale,
        })
      );
    } catch (e: any) {
      console.error(e);
      return;
    }
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);
  const debouncedSearchCompany = useDebounce(
    searchCompanyTerm,
    SEARCH_DELAY_MS
  );

  const adminOptions = admins.map((admin) => ({
    key: admin.admin_id,
    value: admin.admin_id,
    label: `${admin.first_name} ${admin.last_name}`,
  }));

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    onSearchCompanies(debouncedSearchCompany);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchCompany]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleSearchCompany = (value: string) => {
    setSearchCompanyTerm(value);
  };

  return (
    <Modal
      title={t("createApplication|create_application_title")}
      afterClose={() => {
        setIsNewContact(false);
        setSelectedContacts(undefined);
        setSelectedGrant(undefined);
        form.resetFields();
      }}
      open={visible}
      okText={t("createApplication|button_create")}
      okButtonProps={{ loading: isSaving }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
      cancelText={t("createApplication|cancel")}
      onCancel={handleClose}
      keyboard={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="create-program"
      >
        <Row gutter={16}>
          <Col span={24}>
            {!!companies && (
              <Form.Item
                label={t("createApplication|application_company")}
                name="company_id"
                rules={[
                  {
                    required: true,
                    message: t("createApplication|missing_company"),
                  },
                ]}
              >
                <SelectCompany
                  companyOptions={companyOptions}
                  isSearching={searchCompaniesLoading}
                  onSearch={handleSearchCompany}
                  value={selectedCompany}
                  onSelectCompany={(value) => {
                    setSelectedCompany(value);
                    form.setFieldsValue({ company_id: value });
                  }}
                />
              </Form.Item>
            )}
            <Form.Item
              label={t("createApplication|application_manager")}
              name="program_account_manager"
              rules={[
                {
                  required: true,
                  message: t("createApplication|missing_application_manager"),
                },
              ]}
            >
              <Select
                showSearch
                loading={isAdminsLoading}
                options={adminOptions}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .indexOf(input?.toLowerCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item
              label={t("createApplication|application_program")}
              name="program_grant_id"
              rules={[
                {
                  required: true,
                  message: t("createApplication|missing_application_program"),
                },
              ]}
            >
              <Div flex="row">
                <GrantsSearch handleSubmit={handleSubmitGrant} />
                {selectedGrant && (
                  <Div
                    flex="row"
                    align="center"
                    justify="center"
                    style={{ width: "fit-content", marginLeft: 8 }}
                  >
                    <Tag
                      closeIcon={<Close height={8} width={8} />}
                      onClose={() => {
                        setSelectedGrant(undefined);
                        form.setFieldValue("program_grant_id", undefined);
                      }}
                    >
                      {selectedGrant.grant_title
                        ? selectedGrant.grant_title.length > 20
                          ? selectedGrant.grant_title?.substring(0, 20) + "..."
                          : selectedGrant.grant_title
                        : ""}
                    </Tag>
                  </Div>
                )}
              </Div>
            </Form.Item>
            <Form.Item
              label={t("createApplication|status")}
              name="program_grant_status"
              onReset={() =>
                form.setFieldValue(
                  "program_grant_status",
                  "identified_opportunitites"
                )
              }
            >
              <Select
                onChange={(e) => {
                  form.setFieldValue("program_grant_status", e);
                }}
              >
                {(
                  Object.keys(
                    ProgramGrantStatus
                  ) as (keyof typeof ProgramGrantStatus)[]
                ).map((key, index) => (
                  <Select.Option key={index} value={ProgramGrantStatus[key]}>
                    {ProgramGrantStatus[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Div style={{ marginBottom: 15 }}>
              {companyId ? (
                <>
                  <Form.Item
                    label={t("createApplication|contact")}
                    name="notifications_contacts"
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: t("createApplication|minimum_contact"),
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      loading={isSearching}
                      filterOption={false}
                      showSearch
                      placeholder={t(
                        "createApplication|search_company_contacts"
                      )}
                      defaultActiveFirstOption={false}
                      onChange={(values) => {
                        const selectedContacts = contacts.filter((c) =>
                          values.includes(c.contact_id)
                        );
                        setSelectedContacts(selectedContacts);
                        form.setFieldValue("notification_contacts", values);
                        if (companyId) {
                          const value =
                            values.length > 0 ? values[0] : "placeholder";
                          form.setFieldValue("program_contact_id", value);
                        }
                      }}
                      onSearch={handleSearch}
                    >
                      {contacts.map((c) => (
                        <Select.Option key={c.contact_id} value={c.contact_id}>
                          {c.name} / {c.email}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Div
                    flex="row"
                    justify="space-between"
                    style={{ marginBottom: 15 }}
                  >
                    <Typography.Text strong>
                      {t("createApplication|for") + ":"}{" "}
                    </Typography.Text>
                    <Radio.Group
                      options={[
                        {
                          label: t("createApplication|contact_exist"),
                          value: "0",
                        },
                        {
                          label: t("createApplication|new_contact"),
                          value: "1",
                        },
                      ]}
                      onChange={(e) => {
                        setSelectedContacts(undefined);
                        setIsNewContact(e.target.value === "1");
                      }}
                      value={isNewContact ? "1" : "0"}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Div>

                  {!isNewContact ? (
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      loading={isSearching}
                      filterOption={false}
                      showSearch
                      placeholder={t(
                        "createApplication|search_company_contacts"
                      )}
                      defaultActiveFirstOption={false}
                      onChange={(value) => {
                        const selectedContacts = contacts.filter((c) =>
                          value.includes(c.contact_id)
                        );
                        setSelectedContacts(selectedContacts);
                        form.setFieldValue("notification_contacts", value);
                        if (companyId) {
                          form.setFieldValue(
                            "program_contact_id",
                            value ?? "placeholder"
                          );
                        }
                      }}
                      onSearch={handleSearch}
                    >
                      {contacts.map((c) => (
                        <Select.Option key={c.contact_id} value={c.contact_id}>
                          {c.name} / {c.email}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <>
                      <Col span={24}>
                        <Form.Item
                          label={t("createApplication|fullName")}
                          name="contact_name"
                          rules={[
                            {
                              required: true,
                              message: t("createApplication|missing_fullName"),
                            },
                          ]}
                        >
                          <Input placeholder="Name" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t("createApplication|only_email")}
                          name="contact_email"
                          rules={[
                            {
                              required: true,
                              message: t("createApplication|emailRequired"),
                            },
                            {
                              type: "email",
                              message: t("createApplication|email_valid"),
                            },
                          ]}
                        >
                          <Input placeholder={t("createApplication|email")} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t("createApplication|preferredLanguage")}
                          name="project_language"
                          rules={[
                            {
                              required: true,
                              message: t("createApplication|language_required"),
                            },
                          ]}
                        >
                          <Select
                            placeholder={t("createApplication|selectLanguage")}
                          >
                            <Select.Option value="fr">Français</Select.Option>
                            <Select.Option value="en">English</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </>
              )}
            </Div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateProgramForm;

