import { ContainerIcons } from "@hellodarwin/icons/dist/backgrounds/icon-entities";
import LeftArrow from "@hellodarwin/icons/dist/icons/LeftArrow";
import { ReactNode, useEffect, useState } from "react";
import { useAppData } from "../../../../features/providers/app-data-provider";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../button";
import Div from "../../div";

import AppHeader from "../app-layout/app-header";
import { PageLayoutProps } from "../page-layout";
import PageContainer, {
  PageContainerProps,
} from "../page-layout/PageContainer";
import { SidebarBlockConfig } from "./sidebar-block";
import SidebarPageSider from "./sidebar-page-sider";
import { SidebarPageBody, SidebarPageContent } from "./styles";

export interface SidebarPageLayoutProps extends PageLayoutProps {
  leftSidebarBlocks?: SidebarBlockConfig[];
  rightSidebarBlocks?: SidebarBlockConfig[];
  defaultNavCollapsed?: boolean;
  underSearch?: ReactNode;
  noFooter?: boolean;
  fitContent?: boolean;
}

const SidebarPageLayout = ({
  tabTitle,
  styles,
  noGap,
  children,
  className,
  noPadding,
  leftSidebarBlocks = [],
  rightSidebarBlocks = [],
  defaultNavCollapsed = false,
  underSearch,
  noFooter,
  fitContent,
  actions,
  handleBack,
  ...appHeaderProps
}: SidebarPageLayoutProps) => {
  const { title, app } = appHeaderProps;

  const { t } = useTranslations();
  const theme = useTheme();
  const { setNavCollapsed } = useAppData();

  const [leftNavCollapsed, setLeftNavCollapsed] = useState(false);
  const [rightNavCollapsed, setRightNavCollapsed] = useState(false);
  const handleLeftNavCollapsed = () => setLeftNavCollapsed(!leftNavCollapsed);
  const handleRightNavCollapsed = () =>
    setRightNavCollapsed(!rightNavCollapsed);

  useEffect(() => {
    if (!!defaultNavCollapsed) {
      setNavCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultNavCollapsed]);

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (!!tabTitle) {
        document.title = `${tabTitle} | ${t(`appsNames|${app}`)}`;
      } else if (
        (typeof title === "string" || title instanceof String) &&
        !!title
      ) {
        document.title = `${title} | ${t(`appsNames|${app}`)}`;
      } else {
        document.title = t(`appsNames|${app}`);
      }
    }
  }, [tabTitle, title]);

  const containerProps: PageContainerProps = {
    className,
    cap: app === "admin" ? theme.caps.xl : theme.caps.normal,

    styles: {
      ...styles,
      container: {
        ...styles?.container,
        position: "relative",
      },
    },
    noPadding: true,
    app,
  };

  return (
    <>
      {app === "admin" && (
        <AppHeader {...appHeaderProps} style={styles?.header} />
      )}
      <PageContainer
        {...containerProps}
        icons={[
          {
            side: "right",
            name: ContainerIcons.Group1Filled,
            color: theme.colors.beige_1,
            top: 300,
            margin: -300,
            width: 900,
            height: 800,
          },
        ]}
        noFooter={noFooter}
      >
        <Div position="relative" flex="column">
          {app !== "admin" && (
            <AppHeader {...appHeaderProps} style={styles?.header} />
          )}
          <Div
            borderColor={theme.colors.primary}
            backgroundColor={theme.colors.beige_1}
            justify="space-between"
            bordersSide="horizontal"
            flex="row"
            align="center"
            style={{ borderColor: theme.colors.grey_5, padding: "8px 32px" }}
          >
            <Button
              size="circle"
              defaultStyle={theme.colors.white_1}
              onClick={handleBack}
              headingIcon={<LeftArrow size={18} />}
              style={{
                transition: "none",
              }}
            />
            {underSearch && <>{underSearch}</>}
          </Div>
          <SidebarPageContent
            style={{
              minHeight: `calc(
100vh - ${theme.appearance.appHeaderHeight}px
)`,
            }}
          >
            <SidebarPageSider
              sidebarBlocks={leftSidebarBlocks}
              isCollapsed={leftNavCollapsed}
              side="left"
              handleCollapse={handleLeftNavCollapsed}
            />
            <SidebarPageBody style={{ ...styles?.body }}>
              {children}
            </SidebarPageBody>
            <SidebarPageSider
              sidebarBlocks={rightSidebarBlocks}
              isCollapsed={rightNavCollapsed}
              side="right"
              handleCollapse={handleRightNavCollapsed}
            />
          </SidebarPageContent>
        </Div>
      </PageContainer>
    </>
  );
};

export default SidebarPageLayout;

