import Div from "@hellodarwin/core/lib/components/common/div";
import { GrantApplication } from "@hellodarwin/core/lib/features/entities";
import {
  getPaginationTotal,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";
import { useAdminApi } from "../../features/api/use-admin-api";

const GrantApplicationsList = () => {
  const api = useAdminApi();

  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 50;
  const { t } = useTranslations();

  type PageState = {
    grantApplications: any[];
    pagination: {
      page: number;
      size: number;
    };
  };

  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageStateInitialValues: PageState = {
    grantApplications: [],
    pagination: {
      page: DEFAULT_PAGE,
      size: DEFAULT_LIMIT,
    },
  };
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);
  const [apiState, setApiState] = useState<{
    isLoading: boolean;
    isSearching: boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isSearching: false,
    isErrored: null,
  });

  const fetch = (
    page: number,
    size: number,
    loading: boolean,
    isSearching: boolean
  ) => {
    (async () => {
      setApiState({
        isLoading: loading,
        isSearching: isSearching,
        isErrored: null,
      });
      try {
        setApiState({ isLoading: true, isSearching: true, isErrored: null });

        const response = await api.queryGrantApplications(page, size);
        const newPageState = {
          grantApplications: response?.map((grantApplication, index) => ({
            key: index,
            ...grantApplication,
          })),
          pagination: { page: page, size: size },
        };
        setPageState(newPageState);
        setApiState({ isLoading: false, isSearching: false, isErrored: null });
      } catch (e: any) {
        setApiState({ isLoading: false, isSearching: false, isErrored: e });
        console.error(e);
      }
    })();
  };

  const onPageChange = (page: number, size: number) => {
    fetch(page, size, true, false);
  };

  const columns: ColumnsType<any> = [
    {
      title: t("programs_admin|programsApplication"),
      dataIndex: "id",
      key: "id",
      render: (v) =>
        v ? (
          <Link target="_blank" to={`/grant-application/${v}`}>
            {getShortId(v)}
          </Link>
        ) : (
          <></>
        ),
      width: 110,
    },
    {
      title: t("programs_admin|programId"),
      dataIndex: "grant_id",
      key: "grant_id",
      render: (v) =>
        v ? (
          <Link target="_blank" to={`/grants/${v}`}>
            {getShortId(v)}
          </Link>
        ) : (
          <></>
        ),
      width: 110,
    },
    {
      title: "Chat ID",
      dataIndex: "chat_id",
      key: "chat_id",
      render: (v) =>
        v ? (
          <Link target="_blank" to={`/chats/${v}`}>
            {getShortId(v)}
          </Link>
        ) : (
          <></>
        ),
      width: 110,
    },
    {
      title: t("programs_admin|status"),
      dataIndex: "status",
      key: "status",
    },
    {
      title: t("programs_admin|created_on"),
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: t("programs_admin|updatedAt"),
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: t("programs_admin|completed_at"),
      dataIndex: "completed_at",
      key: "completed_at",
    },
    Table.EXPAND_COLUMN,
  ];

  // const applyFilter = async () => {
  //   fetch(DEFAULT_PAGE, DEFAULT_LIMIT, true, false);
  // };

  return (
    <Div flex="column" style={{ marginTop: 24 }} gap={24}>
      <Table
        dataSource={pageState.grantApplications}
        columns={columns}
        pagination={{
          pageSize: pageState.pagination.size,
          current: pageState.pagination.page,
          total: getPaginationTotal(
            pageState.pagination.page,
            pageState.pagination.size,
            pageState.grantApplications?.length
          ),
          showSizeChanger: true,
          onChange: (page, size) => onPageChange(page, size ?? DEFAULT_LIMIT),
        }}
        loading={apiState.isLoading}
        expandable={{
          expandedRowRender: (grantApplication: GrantApplication) => (
            <ReactQuill value={grantApplication.content} readOnly />
          ),
        }}
      />
    </Div>
  );
};

export default GrantApplicationsList;

