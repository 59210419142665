import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";
const Print = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M8.37333 18.6263C8.02667 18.6693 7.68 18.7153 7.33333 18.7641M8.37333 18.6263C13.4379 17.9946 18.5621 17.9946 23.6267 18.6263M8.37333 18.6263L7.82444 24.6154M23.6267 18.6263C23.9733 18.6693 24.32 18.7153 24.6667 18.7641M23.6267 18.6263L24.1756 24.6154L24.5063 28.2382C24.5268 28.4617 24.5002 28.687 24.4282 28.8997C24.3562 29.1124 24.2404 29.3079 24.0882 29.4737C23.936 29.6394 23.7507 29.7718 23.5442 29.8625C23.3376 29.9531 23.1143 29.9999 22.8886 30H9.11144C8.15522 30 7.407 29.1844 7.49367 28.2382L7.82444 24.6154M7.82444 24.6154H6.25C5.38805 24.6154 4.5614 24.275 3.9519 23.6691C3.34241 23.0632 3 22.2415 3 21.3846V12.3471C3 10.7949 4.10933 9.45374 5.65344 9.224C6.57204 9.08741 7.4933 8.96916 8.41667 8.86933M24.1727 24.6154H25.7486C26.1755 24.6156 26.5982 24.5321 26.9927 24.3699C27.3872 24.2076 27.7456 23.9696 28.0476 23.6696C28.3495 23.3696 28.5891 23.0134 28.7525 22.6213C28.9159 22.2292 29 21.809 29 21.3846V12.3471C29 10.7949 27.8907 9.45374 26.3466 9.224C25.428 9.08741 24.5067 8.96917 23.5833 8.86933M23.5833 8.86933C18.5427 8.32412 13.4573 8.32412 8.41667 8.86933M23.5833 8.86933V3.61538C23.5833 2.72369 22.8553 2 21.9583 2H10.0417C9.14467 2 8.41667 2.72369 8.41667 3.61538V8.86933M24.6667 13.8462H24.6782V13.8576H24.6667V13.8462ZM20.3333 13.8462H20.3449V13.8576H20.3333V13.8462Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Print;

