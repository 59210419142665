import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";

import {
  AdminProfile,
  AdminProfileInitialState,
  AdminProfileResponse,
  ApiError,
  ApiErrorInitialState,
  FindAuth0IDResponse,
} from "@hellodarwin/core/lib/features/entities";
import { CreateAuth0UserResponse } from "@hellodarwin/core/lib/features/entities/core-entities";
import { RootState } from "../../../app/app-store";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";
import { me } from "./global-slice";

export const fetchAdminById = createAsyncThunk<
  AdminProfile,
  { api: AdminApiClient; adminId: string },
  { rejectValue: ApiError }
>(
  "admim/fetchAdminById",
  async (
    { api, adminId }: { api: AdminApiClient; adminId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchAdminById(adminId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAdmins = createAsyncThunk<
  AdminProfileResponse,
  {
    api: AdminApiClient;
    needsAuth0: boolean;
    page: number;
    limit: number;
    searchedTerm: string;
  },
  { rejectValue: ApiError }
>(
  "admim/fetchAdmins",
  async (
    {
      api,
      needsAuth0,
      page,
      limit,
      searchedTerm,
    }: {
      api: AdminApiClient;
      needsAuth0: boolean;
      page: number;
      limit: number;
      searchedTerm: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.getAdmins(needsAuth0, page, limit, searchedTerm);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);
export const refreshAdmins = createAsyncThunk<
  AdminProfileResponse,
  { api: AdminApiClient },
  { rejectValue: ApiError }
>(
  "admim/refreshAdmins",
  async ({ api }: { api: AdminApiClient }, { rejectWithValue }) => {
    try {
      return await api.refreshAdmins();
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAdmin = createAsyncThunk<
  AdminProfile,
  { api: AdminApiClient; data: AdminProfile },
  { rejectValue: ApiError }
>(
  "admin/updateAdmin",
  async (
    { api, data }: { api: AdminApiClient; data: AdminProfile },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateAdmin(data);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const verifyAdmin = createAsyncThunk<
  AdminProfile,
  { api: AdminApiClient; adminId: string },
  { rejectValue: ApiError }
>(
  "admin/verifyAdmin",
  async (
    { api, adminId }: { api: AdminApiClient; adminId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.verifyAdmin(adminId);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const createAuth0Admin = createAsyncThunk<
  CreateAuth0UserResponse,
  { api: AdminApiClient; adminId: string; destination: string },
  { rejectValue: ApiError }
>(
  "admin/createAuth0Admin",
  async (
    {
      api,
      adminId,
      destination,
    }: { api: AdminApiClient; adminId: string; destination: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.createAuth0Admin(adminId, destination);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getAuth0UserID = createAsyncThunk<
  FindAuth0IDResponse,
  { api: AdminApiClient; adminEmail: string; adminId: string },
  { rejectValue: ApiError }
>(
  "admin/getAuth0UserID",
  async (
    { api, adminEmail }: { api: AdminApiClient; adminEmail: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.getAuth0UserID(adminEmail);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAdmin = createAsyncThunk<
  string,
  { api: AdminApiClient; data: AdminProfile },
  { rejectValue: ApiError }
>(
  "admin/deleteAdmin",
  async (
    { api, data }: { api: AdminApiClient; data: AdminProfile },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteAdmin(data);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const adminAdapter = createEntityAdapter({
  selectId: (model: AdminProfile) => model.admin_id,
});

export interface AdminState {
  status: "idle" | "pending";
  error: ApiError;
  profile: AdminProfile;
  admins: EntityState<AdminProfile, string>;
  adminsTotal: number;
}

const initialState: AdminState = {
  status: "idle",
  error: ApiErrorInitialState,
  profile: AdminProfileInitialState,
  admins: adminAdapter.getInitialState(),
  adminsTotal: 0,
};

const adminsSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdmins.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchAdmins.fulfilled, (state, { payload }) => {
      if (payload.results) {
        adminAdapter.setAll(state.admins, payload.results);
        state.adminsTotal = payload.total;
      }
      state.status = "idle";
    });
    builder.addCase(fetchAdmins.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(refreshAdmins.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(refreshAdmins.fulfilled, (state, { payload }) => {
      adminAdapter.setAll(state.admins, payload.results);
      state.adminsTotal = payload.total;
      state.status = "idle";
    });
    builder.addCase(refreshAdmins.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchAdminById.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchAdminById.fulfilled, (state, { payload }) => {
      adminAdapter.setOne(state.admins, payload);
      state.status = "idle";
    });
    builder.addCase(fetchAdminById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(verifyAdmin.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(verifyAdmin.fulfilled, (state, { payload }) => {
      adminAdapter.updateOne(state.admins, {
        id: payload.admin_id,
        changes: {
          is_verified_admin: payload.is_verified_admin,
        },
      });
      state.status = "idle";
    });
    builder.addCase(verifyAdmin.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateAdmin.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateAdmin.fulfilled, (state, { payload }) => {
      const currentAdmins = adminAdapter
        .getSelectors((adminState: AdminState) => adminState.admins)
        .selectById(state, payload.admin_id);
      const changes = {
        ...currentAdmins,
        ...payload,
      };
      adminAdapter.updateOne(state.admins, {
        id: payload.admin_id,
        changes,
      });
      state.profile = changes;
      state.status = "idle";
    });
    builder.addCase(updateAdmin.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(deleteAdmin.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(deleteAdmin.fulfilled, (state, { payload }) => {
      adminAdapter.removeOne(state.admins, payload);
      state.status = "idle";
    });
    builder.addCase(deleteAdmin.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(me.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(me.fulfilled, (state, { payload }) => {
      state.profile = payload;
      adminAdapter.setOne(state.admins, payload);

      state.status = "idle";
    });
    builder.addCase(me.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(createAuth0Admin.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      createAuth0Admin.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { adminId },
          },
        }
      ) => {
        adminAdapter.updateOne(state.admins, {
          id: adminId,
          changes: { google_auth_id: payload.auth_id },
        });

        state.status = "idle";
      }
    );
    builder.addCase(createAuth0Admin.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(getAuth0UserID.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      getAuth0UserID.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { adminId },
          },
        }
      ) => {
        adminAdapter.updateOne(state.admins, {
          id: adminId,
          changes: {
            auth_id: payload.auth_id,
            google_auth_id: payload.auth_id,
            auth_id_match: payload.auth_id_match,
          },
        });

        state.status = "idle";
      }
    );
    builder.addCase(getAuth0UserID.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const { selectAll: selectAllAdmins, selectById: selectAdminById } =
  adminAdapter.getSelectors((state: RootState) => state.admins.admins);
export const selectAdminsTotal = (state: RootState) => state.admins.adminsTotal;
export const selectAdminIsLoading = (state: RootState) =>
  state.admins.status === "pending";

export const adminReducer = adminsSlice.reducer;

