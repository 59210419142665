import Collapse from "@hellodarwin/core/lib/components/common/Collapse";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import { GrantContentRaw } from "@hellodarwin/core/lib/features/entities";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import Refresh from "@hellodarwin/icons/dist/icons/Refresh";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import GrantValidationButton from "admin/src/components/grants/grant-validation/grant-validation-button";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Input from "antd/es/input";
import message from "antd/es/message";
import Radio from "antd/es/radio";
import Space from "antd/es/space";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import {
  createGrantContentRaw,
  fetchGrantContentRaw,
  fetchGrantContentRawLinks,
  scrapeGrantContentRawLinks,
  selectGrantContentRaw,
  selectGrantContentRawLinksByGrantID,
  updateGrantContentRaw,
} from "../../features/api/slices/grants-slice";
import { useAdminApi, useNewAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";
import { AdminPagesForms } from "../single-project-page";
const { TextArea } = Input;

export type GrantContentRawFormValues = {
  content: string;
  locale: string;
};

const SingleGrantContextPage = () => {
  const [form] = Form.useForm<GrantContentRawFormValues>();
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [locale, setLocale] = useState("en");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingLinks, setIsGeneratingLinks] = useState(false);
  const { t } = useTranslations();
  const params = useParams<{
    id: string;
  }>();

  const allowedFileTypes = [
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "rtf",
    "txt",
    "odt",
    "ods",
    "odp",
  ];
  const grantContentRaw = useAppSelector((state) =>
    selectGrantContentRaw(state, params.id || "")
  );
  const [forms, setForms] = useState<AdminPagesForms>({
    grantContentRawForm: undefined,
  });

  const grantContentRawLinks = useAppSelector((state) =>
    selectGrantContentRawLinksByGrantID(state, params.id || "")
  );
  const filteredLinks = useMemo(() => {
    if (!grantContentRawLinks) {
      return [];
    }
    return grantContentRawLinks.filter((link) => {
      const extension = link.unique_url.split(".").pop();
      return !allowedFileTypes.includes(extension!);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantContentRawLinks]);

  const filteredFiles = useMemo(() => {
    if (!grantContentRawLinks) {
      return [];
    }
    return grantContentRawLinks.filter((link) => {
      const extension = link.unique_url.split(".").pop();
      return allowedFileTypes.includes(extension!);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantContentRawLinks]);

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      grantContentRawForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    if (!params.id) {
      return;
    }
    dispatch(fetchGrantContentRaw({ api, grantId: params.id, locale: locale }));
    dispatch(fetchGrantContentRawLinks({ api: newApi, grantId: params.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!params.id) return <Loading />;

  if (!grantContentRaw) {
    return <Loading />;
  }

  const onSave = async () => {
    try {
      const grantContentRawFormValues =
        forms.grantContentRawForm?.getFieldsValue()!;

      if (grantContentRawFormValues === undefined) {
        return;
      }

      const updatedGrantContentRaw: GrantContentRaw = {
        ...grantContentRaw,
        ...grantContentRawFormValues,
      };

      await dispatch(
        updateGrantContentRaw({ api, grantContentRaw: updatedGrantContentRaw })
      );

      message.success(
        `Saved Scraped Context ${updatedGrantContentRaw.locale}!`
      );
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const goToGrantSinglePage = () => {
    navigate(`/grants/${params.id}`);
  };

  const initialValues: GrantContentRawFormValues = {
    content: grantContentRaw.content || "",
    locale: grantContentRaw.locale || locale,
  };

  const changeScrapeContext = async (value: string) => {
    try {
      if (!params.id) {
        return;
      }
      setLocale(value);
      const newGrantContentRaw = await dispatch(
        fetchGrantContentRaw({
          api,
          grantId: params.id,
          locale: value,
        })
      ).unwrap();

      form.setFieldValue("content", newGrantContentRaw.content);
    } catch (e: any) {
      message.error("Error !");
    }
  };

  const generateNewScrapeContext = async () => {
    try {
      setIsGenerating(true);
      if (!params.id) {
        return;
      }
      const newGrantContentRaw = await dispatch(
        createGrantContentRaw({
          api,
          grantId: params.id,
          locale: locale,
        })
      ).unwrap();

      form.setFieldValue("content", newGrantContentRaw.content);
    } catch (e: any) {
      message.error("Error !");
    } finally {
      setIsGenerating(false);
    }
  };

  const generateScrapeContextLinks = async () => {
    try {
      if (!params.id) {
        return;
      }
      setIsGeneratingLinks(true);
      const links = await dispatch(
        scrapeGrantContentRawLinks({
          api: newApi,
          grantId: params.id,
        })
      ).unwrap();
      if (!links) {
        message.success("No links found.");
        return;
      }
      const fileCount = links.filter((link) => {
        const extension = link.unique_url.split(".").pop();
        return allowedFileTypes.includes(extension!);
      }).length;
      if (fileCount > 0) {
        message.success(`${fileCount} file(s) found.`);
      } else {
        message.success("No files found.");
      }
      message.success(`${links.length} link(s) found.`);
    } catch (e: any) {
      message.error("Error ! Failed to scrape links and files.");
      console.error(e);
    } finally {
      setIsGeneratingLinks(false);
    }
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={"Context"}
      title={"Context"}
      handleBack={goToGrantSinglePage}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: t("programs_admin|programsTitle"),
          path: "/grants",
        },
        {
          breadcrumbName: "Single Grant Page",
          path: `/grants/${params.id}`,
        },
        {
          breadcrumbName: "Scrape Context",
        },
      ]}
    >
      <Div flex="row" align="center" justify="space-between">
        <Div>
          <Button
            type="primary"
            onClick={onSave}
            size="large"
            color={theme.colors.purple_1}
            style={{ width: "150px" }}
          >
            Save
          </Button>
        </Div>
        <Div alignSelf="center" align="center">
          <GrantValidationButton grantId={params.id} filterByType="scrape" />
        </Div>
        <Div flex="row" align="center" justify="center" gap={16}>
          <Button
            onClick={() => generateNewScrapeContext()}
            loading={isGenerating}
            icon={
              <Refresh
                style={{ color: theme.colors.purple_1 }}
                width={14}
                height={14}
              />
            }
          >
            Generate Section Only
          </Button>
          <Button
            onClick={generateScrapeContextLinks}
            loading={isGeneratingLinks}
          >
            Generate Section Links
          </Button>
        </Div>
        <Div flex="row" align="center" justify="flex-end">
          <Radio.Group
            size={"large"}
            onChange={(e) => changeScrapeContext(e.target.value)}
            defaultValue="en"
            buttonStyle="solid"
          >
            <Space direction="horizontal">
              <Radio.Button value="en">English</Radio.Button>
              <Radio.Button value="fr">Français</Radio.Button>
            </Space>
          </Radio.Group>
        </Div>
      </Div>

      <Card style={{ padding: "1rem" }}>
        {filteredLinks.length > 0 && (
          <Collapse
            style={{ marginBottom: "1rem" }}
            items={[
              {
                id: "id",
                styles: {
                  header: {
                    backgroundColor: theme.colors.beige_2,
                    padding: "8px 24px",
                  },
                },
                title: (
                  <>
                    <Typography>Links</Typography>
                  </>
                ),
                children: filteredLinks.map((link) => (
                  <Div style={{ marginBottom: "1rem" }}>
                    <Link to={link.unique_url} target="_blank">
                      {link.unique_url}
                    </Link>
                  </Div>
                )),
              },
            ]}
            ExpandIcon={({ isActive }) => <TabArrow down={isActive} />}
          />
        )}
        {filteredFiles.length > 0 && (
          <Collapse
            style={{ marginBottom: "1rem" }}
            items={[
              {
                id: "id",
                styles: {
                  header: {
                    backgroundColor: theme.colors.beige_2,
                    padding: "8px 24px",
                  },
                },
                title: (
                  <>
                    <Typography>Files</Typography>
                  </>
                ),
                children: filteredFiles.map((link) => (
                  <Div style={{ marginBottom: "1rem" }}>
                    <Link to={link.unique_url} target="_blank">
                      {link.unique_url}
                    </Link>
                  </Div>
                )),
              },
            ]}
            ExpandIcon={({ isActive }) => <TabArrow down={isActive} />}
          />
        )}
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="grantContentRaw"
        >
          <Form.Item label="Scrape Content" name="content">
            <TextArea autoSize style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Card>
    </PageLayout>
  );
};

export default SingleGrantContextPage;

