import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Grant, GrantResult } from "@hellodarwin/core/lib/features/entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import theme from "@hellodarwin/core/lib/theme";
import Check from "@hellodarwin/icons/dist/icons/Check";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import { useAdminApi } from "admin/src/features/api/use-admin-api";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const GrantsValidationList = () => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const api = useAdminApi();
  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 100;

  type PageState = {
    grants: Grant[];
    pagination: {
      page: number;
      size: number;
    };
  };

  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageStateInitialValues: PageState = {
    grants: [],
    pagination: {
      page: DEFAULT_PAGE,
      size: DEFAULT_LIMIT,
    },
  };
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetch = (page: number, size: number) => {
    (async () => {
      try {
        setIsLoading(true);
        const response: GrantResult[] = await api.queryGrants(
          selectedLocale,
          page,
          size
        );

        const newPageState = {
          grants: response?.map((grant, index) => ({ key: index, ...grant })),
          pagination: { page: page, size: size },
        };
        setPageState(newPageState);
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
        console.error(e);
      }
    })();
  };

  const onPageChange = (page: number, size: number) => {
    fetch(page, size);
  };

  const columns: ColumnsType<any> = [
    {
      title: t("programs_admin|program"),
      dataIndex: "grant_id",
      key: "grant_id",
      render: (grantID) => {
        return (
          <Link
            target="_blank"
            to={`/grants/${grantID}`}
            style={{ flexShrink: 0 }}
          >
            <NewWindow size={20} />
          </Link>
        );
      },
      width: 10,
    },
    {
      title: t("programs_admin|titleProgram"),
      dataIndex: "grant_title",
      key: "grant_title",
      ellipsis: true,
      width: 40,
      render: (title) => (
        <Typography elementTheme="body2" ellipsis nowrap overflow>
          {title}
        </Typography>
      ),
    },
    {
      title: t("programs_admin|status"),
      dataIndex: "application_status",
      key: "application_status",
      width: 10,
      render: (status) => {
        return (
          <Typography elementTheme="body2">
            {t(`grant_card_status|${status}`)}
          </Typography>
        );
      },
    },
    {
      title: t("grant_validation|basic_info"),
      dataIndex: "grant_validation_basic_info",
      key: "grant_validation_basic_info",
      render: (grant_validation_basic_info) => {
        return grant_validation_basic_info ? (
          <Div flex="row" align="center" justify="center">
            <Check size={20} color={theme.colors.purple_1} />
          </Div>
        ) : null;
      },
      width: 10,
    },
    {
      title: t("grant_validation|projects"),
      dataIndex: "grant_validation_projects",
      key: "grant_validation_projects",
      render: (grant_validation_projects) => {
        return grant_validation_projects ? (
          <Div flex="row" align="center" justify="center">
            <Check size={20} color={theme.colors.purple_1} />
          </Div>
        ) : null;
      },
      width: 10,
    },
    {
      title: "GIN",
      dataIndex: "grant_validation_gin",
      key: "grant_validation_gin",
      render: (grant_validation_gin) => {
        return grant_validation_gin ? (
          <Div flex="row" align="center" justify="center">
            <Check size={20} color={theme.colors.purple_1} />
          </Div>
        ) : null;
      },
      width: 10,
    },
    {
      title: "Timeline",
      dataIndex: "grant_validation_timeline",
      key: "grant_validation_timeline",
      render: (grant_validation_timeline) => {
        return grant_validation_timeline ? (
          <Div flex="row" align="center" justify="center">
            <Check size={20} color={theme.colors.purple_1} />
          </Div>
        ) : null;
      },
      width: 10,
    },
    {
      title: "Scrape",
      dataIndex: "grant_validation_scrape",
      key: "grant_validation_scrape",
      render: (grant_validation_scrape) => {
        return grant_validation_scrape ? (
          <Div flex="row" align="center" justify="center">
            <Check size={20} color={theme.colors.purple_1} />
          </Div>
        ) : null;
      },
      width: 10,
    },
  ];

  return (
    <Div flex="column" style={{ marginTop: 24 }} gap={24}>
      <Table
        bordered
        columns={columns}
        dataSource={pageState.grants}
        pagination={{
          pageSize: pageState.pagination.size,
          current: pageState.pagination.page,
          total: pageState.grants?.[0]?.total_count,
          showSizeChanger: true,
          onChange: (page, size) => onPageChange(page, size ?? DEFAULT_LIMIT),
        }}
        loading={isLoading}
      />
    </Div>
  );
};

export default GrantsValidationList;

