import SelectProvince from "@hellodarwin/core/lib/components/forms/form-groups/province-select";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAdminApi } from "../../../features/api/use-admin-api";

type CreateProviderFormProps = {
  visible: boolean;
  handleClose: () => void;
};

const CreateProviderForm = ({
  visible,
  handleClose,
}: CreateProviderFormProps) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslations();
  const navigate = useNavigate();
  const api = useAdminApi();

  const onFinish = (values: any) => {
    (async () => {
      setIsSaving(true);
      try {
        const provider = await api.createBasicProvider(values);
        setIsSaving(false);
        handleClose();
        navigate("/providers/" + provider.provider_id);
        message.success(t("createProvider|saved"));
      } catch (e: any) {
        setIsSaving(false);
        message.error(t("createProvider|messageError"));
        console.error(e);
      }
    })();
  };

  return (
    <Modal
      title={t("createProvider|create_provider_title")}
      afterClose={form.resetFields}
      open={visible}
      okText={t("button|button_create")}
      okButtonProps={{ loading: isSaving }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
      cancelText={t("button|cancel")}
      onCancel={handleClose}
      keyboard={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="create-provider"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={t("createProvider|form_name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("createProvider|missing_fullName"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t("createProvider|only_email")}
              name="email"
              rules={[
                {
                  required: true,
                  message: t("createProvider|emailRequired"),
                },
                {
                  type: "email",
                  message: t("createProvider|email_valid"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SelectProvince isRequired />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateProviderForm;

